.result-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.result-modal__content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.result-modal__header {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #eee;
}

.result-modal__header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.result-modal__title {
  margin: 0;
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: 600;
}

.result-modal__close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.result-modal__close:hover {
  background-color: #f5f5f5;
  color: #333;
}

.result-modal__body {
  padding: 1rem;
  overflow-y: auto;
  flex: 1;
}

.result-modal__list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.result-modal__item {
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.result-modal__item-content {
  flex: 1;
}

.result-modal__item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #eee;
}

.result-modal__category {
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.result-modal__material {
  color: var(--text-color);
}

.result-modal__material--no-selection {
  color: #999;
  font-style: italic;
}

.result-modal__footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.result-modal__share {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.result-modal__share:hover {
  background-color: var(--primary-dark);
}

.result-modal__confirm {
  padding: 0.5rem 1rem;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.result-modal__confirm:hover {
  background-color: var(--primary-color);
  color: white;
}

.result-modal__section {
  margin-bottom: 2rem;
}

.result-modal__section:last-child {
  margin-bottom: 0;
}

.result-modal__section-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
  color: #333;
}

@media (max-width: 768px) {
  .result-modal__content {
    width: 95%;
    max-height: 85vh;
    margin: 0.5rem;
  }

  .result-modal__header {
    padding: 1rem;
  }

  .result-modal__title {
    font-size: 1rem;
    line-height: 1.4;
  }

  .result-modal__body {
    padding: 0.75rem;
  }

  .result-modal__item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem;
  }

  .result-modal__item-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  .result-modal__category {
    font-size: 0.875rem;
  }

  .result-modal__material {
    font-size: 0.875rem;
    line-height: 1.4;
  }

  .result-modal__section-title {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .result-modal__footer {
    padding: 0.75rem;
    flex-direction: column;
    gap: 0.5rem;
  }

  .result-modal__share,
  .result-modal__confirm {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .result-modal__content {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  .result-modal__item-image {
    height: 150px;
  }
} 