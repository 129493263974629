.mobile-saved-items-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.mobile-saved-items-content {
  background-color: white;
  width: 90%;
  max-width: 320px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mobile-saved-items-content h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 18px;
  text-align: center;
}

.mobile-search-box {
  margin-bottom: 20px;
}

.mobile-search-box input {
  width: 100%;
  padding: 12px;
  border: 1px solid #946A6A;
  border-radius: 8px;
  font-size: 14px;
}

.mobile-items-list {
  margin-bottom: 20px;
  max-height: 50vh;
  overflow-y: auto;
}

.mobile-item-row {
  padding: 15px 10px;
  border-bottom: 1px solid #E1D8D8;
  cursor: pointer;
}

.mobile-item-row:active {
  background-color: #F7F7F6;
}

.mobile-item-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mobile-item-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.mobile-item-unit {
  font-size: 12px;
  color: #666;
}

.mobile-item-price {
  font-size: 14px;
  color: #946A6A;
  font-weight: 500;
}

.mobile-item-memo {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.mobile-no-items {
  padding: 30px 0;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.mobile-modal-buttons {
  display: flex;
  justify-content: center;
}

.mobile-modal-buttons button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #946A6A;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.mobile-modal-buttons button:active {
  background-color: #7A5656;
} 