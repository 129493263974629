.estimate-inquiry-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #F8F8F8;
}

.estimate-inquiry-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.estimate-inquiry-form-group {
  margin-bottom: 16px;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}

.estimate-inquiry-form-group:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.estimate-inquiry-form-label {
  display: block;
  margin-bottom: 8px;
  color: #946A6A;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.estimate-inquiry-input,
.estimate-inquiry-textarea {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  font-size: 14px;
  background: transparent;
  transition: all 0.2s ease;
}

.estimate-inquiry-textarea {
  resize: vertical;
  min-height: 100px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
}

.estimate-inquiry-submit-button {
  width: 100%;
  padding: 16px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  letter-spacing: -0.3px;
  box-shadow: 0 2px 8px rgba(148, 106, 106, 0.2);
  transition: all 0.2s ease;
}

.estimate-inquiry-submit-button:hover {
  background-color: #7d5959;
  box-shadow: 0 4px 12px rgba(148, 106, 106, 0.3);
  transform: translateY(-1px);
}

.estimate-inquiry-input::placeholder,
.estimate-inquiry-textarea::placeholder {
  color: #ccc;
}

.estimate-inquiry-input:focus,
.estimate-inquiry-textarea:focus {
  outline: none;
  border-color: #946A6A;
}

.estimate-inquiry-action-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 20px 0 30px;
}

.estimate-inquiry-circle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  background: white;
  color: #946A6A;
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.estimate-inquiry-circle-button:hover {
  background: #946A6A;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.estimate-inquiry-button-icon {
  font-size: 20px;
  margin-bottom: 4px;
}

.estimate-inquiry-button-text {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.3px;
} 