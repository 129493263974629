.window-size-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.window-size-title {
  text-align: center;
  margin-bottom: 16px;
  color: #333;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
}

.window-size-form {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.window-size-input-row {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
}

.window-size-input-field {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 50px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  text-align: center;
}

.window-size-input-field::placeholder {
  text-align: center;
}

.window-size-input-field::-webkit-outer-spin-button,
.window-size-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select.window-size-input-field {
  width: 90px;
  font-size: 12px;
}

.window-size-input-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.window-size-input-wrapper input {
  width: 80px;
}

.window-size-unit {
  color: #666;
  font-size: 12px;
}

.window-size-save-button {
  padding: 6px 14px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
}

.window-size-save-button:hover {
  background-color: #7a5757;
}

.window-size-memo {
  width: 100%;
  margin-top: 12px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  min-height: 50px;
  font-size: 12px;
}

.window-size-list {
  margin-top: 20px;
}

.window-size-item {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.window-size-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.window-size-share-button {
  padding: 5px 10px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.window-size-item-details {
  font-size: 12px;
  color: #666;
}

.window-size-item-details p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .window-size-input-row {
    flex-wrap: wrap;
  }
  
  .window-size-input-wrapper {
    flex: 1;
    min-width: 120px;
  }
  
  select.window-size-input-field {
    flex: 1;
  }
} 

.window-size-button-group {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 12px;
}

.window-size-add-button {
  padding: 6px 14px;
  background-color: #6A946A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
}

.window-size-add-button:hover {
  background-color: #577a57;
}

.window-size-memo-field {
  flex: 1;
  min-width: 120px;
}

@media (max-width: 768px) {
  .window-size-input-row {
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .window-size-input-field,
  .window-size-input-wrapper {
    flex: 1;
    min-width: calc(50% - 5px);
  }
  
  .window-size-memo-field {
    flex: 100%;
    margin-top: 5px;
  }
} 

@media (max-width: 360px) {
  .window-size-input-row {
    gap: 3px;
  }

  .window-size-input-field {
    width: 45px;
    padding: 6px;
    font-size: 12px;
  }

  select.window-size-input-field {
    width: 85px;
  }
} 

.window-size-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.window-size-modal-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 360px;
  max-height: 80vh;
  overflow-y: auto;
}

.window-size-modal-content h2 {
  margin: 0 0 16px 0;
  font-size: 18px;
  text-align: center;
}

.window-size-modal-tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.window-size-modal-tabs button {
  flex: 1;
  height: 38px;
  padding: 0 10px;
  border: none;
  border-radius: 8px;
  background: #f0f0f0;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window-size-modal-tabs button.active {
  background: #946A6A;
  color: white;
}

.window-size-modal-input {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.window-size-modal-input input {
  flex: 1 1 0;
  height: 38px;
  padding: 0 10px;
  border: none;
  border-radius: 8px;
  background: #f0f0f0;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  min-width: 0;
  line-height: 38px;
}

.window-size-modal-input button {
  flex: 1 1 0;
  height: 38px;
  padding: 0 10px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window-size-modal-input input:focus {
  outline: none;
  background: #e8e8e8;
}

.window-size-modal-input button:hover {
  background: #7a5757;
}

.window-size-modal-list {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-bottom: 20px;
}

.window-size-modal-item {
  display: table-row;
  background: #f5f5f5;
}

.window-size-modal-item span {
  display: table-cell;
  padding: 12px;
  font-size: 12px;
  vertical-align: middle;
  border-radius: 8px 0 0 8px;
  background: #f5f5f5;
  width: 85%;
}

.window-size-modal-delete-button {
  display: table-cell;
  background: #f5f5f5;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  font-size: 16px;
  padding: 12px;
  vertical-align: middle;
  text-align: right;
  border-radius: 0 8px 8px 0;
  width: 15%;
}

.window-size-modal-close {
  width: 100%;
  padding: 12px;
  background: #666;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
}

.window-size-modal-content::-webkit-scrollbar {
  width: 8px;
}

.window-size-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.window-size-modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.window-size-modal-content::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.window-size-title {
  cursor: pointer;
  user-select: none;
}

.window-size-item-buttons {
  display: flex;
  gap: 8px;
}

.window-size-delete-button {
  padding: 5px 10px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.window-size-delete-button:hover {
  background-color: #b71c1c;
}

.window-size-share-button,
.window-size-delete-button {
  min-width: 50px;
}

.window-size-item-content {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.window-size-item-content:last-child {
  border-bottom: none;
  margin-bottom: 5px;
}

.window-size-item-memo {
  margin: 10px 0;
  padding: 8px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.window-size-item-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 10px;
}

.window-size-summary {
  background: #f8f8f8;
  padding: 12px;
  border-radius: 8px;
  margin: 12px 0;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
}

.window-size-summary:hover {
  background: #f0f0f0;
}

.window-size-summary-text {
  white-space: pre-wrap;
  margin: 0;
  font-size: 12px;
  line-height: 1.4;
  font-family: inherit;
}

.window-size-summary-hint {
  text-align: center;
  color: #946A6A;
  font-size: 10px;
  margin-top: 8px;
}

.window-size-reset-button {
  padding: 6px 14px;
  background-color: #666;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
}

.window-size-reset-button:hover {
  background-color: #555;
}

.window-size-remove-button {
  background: none;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
}

.window-size-remove-button:hover {
  background-color: #ffebee;
}

@media (max-width: 360px) {
  .window-size-remove-button {
    font-size: 18px;
    min-width: 20px;
    height: 20px;
  }
}

@media (max-width: 360px) {
  .window-size-modal-item span {
    padding: 10px;
    font-size: 12px;
  }
  
  .window-size-modal-delete-button {
    padding: 10px;
    font-size: 15px;
  }
}