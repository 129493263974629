.saved-items-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.saved-items-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 20px 20px;
  width: 550px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.saved-items-modal h3 {
  margin: 0 0 20px 0;
  color: #333;
}

.search-box {
  margin-bottom: 20px;
}

.search-box input {
  width: 100%;
  padding: 10px;
  border: 1px solid #946A6A;
  border-radius: 4px;
  font-size: 14px;
}

.items-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 20px;
}

.item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #E1D8D8;
  transition: background-color 0.2s;
  position: relative;
  margin-right: 20px;
}

.item-row:hover {
  background-color: #F7F7F6;
}

.item-content {
  display: flex;
  flex: 1;
  gap: 10px;
  cursor: pointer;
  min-width: 0;
  margin-right: 12px;
  align-items: center;
}

.item-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  display: flex;
  align-items: center;
}

.item-unit {
  flex: 0 0 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-price {
  flex: 0 0 90px;
  text-align: right;
  color: #946A6A;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item-memo {
  flex: 0 0 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #946A6A;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-buttons button:hover {
  background-color: #7A5656;
}

.loading, .no-items {
  padding: 20px;
  text-align: center;
  color: #666;
}

.delete-button {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  border: none;
  background-color: #e74c3c;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s ease;
  margin-left: 0;
}

.item-row:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  background-color: #c0392b;
  transform: scale(1.1);
}

.delete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.item-row.deleting {
  opacity: 0.5;
  background-color: #f8f8f8;
} 