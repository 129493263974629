.version-save-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.version-save-modal {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.version-save-modal h2 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 18px;
}

.base-version-info {
  margin-bottom: 20px;
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.base-version-label {
  color: #666;
  font-size: 14px;
}

.base-version-name {
  color: #946A6A;
  font-weight: 500;
  font-size: 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: help;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 14px;
}

.input-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E1D8D8;
  border-radius: 4px;
  font-size: 14px;
}

.input-group input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.preview {
  margin-top: 12px;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 13px;
}

.preview-label {
  color: #666;
  margin-right: 8px;
}

.preview-value {
  color: #946A6A;
  font-weight: 500;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.save-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button {
  background-color: #946A6A;
  color: white;
}

.save-button:hover {
  background-color: #7A5656;
}

.cancel-button {
  background-color: #E1D8D8;
  color: #333;
}

.cancel-button:hover {
  background-color: #D1C8C8;
} 