/* 모달 기본 구조 */
.material-manager-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.material-manager-container {
  width: 360px;
  max-height: 600px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* 헤더 영역 */
.material-manager-header {
  position: relative;
  height: 56px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
  border-radius: 16px 16px 0 0;
}

.material-manager-title {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.material-manager-close {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: #666;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
}

.material-manager-close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* 아코디언 영역 */
.material-manager-content {
  flex: 1;
  overflow-y: auto;
  padding: 8px 0;
}

.material-manager-section {
  border-bottom: 1px solid #eee;
}

.material-manager-section:last-child {
  border-bottom: none;
}

.material-manager-section-header {
  height: 48px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s;
}

.material-manager-section-header:hover {
  background-color: #f8f9fa;
}

.material-manager-section-title {
  font-size: 15px;
  color: #333;
}

.material-manager-section-arrow {
  font-size: 12px;
  color: #666;
  transition: transform 0.2s;
}

.material-manager-section.active .material-manager-section-arrow {
  transform: rotate(180deg);
}

.material-manager-section-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
}

.material-manager-section.active .material-manager-section-content {
  max-height: 400px;
}

.material-manager-section-body {
  padding: 16px 20px;
}

/* 아이템 리스트 */
.material-manager-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.material-manager-item {
  height: 44px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.material-manager-item:hover {
  background-color: #f8f9fa;
  border-color: #ddd;
  transform: translateX(4px);
}

.material-manager-item.selected {
  background-color: #f0f0f0;
  border-color: #ddd;
}

.material-manager-item-name {
  flex: 1;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-manager-item-delete {
  width: 28px;
  height: 28px;
  border: none;
  background: none;
  color: #dc3545;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.2s;
}

.material-manager-item:hover .material-manager-item-delete {
  opacity: 0.7;
}

.material-manager-item-delete:hover {
  opacity: 1;
  background-color: rgba(220, 53, 69, 0.1);
}

/* 입력 폼 */
.material-manager-form {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
}

.material-manager-input {
  flex: 1;
  height: 44px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background-color: white;
  transition: all 0.2s;
}

.material-manager-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 3px rgba(148, 106, 106, 0.1);
}

.material-manager-input::placeholder {
  color: #999;
}

.material-manager-button {
  min-width: 64px;
  height: 44px;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #946A6A;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.material-manager-button:hover:not(:disabled) {
  background-color: #7a5757;
  transform: translateY(-1px);
}

.material-manager-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 스크롤바 */
.material-manager-content::-webkit-scrollbar {
  width: 6px;
}

.material-manager-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.material-manager-content::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 3px;
}

/* 비활성화된 섹션 */
.material-manager-section-disabled {
  opacity: 0.5;
  pointer-events: none;
} 