.template-items-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.template-items-modal {
  background: white;
  width: 95%;
  max-width: 1000px;
  max-height: 90vh;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.template-items-modal-header {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  background: #fff;
}

.template-items-modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  flex: 1;
}

.template-items-modal-close {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 16px;
}

.template-items-table-container {
  flex: 1;
  overflow: auto;
  padding: 0 24px;
}

.template-items-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 16px 0;
}

.template-items-table th {
  background: #f8f9fa;
  font-weight: 500;
  font-size: 13px;
  color: #666;
  text-align: center;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.template-items-table td {
  padding: 12px;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  background: white;
}

.template-items-table tr:hover td {
  background: #f8f9fa;
}

.template-items-total-row td {
  background: #f8f9fa !important;
  font-weight: 500;
  border-top: 2px solid #eee;
}

.template-items-total-label {
  text-align: right !important;
}

.template-items-total-amount {
  color: #E65D20;
  font-weight: 600 !important;
}

.template-item-actions {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.template-item-edit-btn {
  border: none;
  background: #946A6A;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 40px;
}

.template-item-edit-btn:hover {
  background: #7A5656;
}

.template-item-delete-btn {
  border: none;
  background: #E65D20;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 40px;
}

.template-item-delete-btn:hover {
  background: #D14810;
}

/* 스크롤바 스타일링 */
.template-items-table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.template-items-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.template-items-table-container::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.template-items-table-container::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* 수정 모달 스타일 */
.template-item-edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.template-item-edit-modal {
  background: white;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  padding: 24px;
}

.template-item-edit-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.template-item-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.template-item-form-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.template-item-form-input {
  height: 40px;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.template-item-form-input:focus {
  outline: none;
  border-color: #946A6A;
}

.template-item-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.template-item-save-btn {
  background: #946A6A;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-item-save-btn:hover {
  background: #7A5656;
}

.template-item-cancel-btn {
  background: white;
  color: #666;
  border: 1px solid #ddd;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-item-cancel-btn:hover {
  background: #f8f9fa;
}

.template-items-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}

.template-items-add-btn {
  background: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-items-add-btn:hover {
  background: #7A5656;
}

.template-summary {
  padding: 16px 24px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.template-summary .divider {
  color: #ddd;
} 