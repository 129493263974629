.bulk-upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.bulk-upload-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.bulk-upload-modal-title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.work-type-select-container {
  margin-bottom: 1.5rem;
}

.work-type-select-container + .work-type-select-container {
  margin-top: -0.5rem;
}

.work-type-select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: all 0.3s ease;
}

.work-type-select:focus {
  border-color: #946A6A;
  outline: none;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.2);
}

.work-type-select option {
  padding: 0.5rem;
}

.bulk-upload-area {
  width: 100%;
  height: 200px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.bulk-upload-area.dragging {
  border-color: #946A6A;
  background-color: rgba(148, 106, 106, 0.1);
}

.bulk-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 2rem;
  text-align: center;
}

.upload-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #946A6A;
}

.upload-text {
  color: #666;
  font-size: 1rem;
}

.selected-files {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.selected-files h3 {
  color: #333;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.file-list {
  max-height: 200px;
  overflow-y: auto;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  color: #666;
  font-size: 0.9rem;
}

.file-item:last-child {
  border-bottom: none;
}

.file-item span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}

.file-remove-button {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  width: 20px;
  margin-left: 8px;
}

.file-remove-button:hover {
  color: #c82333;
  transform: scale(1.1);
}

.bulk-upload-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.upload-button,
.cancel-button {
  padding: 0.8rem 2rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button {
  background-color: #946A6A;
  color: white;
  border: none;
}

.upload-button:hover:not(:disabled) {
  background-color: #7a5757;
}

.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cancel-button {
  background-color: #f8f9fa;
  color: #666;
  border: 1px solid #ddd;
}

.cancel-button:hover {
  background-color: #e9ecef;
} 