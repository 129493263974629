.address-filter {
  padding: 0 10px;
  margin: 0 0 15px 0;
}

.address-filter__select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: white;
  height: 36px;
  line-height: 20px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 1em;
  padding-right: 32px;
}

.address-filter__select:focus {
  outline: none;
  border-color: #946A6A;
}

.address-filter__select:hover {
  border-color: #946A6A;
} 