.msg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.msg-modal__content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.msg-modal__header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.msg-modal__title {
  font-size: 1.5rem;
  margin: 0 0 10px 0;
  color: #333;
}

.msg-modal__subtitle {
  font-size: 1.1rem;
  margin: 0;
  color: #666;
}

.msg-modal__loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

.msg-modal__events {
  margin-bottom: 20px;
}

.msg-modal__event-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.msg-modal__process-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-modal__process-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.msg-modal__process-name {
  margin-left: 8px;
  font-weight: bold;
  color: #333;
}

.msg-modal__dates {
  color: #666;
  font-size: 0.9rem;
}

.msg-modal__date {
  margin-bottom: 4px;
}

.msg-modal__contractors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.msg-modal__contractor {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
}

.msg-modal__contractor:hover {
  background-color: #f0f0f0;
}

.msg-modal__checkbox {
  margin-right: 8px;
}

.msg-modal__contractor-name {
  margin-right: 8px;
  font-weight: 500;
}

.msg-modal__contractor-phone {
  color: #666;
  font-size: 0.9rem;
}

.msg-modal__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.msg-modal__send-button,
.msg-modal__close-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.msg-modal__send-button {
  background-color: #007bff;
  color: white;
}

.msg-modal__send-button:hover {
  background-color: #0056b3;
}

.msg-modal__send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.msg-modal__close-button {
  background-color: #6c757d;
  color: white;
}

.msg-modal__close-button:hover {
  background-color: #5a6268;
}

.msg-modal__select-all {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
}

.msg-modal__select-all-text {
  margin-left: 8px;
  font-weight: bold;
  color: #333;
}

/* 날짜 변경 요청 체크박스 */
.msg-modal__date-change {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.msg-modal__date-change input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
  accent-color: #007bff;
}

.msg-modal__date-change span {
  font-size: 14px;
  color: #666;
}

/* 공정 헤더 내 옵션 컨테이너 */
.msg-modal__process-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

/* 자재 체크박스 컨테이너 */
.msg-modal__material-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  padding: 4px 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #eee;
}

.msg-modal__material-checkbox input[type="checkbox"] {
  margin: 0;
  accent-color: #946A6A;
}

.msg-modal__material-checkbox:hover {
  background-color: #f0f0f0;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .msg-modal__content {
    width: 95%;
    padding: 15px;
  }

  .msg-modal__contractors {
    grid-template-columns: 1fr;
  }

  .msg-modal__buttons {
    flex-direction: column;
  }

  .msg-modal__send-button,
  .msg-modal__close-button {
    width: 100%;
  }

  .msg-modal__process-header {
    flex-direction: column;
    gap: 8px;
  }

  .msg-modal__process-options {
    width: 100%;
    align-items: flex-start;
  }

  .msg-modal__material-checkbox {
    width: 100%;
    justify-content: flex-end;
  }
} 