.ai-suggest-button {
  position: absolute;
  right: 50px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.ai-suggest-button:hover {
  background-color: #357ABD;
  transform: scale(1.1);
}

.button-icon {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
} 