@font-face {
  font-family: 'Abster';
  src: url('../AutoProposal/fonts/abster-500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

.work-order-container {
  width: 297mm;
  height: 210mm;
  margin: 0 auto;
  padding: 15mm;
  background: white;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cover-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 20mm);
  text-align: center;
  margin: 10mm 0;
}

.logo-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.logo-text {
  font-family: 'Abster', sans-serif;
  font-size: 48px;
  letter-spacing: 2px;
  word-spacing: 8px;
  margin-bottom: 10px;
  color: #946A6A;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.logo-text-container:hover .logo-text {
  transform: scale(1.05);
}

.logo-subtext {
  font-size: 24px;
  margin-top: 20px;
  color: #946A6A;
}

.address-select-section {
  margin-bottom: 40px;
  text-align: center;
  padding: 20px;
}

.address-select {
  width: auto;
  padding: 10px;
  height: 60px;
  font-size: 20px;
  color: #333;
  border: none;
  background: none;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 20px 0;
}

.address-select::-ms-expand {
  display: none;
}

.address-select:focus {
  outline: none;
}

.address-select option {
  text-align: center;
  font-size: 16px;
}

.work-order-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5mm;
}

.work-order-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
}

.site-address {
  text-align: right;
  font-size: 14px;
  color: #555;
}

.warning-text {
  color: #dc3545;
  font-size: 12px;
  margin-top: 2px;
}

.divider {
  border: none;
  border-top: 1px solid #ddd;
  margin: 5mm 0;
}

.work-order-content {
  display: flex;
  gap: 10mm;
  height: calc(210mm - 40mm);
}

.blueprint-section {
  flex: 8;
  background: white;
}

.right-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.materials-container {
  flex: 1;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: auto;
  padding: 10px;
  min-height: 0;
}

.materials-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.materials-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.material-item {
  padding: 6px 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
}

.material-item-text {
  color: #495057;
  flex: 1;
  min-width: 0;
  padding-right: 8px;
}

.material-item-delete {
  color: #dc3545;
  cursor: pointer;
  padding: 2px;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 1;
  flex-shrink: 0;
  transition: color 0.2s;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.material-item-delete:hover {
  color: #c82333;
}

.memo-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.memo-content {
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  background: white;
  white-space: pre-wrap !important;
  word-break: break-word !important;
  min-height: 100px;
}

.blueprint-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.blueprint-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  background: #f8f9fa;
}

.label-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.image-label {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #FF6B35;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  cursor: move;
  pointer-events: auto;
  user-select: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  will-change: transform, left, top;
  transform: translate(-50%, -50%);
}

.image-label.dragging {
  transition: none;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  transform: translate(-50%, -50%) scale(1.1);
}

.image-label:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.image-label.has-material:hover {
  background-color: #45a049;
  transform: translate(-50%, -50%) scale(1.1);
}

.image-label .edit-icon {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
  border: 2px solid white;
}

.image-label:hover .edit-icon {
  display: flex;
  animation: pulse 1s infinite;
}

.edit-icon:hover {
  transform: scale(1.15);
  background-color: #45a049;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
}

.image-label.has-material {
  background-color: #4CAF50;
}

.image-label .label-info {
  display: none;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1001;
}

.image-label:hover .label-info {
  display: block;
}

.label-info-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label-info-title {
  color: #666;
  font-weight: bold;
  min-width: 40px;
}

.label-info-value {
  color: #333;
}

.material-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.material-item:hover {
  background-color: #f5f5f5;
}

.material-label-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #4CAF50;
  color: white;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 12px;
  font-weight: bold;
}

.material-item-text {
  flex: 1;
  color: #333;
  font-size: 14px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.image-overlay.dragging {
  background: rgba(0, 0, 0, 0.5);
}

.overlay-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay.dragging .overlay-text {
  opacity: 1;
}

.blueprint-upload-area {
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.blueprint-upload-area:empty {
  border: 2px dashed #ddd;
}

.blueprint-upload-area.dragging {
  background: #e9ecef;
  border-color: #946A6A;
  transform: scale(0.99);
}

.blueprint-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #666;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.upload-icon {
  font-size: 48px;
  color: #946A6A;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 16px;
  color: #666;
  max-width: 80%;
  line-height: 1.4;
}

@media print {
  .work-order-container {
    margin: 0;
    padding: 10mm;
    width: 297mm;
    height: 210mm;
  }
  
  @page {
    size: A4 landscape;
    margin: 0;
  }
  
  .blueprint-upload-label {
    display: none;
  }
  
  .memo-content {
    white-space: pre-wrap !important;
    word-break: break-word !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .blueprint-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  .blueprint-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

.pages-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.pages-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 297mm;
  width: 100%;
  position: relative;
  padding-bottom: 60px;
}

.page-wrapper {
  transition: all 0.3s ease;
  position: relative;
}

.page-wrapper.active {
  transform: scale(1.01);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

/* 스크롤바 스타일링 */
.pages-wrapper::-webkit-scrollbar {
  width: 8px;
}

.pages-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.pages-wrapper::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 4px;
}

.pages-wrapper::-webkit-scrollbar-thumb:hover {
  background: #7a5757;
}

.pages-container.has-multiple-pages {
  margin: 0 auto;
  padding-top: 40px;
  align-items: flex-start;
}

.page-control-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.add-page-button,
.delete-page-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.add-page-button {
  background-color: #946A6A;
}

.delete-page-button {
  background-color: #dc3545;
}

.add-page-button:hover {
  background-color: #7a5757;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

.delete-page-button:hover {
  background-color: #c82333;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

@media screen and (min-width: 297mm) {
  .page-control-buttons {
    position: fixed;
    right: calc((100vw - 297mm) / 2 + 20px);
  }
}

/* 작업 종류 선택 모달 스타일 */
.worktype-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.worktype-modal-content {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.worktype-modal-title {
  text-align: center;
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.worktype-modal-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 0;
}

.worktype-modal-button {
  background-color: #f8f9fa;
  border: 1.5px solid #946A6A;
  color: #946A6A;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  white-space: nowrap;
}

.worktype-modal-button:hover {
  background-color: #946A6A;
  color: white;
  transform: translateY(-2px);
}

/* 불필요한 스타일 제거 */
.work-type-grid,
.work-type-button,
.work-type-button:hover,
.work-type-tag {
  display: none;
}

.image-delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(220, 53, 69, 0.8);
  color: white;
  border: none;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.blueprint-image-container:hover .image-delete-button {
  display: flex;
}

.image-delete-button:hover {
  background-color: #dc3545;
  transform: scale(1.1);
}

.material-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #17a2b8;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: background-color 0.2s;
}

.material-button:hover {
  background-color: #138496;
}

.material-list {
  margin: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.material-item {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.material-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.material-item-text {
  color: #495057;
  font-size: 0.9rem;
}

.material-item-delete {
  color: #dc3545;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  border: none;
  background: none;
  font-size: 1.2rem;
  transition: color 0.2s;
}

.material-item-delete:hover {
  color: #c82333;
}

.work-order-content.template2 {
  display: flex;
  gap: 10mm;
}

.template2 .left-section {
  flex: 7;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.template2 .blueprint-section {
  height: 55%;
}

.template2 .memo-section {
  height: 25%;
}

.template2 .right-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.template2 .materials-container {
  height: 50%;
}

.template2 .floor-plan-section {
  height: 50%;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.floor-plan-title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.floor-plan-upload-area {
  flex: 1;
  background: #f8f9fa;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  min-height: 200px;
}

.floor-plan-upload-area:empty {
  border: 2px dashed #ddd;
}

.floor-plan-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
}

.floor-plan-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #f8f9fa;
  border: none;
}

.floor-plan-image-container:hover .image-delete-button {
  display: flex;
}

.floor-plan-image-container .image-delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(220, 53, 69, 0.8);
  color: white;
  border: none;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.floor-plan-image-container .image-delete-button:hover {
  background-color: #dc3545;
  transform: scale(1.1);
}

.page-number-indicator {
  margin-left: 0;
  font-size: 0.9em;
  color: #666;
  font-weight: normal;
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  pointer-events: auto;
}

.delete-modal-content {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}

.delete-modal-content h3 {
  text-align: center;
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.delete-modal-content p {
  margin: 10px 0;
  color: #666;
  font-size: 14px;
  text-align: center;
}

.delete-modal-hint {
  font-size: 13px;
  color: #888;
  font-style: italic;
  text-align: center;
  margin: 8px 0;
}

.delete-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 15px;
  pointer-events: auto;
}

.delete-confirm-button,
.delete-cancel-button {
  background-color: #f8f9fa;
  border: 1.5px solid #946A6A;
  color: #946A6A;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 80px;
  pointer-events: auto;
}

.delete-confirm-button {
  border-color: #dc3545;
  color: #dc3545;
}

.delete-confirm-button:hover {
  background-color: #dc3545;
  color: white;
  transform: translateY(-2px);
}

.delete-cancel-button:hover {
  background-color: #946A6A;
  color: white;
  transform: translateY(-2px);
}

.work-type-text {
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: inline-block;
}

.work-type-text:hover {
  background-color: #f0f0f0;
}

.bulk-upload-button {
  width: 80%;
  padding: 12px;
  margin-top: 15px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bulk-upload-button:hover {
  background-color: #7a5757;
  transform: translateY(-2px);
}

.template-change-button {
  margin-left: 10px;
  padding: 4px 8px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.template-change-button:hover {
  background-color: #7a5757;
  transform: translateY(-1px);
}

.location-text {
  color: #946A6A;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: inline-block;
}

.location-text:hover {
  background-color: rgba(148, 106, 106, 0.1);
}

.location-text::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #946A6A;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.location-text:hover::before {
  transform: scaleX(1);
}

.floor-plan-arrow {
  position: absolute;
  width: 60px;
  height: 20px;
  transform-origin: right center;
  pointer-events: none;
  transition: all 0.3s ease;
  filter: drop-shadow(0 0 3px rgba(255, 0, 0, 0.5));
}

.floor-plan-arrow::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 3px;
  background-color: rgba(255, 0, 0, 0.8);
  transform: translateY(-50%);
}

.floor-plan-arrow::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 50%;
  width: 12px;
  height: 12px;
  border-top: 3px solid rgba(255, 0, 0, 0.8);
  border-right: 3px solid rgba(255, 0, 0, 0.8);
  transform: translateY(-50%) rotate(45deg);
}

.work-order-content.template3 {
  height: calc(210mm - 50mm); /* 상단 여백(15mm) + 하단 여백(15mm) + 헤더 영역(20mm) */
  padding: 0;
  overflow: hidden;
  position: relative;
}

.work-order-content.template3 .full-image-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.work-order-content.template3 .blueprint-upload-area {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.work-order-content.template3 .blueprint-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.work-order-content.template3 .blueprint-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
}

.work-order-content.template4 {
  height: calc(210mm - 50mm);
  padding: 0;
  overflow: hidden;
  position: relative;
}

.work-order-content.template4 .full-text-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20px;
}

.work-order-content.template4 .text-content {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 20px;
  font-size: 24px;
  line-height: 1.5;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: white;
  color: #333;
}

.work-order-content.template4 .text-content:focus {
  outline: none;
}

.font-size-control {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.font-size-button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  background-color: #946A6A;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.2s ease;
}

.font-size-button:hover {
  background-color: #7a5757;
  transform: scale(1.1);
}

.font-size-display {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  min-width: 40px;
} 