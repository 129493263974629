.select-version-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.select-version-modal {
  background: white;
  border-radius: 12px;
  width: 480px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.modal-body {
  padding: 24px;
}

.new-version-section {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid #eee;
}

.new-version-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  background-color: white;
  margin-bottom: 12px;
}

.new-version-input:focus {
  outline: none;
  border-color: #666;
}

.version-list {
  max-height: 320px;
  overflow-y: auto;
  margin: 0 -24px;
  padding: 0 24px;
}

.version-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.version-item:hover {
  border-color: #666;
  background-color: #fafafa;
}

.version-info {
  flex: 1;
  min-width: 0;
}

.version-name {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.version-date {
  display: block;
  font-size: 12px;
  color: #666;
}

.version-actions {
  margin-left: 16px;
}

.version-delete-btn {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  background-color: transparent;
  color: #666;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.version-delete-btn:hover {
  background-color: #fafafa;
  border-color: #666;
  color: #333;
}

.version-delete-btn svg {
  font-size: 14px;
}

.modal-footer {
  padding: 20px 24px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.btn {
  padding: 10px 16px;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: transparent;
}

.btn-primary {
  background-color: #333;
  color: white;
  border-color: #333;
}

.btn-primary:hover {
  background-color: #000;
  border-color: #000;
}

.btn-secondary {
  color: #666;
}

.btn-secondary:hover {
  background-color: #fafafa;
  border-color: #666;
  color: #333;
}

.loading, .no-versions {
  text-align: center;
  color: #666;
  padding: 40px 0;
  font-size: 14px;
}

.version-edit-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #946A6A;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 4px;
}

.version-edit-input:focus {
  outline: none;
  border-color: #946A6A;
}

.version-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  color: #666;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 8px;
}

.version-btn:hover {
  background-color: #fafafa;
  border-color: #946A6A;
  color: #946A6A;
}

.version-edit-btn {
  color: #666;
}

.version-save-btn {
  color: #666;
}

.version-save-btn:hover {
  color: #946A6A;
}

.version-cancel-btn {
  color: #666;
}

.version-cancel-btn:hover {
  color: #946A6A;
}

.btn-primary {
  background-color: #946A6A;
  color: white;
  border-color: #946A6A;
}

.btn-primary:hover {
  background-color: #7A5656;
  border-color: #7A5656;
} 