.material-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.material-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.material-modal-content h2 {
  margin: 0 0 1.5rem 0;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
}

.material-input-group {
  margin-bottom: 1.5rem;
}

.material-input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
  font-size: 0.9rem;
}

.material-input-group select,
.material-input-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: all 0.2s;
}

.material-input-group select:focus,
.material-input-group input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.2);
}

.material-input-group select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.material-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.material-modal-buttons button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.material-modal-buttons button:first-child {
  background-color: #007bff;
  color: white;
}

.material-modal-buttons button:first-child:hover {
  background-color: #0056b3;
}

.material-modal-buttons button:first-child:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.material-modal-buttons button:last-child {
  background-color: #6c757d;
  color: white;
}

.material-modal-buttons button:last-child:hover {
  background-color: #5a6268;
}

.material-list {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.material-item {
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.material-item:last-child {
  border-bottom: none;
}

.material-item-text {
  color: #495057;
  display: block;
  line-height: 1.4;
}

.material-item-delete {
  color: #dc3545;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  border: none;
  background: none;
  font-size: 1.2rem;
}

.material-item-delete:hover {
  color: #c82333;
}

.material-input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: all 0.2s;
}

.material-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.2);
} 