.pdf-controls {
  position: fixed;
  bottom: 20px;
  left: calc((100vw - 297mm) / 2 + 20px);
  display: flex;
  gap: 10px;
  z-index: 1000;
}

@media screen and (min-width: 297mm) {
  .pdf-controls {
    left: calc((100vw - 297mm) / 2 + 20px);
  }
}

/* PDF 저장 버튼 기본 스타일 */
.pdf-save-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.pdf-save-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

/* 단일 페이지 PDF 저장 버튼 */
.pdf-save-button.single {
  background-color: #4a90e2;
}

.pdf-save-button.single:hover {
  background-color: #357abd;
}

/* 전체 페이지 PDF 저장 버튼 */
.pdf-save-button.all {
  background-color: #34495e;
}

.pdf-save-button.all:hover {
  background-color: #2c3e50;
} 