.price-form {
  margin-top: 20px;
}

.price-input-group {
  background: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
}

.price-input-group:hover {
  border-color: #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.price-input-group label {
  display: block;
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.input-wrapper {
  position: relative;
}

.price-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  text-align: right;
}

.price-input:focus {
  outline: none;
  border-color: var(--lineup-color, #946A6A);
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.submit-button {
  width: 100%;
  padding: 16px;
  background: var(--lineup-color, #946A6A);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;
  transition: all 0.2s ease;
}

.submit-button:hover {
  filter: brightness(0.9);
}

.select-wrapper {
  margin-bottom: 24px;
}

.admin-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background: white;
  cursor: pointer;
}

.admin-select:focus {
  outline: none;
  border-color: var(--lineup-color, #946A6A);
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.lineup-selector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  background: white;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.lineup-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.lineup-button:hover {
  background: #f8f8f8;
  transform: translateX(4px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.lineup-button.active {
  background: var(--lineup-color);
  color: white;
  border-color: var(--lineup-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translateX(8px);
}

.lineup-button svg {
  font-size: 18px;
  transition: transform 0.2s ease;
}

.lineup-button:hover svg {
  transform: scale(1.1);
}

.lineup-button span {
  font-size: 15px;
  font-weight: 500;
}

.csv-controls {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.csv-button {
  flex: 1;
  padding: 12px 16px;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
}

.csv-button:hover {
  background: #f8f8f8;
  border-color: #ddd;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
} 