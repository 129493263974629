.bathroom-category-list-container {
  position: relative;
  margin-bottom: 1.5rem;
}

.bathroom-category-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  min-height: 100px;
}

.scroll-hint {
  display: none;
}

.bathroom-category-list__item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  position: relative;
  margin-bottom: 8px;
  user-select: none;
  gap: 8px;
}

.bathroom-category-list__item:hover {
  background-color: #F0F0F0;
  border-color: var(--primary-color);
}

.bathroom-category-list__item--active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.bathroom-category-list__item--active:hover {
  background-color: var(--primary-dark);
}

.bathroom-category-list__item-name {
  flex: 1;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bathroom-category-list__item-actions {
  display: flex;
  gap: 4px;
  opacity: 1;
  visibility: visible;
  position: static;
  transform: none;
}

.bathroom-category-list__edit-btn,
.bathroom-category-list__delete-btn {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  box-shadow: none;
  white-space: nowrap;
}

.bathroom-category-list__edit-btn {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.bathroom-category-list__delete-btn {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.bathroom-category-list__edit-btn:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateX(-2px);
}

.bathroom-category-list__delete-btn:hover {
  background-color: #dc3545;
  color: white;
  transform: translateX(-2px);
}

.bathroom-category-list__add-btn {
  padding: 12px 16px;
  background-color: #f8f9fa;
  border: 2px dashed var(--border-color);
  border-radius: 6px;
  color: var(--text-secondary-color);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bathroom-category-list__add-btn:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: #fff;
}

@keyframes scrollHintLeft {
  0% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scrollHintRight {
  0% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.bathroom-category-list__item--dragging {
  background-color: var(--primary-bg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transform: scale(1.02);
  border: 2px solid var(--primary-color);
  z-index: 1000;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
  .bathroom-category-list {
    flex-direction: column;
    overflow-x: visible;
    overflow-y: auto;
    padding: 1rem;
    gap: 12px;
  }

  .bathroom-category-list__item {
    width: 100%;
    min-width: auto;
    padding: 12px;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }

  .bathroom-category-list__item-name {
    font-size: 15px;
    text-align: center;
    white-space: normal;
    line-height: 1.3;
  }

  .bathroom-category-list__item-actions {
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  .bathroom-category-list__edit-btn,
  .bathroom-category-list__delete-btn {
    padding: 8px 16px;
    font-size: 14px;
    flex: 1;
    max-width: 120px;
  }

  .bathroom-category-list__add-btn {
    flex: 0 0 auto;
    min-width: 200px;
    padding: 16px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
  }

  /* 스크롤 힌트 스타일 개선 */
  .scroll-hint {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: var(--text-secondary-color);
    white-space: nowrap;
    background-color: white;
    padding: 8px 16px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .scroll-hint__text {
    margin: 0 12px;
  }

  .scroll-hint__arrow-left,
  .scroll-hint__arrow-right {
    font-size: 16px;
    color: var(--primary-color);
  }
}

/* 작은 모바일 화면 대응 */
@media (max-width: 480px) {
  .bathroom-category-list {
    padding: 0.75rem;
    gap: 8px;
  }

  .bathroom-category-list__item {
    padding: 10px;
    gap: 10px;
  }

  .bathroom-category-list__item-name {
    font-size: 14px;
  }

  .bathroom-category-list__edit-btn,
  .bathroom-category-list__delete-btn {
    padding: 6px 12px;
    font-size: 13px;
    max-width: 100px;
  }

  .bathroom-category-list__add-btn {
    min-width: 180px;
    padding: 12px;
    font-size: 14px;
  }
} 