:root {
  --primary-color: #946A6A;
  --primary-dark: #7D5A5A;
  --primary-light: #B69090;
  --primary-bg: #F9F6F6;
  --text-color: #4A4A4A;
  --text-secondary-color: #666;
  --border-color: #E0E0E0;
  --shadow-color: rgba(148, 106, 106, 0.1);
}

.bathroom-material {
  min-height: 100vh;
  background-color: var(--primary-bg);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bathroom-material__title {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;
}

.bathroom-material__title:hover {
  color: #4a90e2;
}

.bathroom-material__admin-panel {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
}

.bathroom-material__admin-panel h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
}

.bathroom-material__admin-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bathroom-material__customer-input {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
}

.bathroom-material__form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.bathroom-material__input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bathroom-material__input {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.bathroom-material__input:focus {
  outline: none;
  border-color: #4a90e2;
}

.bathroom-material__input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.bathroom-material__error {
  color: #e74c3c;
  font-size: 0.875rem;
  margin: 0;
}

.bathroom-material__submit {
  padding: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.bathroom-material__submit:hover:not(:disabled) {
  background-color: #357abd;
}

.bathroom-material__submit:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.bathroom-material__message {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.bathroom-material__message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.bathroom-material__message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bathroom-material__admin-exit {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.bathroom-material__admin-exit:hover {
  background-color: #c0392b;
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .bathroom-material {
    padding: 1rem;
  }

  .bathroom-material__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .bathroom-material__admin-panel,
  .bathroom-material__customer-input {
    padding: 1.5rem;
  }

  .bathroom-material__input {
    padding: 0.875rem;
    font-size: 0.875rem;
  }

  .bathroom-material__submit {
    padding: 0.875rem;
    font-size: 0.875rem;
  }

  .bathroom-material__admin-exit {
    padding: 0.875rem;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 480px) {
  .bathroom-material {
    padding: 0.5rem;
  }

  .bathroom-material__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .bathroom-material__admin-panel,
  .bathroom-material__customer-input {
    padding: 1rem;
  }

  .bathroom-material__input {
    padding: 0.75rem;
  }

  .bathroom-material__submit {
    padding: 0.75rem;
  }

  .bathroom-material__admin-exit {
    padding: 0.75rem;
    margin-top: 1.5rem;
  }
}

.customer-number-input {
  width: 100%;
  max-width: 480px;
  margin: 2rem auto;
  padding: 0;
}

.customer-number-input__content {
  width: 100%;
  background-color: white;
  padding: 4rem 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 4px 30px var(--shadow-color);
  text-align: center;
  animation: fadeInScale 0.8s ease-out;
}

.customer-number-input__title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
}

.customer-number-input__subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary-color);
  margin: 1rem 0 2rem;
  opacity: 0;
  animation: fadeInUp 1s ease 0.3s forwards;
}

.customer-number-input__form {
  opacity: 0;
  animation: fadeInUp 1s ease 0.6s forwards;
}

.customer-number-input__field {
  margin-bottom: 1rem;
  text-align: left;
}

.customer-number-input__label {
  display: block;
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.customer-number-input__input {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  border: 2px solid var(--border-color);
  border-radius: 0.75rem;
  transition: all 0.3s ease;
  background-color: var(--primary-bg);
}

.customer-number-input__input:focus {
  outline: none;
  border-color: var(--primary-color);
  background-color: white;
  box-shadow: 0 0 0 4px var(--shadow-color);
}

.customer-number-input__error {
  color: #dc3545;
  font-size: 0.875rem;
  margin: 0.5rem 0 0;
  animation: fadeIn 0.3s ease;
}

.customer-number-input__submit {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.customer-number-input__submit:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.customer-number-input__submit:disabled {
  background-color: var(--border-color);
  cursor: not-allowed;
  transform: none;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 태블릿 스타일 */
@media (min-width: 768px) {
  .customer-number-input__content {
    padding: 5rem 3rem;
  }

  .customer-number-input__title {
    font-size: 3.5rem;
  }

  .customer-number-input__subtitle {
    font-size: 1.5rem;
  }
}

/* 모바일 스타일 */
@media (max-width: 480px) {
  .customer-number-input__content {
    margin: 1rem;
    padding: 3rem 1.5rem;
  }

  .customer-number-input__title {
    font-size: 2.5rem;
  }

  .customer-number-input__subtitle {
    font-size: 1rem;
    margin: 1rem 0 1.5rem;
  }

  .customer-number-input__input,
  .customer-number-input__submit {
    padding: 0.875rem;
    font-size: 1rem;
  }
}

.bathroom-material__bathroom-selector {
  display: flex;
  gap: 1rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 600px;
  padding: 0 1rem;
}

.bathroom-material__bathroom-btn {
  flex: 1;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.bathroom-material__bathroom-btn:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-2px);
}

.bathroom-material__bathroom-btn--active {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material__current-bathroom {
  background-color: var(--primary-bg);
  color: var(--primary-color);
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 2rem;
  border: 1px solid var(--primary-color);
  animation: fadeInDown 0.3s ease-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
  .bathroom-material__bathroom-selector {
    flex-direction: column;
    max-width: 100%;
    padding: 0 1rem;
  }

  .bathroom-material__bathroom-btn {
    width: 100%;
    padding: 0.875rem;
    font-size: 0.9375rem;
  }

  .bathroom-material__current-bathroom {
    font-size: 0.8125rem;
    padding: 0.625rem 1.25rem;
    margin-bottom: 1.5rem;
  }
} 