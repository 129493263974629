.email-pdf-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-pdf-modal {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  width: 500px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.email-pdf-modal-title {
  color: #333;
  font-size: 20px;
  margin: 0 0 25px 0;
  text-align: center;
  font-weight: 600;
  padding: 12px;
  border: none;
  border-radius: 8px;
}

.email-pdf-modal h2 {
  color: #333;
  font-size: 20px;
  margin: 0 0 25px 0;
  text-align: center;
  font-weight: 600;
}

.email-pdf-input-group,
.email-pdf-template-group {
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  padding: 12px;
}

.email-pdf-input-group input,
.email-pdf-input-group textarea,
.email-pdf-template-section select {
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

.email-pdf-input-group input:focus,
.email-pdf-input-group textarea:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.email-pdf-input-group textarea {
  min-height: 100px;
  resize: vertical;
}

.email-pdf-template-section {
  width: 100%;
  margin-bottom: 20px;
}

.email-pdf-template-section select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 10px;
}

.email-pdf-template-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.email-pdf-action-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #E1D8D8;
  background-color: white;
  transition: all 0.2s ease;
}

.email-pdf-action-button.edit {
  background-color: #946A6A;
  color: white;
  border: none;
}

.email-pdf-action-button.delete {
  background-color: #C57E66;
  color: white;
  border: none;
}

.email-pdf-action-button.save {
  background-color: #946A6A;
  color: white;
  border: none;
}

.email-pdf-action-button.cancel {
  background-color: #AFAFAF;
  color: white;
  border: none;
}

.email-pdf-action-button.new {
  background-color: #FCF5F5;
  color: #946A6A;
}

.email-pdf-action-button:hover {
  opacity: 0.9;
}

.email-pdf-template-required {
  border-color: #C57E66 !important;
}

.email-pdf-template-container {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0;
  margin-top: 0;
}

.email-pdf-template-section select {
  flex: 1;
  height: 45px;
  width: calc(100% - 120px);
  cursor: pointer;
}

.email-pdf-template-section select.email-pdf-template-required {
  border-color: #946A6A;
  background-color: #FFF9F9;
}

.email-pdf-button-group {
  display: flex;
  gap: 10px;
  margin-top: 25px;
}

.email-pdf-send-button,
.email-pdf-cancel-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.email-pdf-send-button {
  background-color: #946A6A;
  color: white;
  border: none;
}

.email-pdf-send-button:hover {
  background-color: #7A5656;
}

.email-pdf-send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.email-pdf-cancel-button {
  background-color: #F7F7F6;
  color: #666;
  border: 1px solid #E1D8D8;
}

.email-pdf-cancel-button:hover {
  background-color: #EAEAEA;
}

.email-pdf-error-message {
  color: #e74c3c;
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
}

.email-pdf-success-message {
  color: #27ae60;
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
}

.email-pdf-modal::-webkit-scrollbar {
  width: 8px;
}

.email-pdf-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.email-pdf-modal::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 4px;
}

.email-pdf-modal::-webkit-scrollbar-thumb:hover {
  background: #7A5656;
}

.email-pdf-loading {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 600px) {
  .email-pdf-modal {
    padding: 20px;
    width: 95vw;
  }

  .email-pdf-template-section {
    flex-direction: column;
  }

  .email-pdf-button {
    width: 100%;
  }

  .email-pdf-button-group {
    flex-direction: column;
  }
}

.email-pdf-input-group label,
.email-pdf-template-group label {
  display: block;
  color: #946A6A;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}

.email-pdf-template-section select::placeholder,
.email-pdf-input-group input::placeholder,
.email-pdf-input-group textarea::placeholder {
  color: #999;
  font-size: 14px;
}

.email-pdf-template-buttons {
  display: flex;
  gap: 6px;
  margin-left: 10px;
}

.email-pdf-template-edit-button,
.email-pdf-template-delete-button {
  padding: 8px 12px;
  font-size: 13px;
  border: 1px solid #946A6A;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 45px;
  white-space: nowrap;
}

.email-pdf-template-edit-button {
  background-color: white;
  color: #946A6A;
}

.email-pdf-template-edit-button:hover {
  background-color: #F7F7F6;
}

.email-pdf-template-delete-button {
  background-color: white;
  color: #e74c3c;
  border-color: #e74c3c;
}

.email-pdf-template-delete-button:hover {
  background-color: #fef2f2;
}

.email-pdf-template-edit-form {
  margin-top: 10px;
  padding: 15px;
  background-color: #F7F7F6;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.email-pdf-template-edit-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 6px;
  font-size: 14px;
}

.email-pdf-edit-buttons {
  display: flex;
  gap: 6px;
}

.email-pdf-edit-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.email-pdf-new-template-form {
  background-color: #F7F7F6;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
}

.email-pdf-new-template-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 15px;
  background-color: white;
}

.email-pdf-new-template-form input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.email-pdf-template-form-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.email-pdf-template-form-buttons button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.email-pdf-template-form-buttons .save {
  background-color: #946A6A;
  color: white;
  border: none;
}

.email-pdf-template-form-buttons .cancel {
  background-color: #AFAFAF;
  color: white;
  border: none;
}

.email-pdf-template-form-buttons button:hover {
  opacity: 0.9;
} 