.page-controls {
  position: absolute;
  top: 10px;
  right: -50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1000;
}

.page-control-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: white;
  color: #946A6A;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.page-control-button:hover {
  transform: scale(1.1);
  background-color: #946A6A;
  color: white;
}

.page-control-button.duplicate {
  background-color: #946A6A;
  color: white;
}

.page-control-button.duplicate:hover {
  background-color: #7a5757;
}

.page-control-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.control-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.control-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

.control-button.add-type {
  background-color: #28a745;
}

.control-button.delete {
  background-color: #dc3545;
}

.control-button.duplicate {
  background-color: #17a2b8;
  font-size: 28px;
  font-weight: bold;
}

.control-button.add-material {
  background-color: #946A6A;
}

.control-button.add-type:hover {
  background-color: #218838;
}

.control-button.delete:hover {
  background-color: #c82333;
}

.control-button.duplicate:hover {
  background-color: #138496;
}

.control-button.add-material:hover {
  background-color: #7a5757;
}

.control-button.change-template {
  background-color: #6c757d;
}

.control-button.change-template:hover {
  background-color: #5a6268;
}

.control-button.manage-material {
  background-color: #946A6A;
}

.control-button.manage-material:hover {
  background-color: #7a5757;
}

@media screen and (min-width: 297mm) {
  .page-control-buttons {
    right: calc((100vw - 297mm) / 2 + 20px);
  }
} 