@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");

/* A4 크기 설정 */
.contract-page {
  padding: 10px;
  background: #f5f5f5;
  min-height: 100vh;
  color: #333;
  font-size: 13px;
  display: flex;
  justify-content: center;
}

.contract-container {
  width: 100%;
  max-width: 21cm;
  margin: 0 auto;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

/* 모바일 대응 */
@media screen and (max-width: 21cm) {
  .contract-container {
    width: 100%;
    padding: 10px;
  }

  .contract-page-1,
  .contract-page-2 {
    margin: 10px 0;
    padding: 15px;
    min-height: auto;
  }
}

/* 계약서 헤더 */
.contract-header {
  text-align: center;
  margin-bottom: 15px;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}

.contract-header h1 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

/* 공사 정보 */
.project-info {
  margin-bottom: 15px;
  width: 100%;
}

.info-row {
  margin-bottom: 8px;
  width: 100%;
}

.project-name {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.address-input {
  flex: 1;
}

.area-input-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.area-input {
  width: 60px;
  text-align: right;
}

.project-period {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.period-input {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  min-width: 200px;
}

.period-input span {
  color: #444;
  font-size: 13px;
  white-space: nowrap;
}

.period-input input[type="date"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 13px;
  color: #444;
  background-color: white;
  width: 140px;
  min-width: 140px;
}

.period-input input[type="date"]::-webkit-calendar-picker-indicator {
  color: #444;
  opacity: 1;
}

.period-input input[type="date"]::-webkit-datetime-edit {
  color: #444;
  padding: 0;
}

.period-input input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* 계약 당사자 정보 */
.party-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .party-info {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .party {
    padding: 20px;
  }

  .info-field {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin: 12px 0;
  }

  .info-field label {
    width: 100%;
    margin-bottom: 4px;
    font-size: 13px;
  }

  .info-field input {
    width: 100%;
    height: 40px;
  }

  .project-name {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .area-input-wrapper {
    width: 100%;
  }

  .area-input {
    width: calc(100% - 24px);
    text-align: left;
  }

  .project-period {
    flex-direction: column;
    gap: 12px;
  }

  .period-input {
    width: 100%;
    min-width: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .period-input input[type="date"] {
    width: 100%;
    min-width: 0;
    height: 40px;
  }
}

.party {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.party h3 {
  font-size: 14px;
  color: #444;
  margin-bottom: 12px;
  text-align: center;
}

.info-field {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.info-field label {
  width: 85px;
  font-weight: 500;
  color: #444;
  font-size: 12px;
}

.info-field input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 13px;
  min-height: 36px;
}

/* 계약 내용 */
.contract-section {
  margin-bottom: 15px;
}

.contract-section h2 {
  font-size: 15px;
  margin-bottom: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid #ddd;
  color: #444;
}

.term-item {
  margin: 6px 0;
  line-height: 1.4;
  font-size: 12px;
  color: #444;
}

/* 지불 정보 */
.payment-info {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.payment-schedule {
  width: 100%;
  border-collapse: collapse;
}

.payment-schedule h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  padding: 12px 16px;
  background: #f8f8f8;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.schedule-item:last-child {
  border-bottom: none;
}

.schedule-item label {
  color: #333;
  font-size: 13px;
  font-weight: 500;
}

.schedule-item .amount {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
}

/* 서명 영역 */
.contract-footer {
  margin-top: 20px;
}

.date {
  text-align: center;
  margin-bottom: 15px;
  font-size: 12px;
  color: #444;
}

.date-input {
  width: 40px;
  text-align: center;
  margin: 0 4px;
  padding: 2px;
  border: none;
  border-bottom: 1px solid #444;
  font-size: 13px;
}

.signatures {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 15px;
}

.signature-field {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
}

.signature-field h3 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.signature-input-group {
  margin-bottom: 10px;
}

.signature-input-group label {
  display: block;
  font-size: 12px;
  color: #444;
  margin-bottom: 4px;
}

.signature-input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.signature-input-group input[readonly] {
  background-color: #f8f8f8;
}

.signature-box {
  width: 100%;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  margin-top: 4px;
}

/* 인쇄 스타일 */
@media print {
  .contract-container {
    padding: 0;
    background: white;
  }

  .contract-page-1,
  .contract-page-2 {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
  
  .contract-page-2 {
    page-break-before: always;
  }
} 

/* 샷시 공사 토글 */
.sash-option {
  margin-top: 15px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  display: inline-block;
  width: 60px;
  height: 26px;
  background-color: #ccc;
  border-radius: 13px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.toggle-switch input:checked + label {
  background-color: #4CAF50;
}

.toggle-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
}

.contract-actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  margin-top: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media print {
  .contract-actions {
    display: none;
  }
} 

/* 페이지 구분 */
.contract-page-1,
.contract-page-2 {
  width: 100%;
  min-height: 29.7cm;
  padding: 40px;
  background: white;
  margin: 0 0 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-sizing: border-box;
}

/* 계약 일반조건 스타일 */
.contract-terms-section {
  margin-top: 20px;
}

.terms-article {
  margin-bottom: 15px;
}

.terms-article h3 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.terms-article p {
  font-size: 12px;
  line-height: 1.6;
  color: #444;
  margin: 4px 0;
  text-align: justify;
  word-break: keep-all;
} 