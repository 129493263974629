.notification-panel {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 0;
  width: 100%;
  position: sticky;
  top: 10px;
  z-index: 100;
}

.ai-message {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.ai-message-header {
  background-color: #946A6A;
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.ai-message-header h3 {
  color: white;
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  flex: 1;
}

.ai-settings-icon {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.ai-settings-icon:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.ai-message-content {
  padding: 15px;
  background-color: #fff;
}

.schedule-section {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  animation: fadeIn 0.3s ease-out forwards;
}

.schedule-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.date-header {
  color: #946A6A;
  font-size: 14px;
  margin: 0 0 10px 0;
  font-weight: 500;
}

.schedule-item {
  margin: 5px 0;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.material-alert,
.demolition-alert {
  color: #E65100 !important;
  font-size: 11px;
  font-weight: 500;
  white-space: normal;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 8px;
  background-color: #FFF3E0 !important;
  border-radius: 4px;
  border: 1px solid #FFB74D !important;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.material-alert strong,
.demolition-alert strong {
  display: block;
  margin-top: 4px;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.material-preparation .schedule-item {
  background-color: #fff;
  border: 1px solid #eee;
}

.no-schedule {
  color: #666;
  font-size: 13px;
  font-style: italic;
  margin: 5px 0;
}

/* 강조 스타일 */
.ai-message-content p:first-child {
  font-weight: 500;
  color: #946A6A;
  padding-bottom: 10px;
}

/* 자재 주문 알림 섹션 스타일 */
.ai-message-content p:contains("※") {
  margin-top: 15px;
  font-weight: 500;
  color: #946A6A;
}

.ai-message-content p:contains("-") {
  padding-left: 10px;
  color: #666;
}

/* 자재 준비 알림 섹션 스타일 */
.schedule-section.material-schedules {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px;
  margin: 15px 0 0 0;
  border-radius: 4px;
}

.material-schedules .date-header {
  color: #946A6A;
  font-size: 14px;
  margin: 0 0 12px 0;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 4px;
}

.analysis-header-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

.material-schedules .schedule-item {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  padding: 10px;
  margin: 8px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.material-schedules .schedule-item p {
  margin: 0;
  flex: 1;
}

.material-schedules .material-alert {
  color: #E65100 !important;
  font-size: 11px;
  font-weight: 500;
  white-space: normal;
  line-height: 1.4;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: #FFF3E0 !important;
  border-radius: 4px;
  border: 1px solid #FFB74D !important;
}

/* 철거 시작 알림 스타일 */
.schedule-section.demolition-schedules {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px;
  margin: 15px 0 30px 0;
  border-radius: 4px;
}

.demolition-alert {
  color: #E65100;
  font-size: 11px;
  font-weight: 500;
  white-space: normal;
  line-height: 1.4;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: #FFF3E0;
  border-radius: 4px;
  border: 1px solid #FFB74D;
}

/* 공정 집중도 경고 스타일 */
.schedule-section.material-schedules .schedule-item.concentration-warning {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
  margin: 8px 0;
}

.schedule-item.concentration-warning .material-alert {
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 4px;
  padding: 12px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .notification-panel {
    margin: 10px;
    width: calc(100% - 20px);
  }

  .ai-message-content {
    padding: 12px;
  }

  .schedule-item {
    font-size: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 12px;
  }

  .material-alert,
  .demolition-alert {
    font-size: 10px;
    padding: 6px 8px;
  }

  .material-alert strong,
  .demolition-alert strong {
    margin-top: 6px;
  }

  .material-schedules .schedule-item {
    padding: 12px;
    margin: 6px 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .material-schedules .material-alert {
    width: 100%;
    justify-content: flex-start;
    margin-top: 4px;
  }

  .schedule-section.material-schedules {
    padding: 12px;
    margin: 12px 0 0 0;
  }

  .schedule-item.concentration-warning {
    padding: 8px;
    margin: 6px 0;
  }
  
  .schedule-item.concentration-warning .material-alert {
    width: 100%;
    text-align: center;
    margin: 0;
    border-radius: 4px;
  }

  .voice-button {
    width: 44px;
    height: 44px;
    font-size: 20px;
  }
  
  .ai-settings-icon {
    width: 44px;
    height: 44px;
    font-size: 20px;
  }
  
  .refresh-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  .speech-error-message {
    font-size: 11px;
    padding: 6px 10px;
    margin: 6px 0;
  }
}

/* 애니메이션 효과 */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* 애니메이션 효과 */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.schedule-section {
  animation: fadeIn 0.3s ease-out forwards;
}

.refresh-icon {
  background: none;
  border: none;
  color: #946A6A;
  cursor: pointer;
  padding: 4px;
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.refresh-icon.rotating {
  animation: rotate 1s linear;
}

.refresh-icon:hover {
  opacity: 1;
  background-color: rgba(148, 106, 106, 0.1);
}

.refresh-icon:active {
  transform: rotate(180deg);
}

/* 음성 버튼 스타일 */
.voice-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.voice-button:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.voice-button.speaking {
  animation: pulse 1.5s infinite;
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* 오류 메시지 스타일 */
.speech-error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  animation: fadeIn 0.3s ease-out forwards;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 3px solid #d32f2f;
}

.speech-error-message::before {
  content: '⚠️';
  font-size: 14px;
}

/* 음성 진행 상태 표시 */
.notificationpanel-speech-progress-container {
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 8px 0;
  overflow: hidden;
}

.notificationpanel-speech-progress-bar {
  height: 100%;
  background-color: #4285f4;
  border-radius: 2px;
  transition: width 0.5s ease;
}

/* 음성 재생 중 애니메이션 효과 */
@keyframes notificationpanel-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.notificationpanel-speech-progress-bar {
  animation: notificationpanel-pulse 2s infinite;
}

/* 오류 메시지 스타일 개선 */
.notificationpanel-speech-error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 14px;
  border-left: 3px solid #d32f2f;
}

/* 브라우저 지원 메시지 스타일 개선 */
.notificationpanel-browser-support-message {
  color: #0277bd;
  background-color: #e1f5fe;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 14px;
  border-left: 3px solid #0277bd;
}

/* 배터리 부족 표시 스타일 개선 */
.notificationpanel-low-battery-indicator {
  background-color: #ffeb3b;
  color: #212121;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  animation: notificationpanel-pulse 2s infinite;
} 