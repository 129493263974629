.ai-template-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ai-template-modal {
  background: white;
  width: 450px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
}

.modal-header {
  padding: 16px 20px;
  background-color: #946A6A;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-header h3 {
  margin: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.close-button {
  display: none;
}

.modal-content {
  padding: 16px 0 16px 16px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.ai-input-section {
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 0 16px;
  width: 100%;
}

.section-title {
  color: #946A6A;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 12px 0;
}

.ai-template-section {
  margin-top: 32px;
  padding: 24px;
  width: 100%;
  background-color: #F8F8F8;
  border-radius: 8px;
}

.ai-template-section .section-title {
  color: #946A6A;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 24px 0;
  position: relative;
  text-align: center;
  padding: 16px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-template-section .section-title:hover {
  background: transparent;
}

.ai-template-list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.ai-template-preview {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.ai-template-preview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ai-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E1D8D8;
  gap: 16px;
}

.ai-template-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  flex: 1;
}

.ai-template-date {
  font-size: 12px;
  color: #666;
  background: #F5F5F5;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.ai-template-info-row {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  font-size: 13px;
}

.info-label {
  color: #666;
  font-size: 13px;
  font-weight: 500;
}

.info-value {
  color: #333;
  font-size: 13px;
  line-height: 1.4;
}

.ai-template-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0;
  padding: 12px;
  background: #F8F8F8;
  border-radius: 6px;
  border: 1px dashed #E1D8D8;
}

.keyword-tag {
  background: #fff;
  color: #946A6A;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #E1D8D8;
}

.ai-template-items {
  margin-top: 16px;
  padding: 16px;
  background: #F8F8F8;
  border-radius: 6px;
  border: 1px dashed #E1D8D8;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.ai-template-items:hover {
  background-color: #F5F5F5;
}

.items-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  pointer-events: none;
}

.item-tag {
  background: #fff;
  color: #946A6A;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #E1D8D8;
}

.item-tag.more {
  background: #E1D8D8;
  color: #666;
}

.ai-template-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #E1D8D8;
}

.ai-template-use-count {
  font-size: 12px;
  color: #666;
  background: #F5F5F5;
  padding: 4px 8px;
  border-radius: 4px;
}

.ai-template-apply-button {
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ai-template-apply-button:hover {
  background: #7A5656;
}

.modal-footer {
  padding: 12px 16px;
  border-top: 1px solid #E1D8D8;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #F8F8F8;
}

.ai-template-save-button {
  width: 90%;
  max-width: 400px;
  padding: 10px;
  background-color: #946A6A;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.template-save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .ai-template-modal {
    width: 95%;
    margin: 10px;
  }
}

.prompt-search {
  margin: 16px 0 24px 0;
  padding: 0;
}

.prompt-search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.prompt-search-input {
  width: 100%;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  height: 40px;
  display: flex;
  align-items: center;
}

.prompt-search-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.template-details {
  margin: 16px 0;
}

.template-info-row {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  font-size: 13px;
}

.info-label {
  color: #666;
  min-width: 60px;
}

.info-value {
  color: #333;
  flex: 1;
}

.template-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 12px 0;
}

.keyword-tag {
  background: #F5F5F5;
  color: #946A6A;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.template-items {
  margin-top: 16px;
}

.items-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.item-tag {
  background: #F8F3F3;
  color: #946A6A;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.item-tag.more {
  background: #E1D8D8;
}

.template-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #E1D8D8;
}

.template-use-count {
  font-size: 12px;
  color: #666;
}

.template-apply-button {
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.template-apply-button:hover {
  background: #7A5656;
}

.current-items-section {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 0;
  padding: 16px 16px 0 16px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 12px 0 12px 12px;
  border-radius: 4px;
  background-color: #F8F8F8;
}

.section-header h4 {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.section-header h4::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background-color: #946A6A;
  border-radius: 2px;
}

.toggle-button {
  background: none;
  border: 1px solid #946A6A;
  color: #946A6A;
  font-size: 12px;
  cursor: pointer;
  padding: 6px 0;
  min-width: 100px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.toggle-button:hover {
  background-color: #946A6A;
  color: white;
}

.section-header:hover {
  background-color: #F5F5F5;
}

.current-items-list {
  padding: 16px;
  border-radius: 6px;
  background-color: white;
  margin-top: 12px;
}

.items-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr;
  padding: 12px;
  background-color: #F8F8F8;
  border-radius: 4px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #666;
  font-size: 12px;
}

.current-item-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr;
  padding: 12px;
  border-bottom: 1px solid #F5F5F5;
  font-size: 13px;
  align-items: center;
}

.col-name {
  color: #333;
}

.col-quantity {
  text-align: center;
  color: #666;
}

.col-price {
  text-align: center;
  color: #946A6A;
}

.current-item-row:last-child {
  border-bottom: none;
  margin-bottom: 16px;
}

.current-items-list > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  background-color: #F8F8F8;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #E1D8D8;
  font-size: 13px;
}

.current-items-list > div:last-child > span:first-child {
  color: #666;
}

.items-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  background-color: #F8F8F8;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #E1D8D8;
  font-size: 13px;
}

.footer-left {
  color: #666;
  display: flex;
  align-items: center;
}

.footer-right {
  color: #946A6A;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.icon-button {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 8px;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.25);
}

.icon-button svg {
  width: 14px;
  height: 14px;
}

.header-left {
  display: flex;
  align-items: center;
}

.view-all-button,
.view-all-button:hover,
.view-all-button svg {
  display: none;
}

.header-left,
.icon-button {
  display: none;
}

.ai-search {
  margin: 16px 0 24px 0;
  padding: 0;
}

.ai-search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.ai-search-input {
  flex: 1;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  height: 40px;
  display: flex;
  align-items: center;
}

.ai-search-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.ai-search-button {
  padding: 0 20px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-search-button:hover {
  background-color: #7A5656;
}

.ai-search-button:disabled {
  background-color: #E1D8D8;
  cursor: not-allowed;
}

.search-divider {
  margin: 12px 0;
  height: 1px;
  background-color: #E1D8D8;
}

.ai-prompt-input {
  width: 100%;
}

.input-group {
  width: 100%;
}

.template-buttons {
  display: flex;
  gap: 4px;
}

.template-apply-button,
.template-one-min-button,
.template-delete-button {
  min-width: 36px;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.template-delete-button.confirm {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.template-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #E1D8D8;
}

.template-use-count {
  font-size: 12px;
  color: #666;
  background: #F5F5F5;
  padding: 4px 8px;
  border-radius: 4px;
}

.delete-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.delete-confirm-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.delete-confirm-content p {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 14px;
}

.delete-confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancel-button,
.confirm-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #666;
}

.confirm-button {
  background: #dc3545;
  border: none;
  color: white;
}

.cancel-button:hover {
  background: #e9ecef;
}

.confirm-button:hover {
  background: #c82333;
}

.template-info-row.total-price {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.template-info-row.total-price .info-value.price {
  color: #E65D20;
  font-weight: 600;
  font-size: 15px;
}

/* 라인업 선택 모달 스타일 추가 */
.lineup-select-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.lineup-select-modal {
  background: white;
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 320px;
}

.lineup-select-modal h3 {
  margin: 0 0 20px 0;
  text-align: center;
  color: #333;
  font-size: 18px;
}

.lineup-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.lineup-select-button {
  padding: 16px;
  border: none;
  border-radius: 8px;
  background: var(--lineup-color);
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.lineup-select-button:hover {
  filter: brightness(1.1);
  transform: translateY(-1px);
}

.cancel-button {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  color: #666;
  font-size: 14px;
  cursor: pointer;
}

.cancel-button:hover {
  background: #f5f5f5;
}

.info-value.editable {
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 2px 4px;
  border-radius: 4px;
}

.info-value.editable:hover {
  background-color: rgba(148, 106, 106, 0.1);
}

.edit-note-container {
  flex: 1;
  position: relative;
}

.edit-note-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #946A6A;
  border-radius: 4px;
  font-size: 13px;
  min-height: 60px;
  resize: vertical;
  background-color: white;
}

.edit-note-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.edit-note-hint {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 11px;
  color: #666;
}

/* 빈 특이사항일 때의 스타일 */
.info-value.editable:empty::before {
  content: '(더블클릭하여 입력)';
  color: #999;
  font-style: italic;
}

.ai-template-buttons {
  display: flex;
  gap: 4px;
}

.ai-template-apply-button {
  min-width: 36px;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  background: #946A6A;
  color: white;
  border: none;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.ai-template-apply-button:hover {
  background: #7A5656;
}

.ai-template-one-min-button {
  min-width: 36px;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  background: #4A90E2;
  color: white;
  border: none;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.ai-template-one-min-button:hover {
  background: #357ABD;
}

.ai-template-delete-button {
  min-width: 36px;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  background: white;
  color: #dc3545;
  border: 1px solid #dc3545;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.ai-template-delete-button:hover {
  background: #dc3545;
  color: white;
}

.ai-template-delete-button.confirm {
  background: #dc3545;
  color: white;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
} 