.pdf-button {
    width: 100%;
    height: 46px;
    padding: 0 24px;
    background-color: #946A6A;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .pdf-button:hover {
    background-color: #7A5656;
    transform: translateY(-1px);
  }