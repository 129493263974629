.cost-calculation-container {
  width: 100%;
  min-height: 100vh;
  background-color: #F7F7F6;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.cost-calculation-back-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.cost-calculation-back-button:hover {
  background-color: #7a5757;
}

.cost-calculation-content {
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cost-calculation-title {
  color: #946A6A;
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.cost-calculation-title:active {
  opacity: 0.8;
}

.cost-calculation-description {
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.cost-calculation-start-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.cost-calculation-start-button:hover {
  background-color: #7a5757;
}

/* 공정 관리 섹션 */
.cost-calculation-process-management {
  background-color: #FCF5F5;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.cost-calculation-process-list.management {
  margin-top: 15px;
}

.cost-calculation-process-item.management {
  background-color: #fff;
  padding: 10px 15px;
}

/* 현장 선택 섹션 */
.cost-calculation-address-section {
  margin-bottom: 30px;
}

.cost-calculation-address-section h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 10px;
}

.cost-calculation-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

/* 공정 섹션 */
.cost-calculation-process-section {
  background-color: #FCF5F5;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.cost-calculation-process-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.cost-calculation-process-header h3 {
  margin: 0;
  color: #946A6A;
  font-size: 16px;
  font-weight: bold;
}

.cost-calculation-contractor {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.cost-calculation-add-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cost-calculation-add-button:hover {
  background-color: #7a5757;
}

.cost-calculation-process-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cost-calculation-process-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cost-calculation-process-item h3 {
  color: #946A6A;
  font-size: 16px;
  margin: 0;
}

.cost-calculation-delete-button {
  background-color: #C57E66;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cost-calculation-delete-button:hover {
  background-color: #a35e46;
}

.cost-calculation-input-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.cost-calculation-input-group::after {
  content: '원';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  pointer-events: none;
}

.cost-calculation-input {
  flex: 1;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #E1D8D8;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  text-align: right;
  appearance: textfield;
  -moz-appearance: textfield;
  box-sizing: border-box;
  min-width: 0;
}

.cost-calculation-input::-webkit-outer-spin-button,
.cost-calculation-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cost-calculation-input {
  padding-right: 30px;
}

.cost-calculation-input::placeholder {
  text-align: center;
}

.cost-calculation-total-section {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cost-calculation-total-section h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 15px;
}

.cost-calculation-total-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #E1D8D8;
  font-size: 14px;
  color: #333;
}

.cost-calculation-total-row:last-child {
  border-bottom: none;
  font-weight: bold;
}

.cost-calculation-total-row span:last-child {
  color: #946A6A;
  font-weight: bold;
}

/* 모달 스타일 */
.process-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.process-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 320px;
}

.process-modal-content h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.process-modal-input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.process-modal-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 6px;
  font-size: 14px;
  box-sizing: border-box;
}

.process-modal-buttons {
  display: flex;
  gap: 10px;
}

.process-modal-button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #946A6A;
  color: white;
}

.process-modal-button.cancel {
  background-color: #C57E66;
}

.process-modal-button:hover {
  background-color: #7a5757;
}

.process-modal-button.cancel:hover {
  background-color: #a35e46;
}

.cost-calculation-process-header-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.cost-calculation-process-header-actions h2 {
  color: #946A6A;
  font-size: 18px;
  margin: 0 0 10px 0;
  cursor: pointer;
  user-select: none;
}

.cost-calculation-process-header-actions h2:active {
  opacity: 0.8;
}

.cost-calculation-edit-button {
  align-self: center;
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
}

.cost-calculation-edit-button:hover {
  background-color: #7a5757;
}

.cost-calculation-process-info {
  flex: 1;
}

.cost-calculation-process-actions {
  display: flex;
  gap: 5px;
}

.cost-calculation-move-button {
  background-color: #946A6A;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost-calculation-move-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cost-calculation-move-button:not(:disabled):hover {
  background-color: #7a5757;
}

.cost-calculation-hide-button {
  background-color: #C57E66;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cost-calculation-hide-button:hover {
  background-color: #a35e46;
}

.cost-calculation-hidden-processes {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cost-calculation-hidden-processes h3 {
  color: #946A6A;
  font-size: 16px;
  margin: 0 0 10px 0;
}

.cost-calculation-hidden-process-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #FCF5F5;
  border-radius: 6px;
  margin-bottom: 8px;
}

.cost-calculation-hidden-process-item span {
  flex: 1;
  margin-right: 10px;
  word-break: break-all;
  line-height: 1.4;
  font-size: 14px;
}

.cost-calculation-show-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 48px;
}

.cost-calculation-show-button:hover {
  background-color: #7a5757;
}

.cost-calculation-notice {
  color: #946A6A;
  font-size: 14px;
  margin: 0;
  line-height: 1.6;
  text-align: left;
  font-weight: 500;
  background-color: #FCF5F5;
  padding: 12px;
  border-radius: 8px;
  white-space: pre-line;
  word-break: keep-all;
} 