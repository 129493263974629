/* 기본 레이아웃 */
.settings-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 설정 패널 */
.settings-panel {
  width: 100%;
  max-width: 21cm;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.settings-content {
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
}

/* 설정 행 */
.settings-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

/* 설정 아이템 */
.settings-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.settings-item:first-child {
  flex: 0.7;
}

.settings-item:last-child {
  flex: 0.3;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 48px;
}

.settings-item label {
  margin-right: 10px;
}

/* 토글 스위치 */
.toggle-switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch label {
  display: inline-block;
  width: 64px;
  height: 32px;
  background-color: #ddd;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-switch input:checked + label {
  background-color: #946A6A;
}

.toggle-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  font-weight: 500;
}

/* 저장 섹션 */
.save-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  width: 100%;
  max-width: 700px;
  margin: 20px auto 0;
}

/* 저장 버튼 */
.save-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px !important;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:hover {
  background-color: #7A5656;
}

.save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.save-message {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
}

.save-message.success {
  color: #2E7D32;
  background-color: #E8F5E9;
}

.save-message.error {
  color: #C62828;
  background-color: #FFEBEE;
}

/* 모바일 대응 */
@media screen and (max-width: 21cm) {
  .settings-wrapper {
    padding: 20px;
  }

  .settings-panel {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .settings-content {
    padding: 20px;
  }

  .settings-row {
    flex-direction: column;
    gap: 20px;
  }

  .settings-item {
    width: 100%;
  }

  .save-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
  }

  .save-button {
    width: 100%;
  }
}

/* 모달 오버레이 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  outline: none !important;
  border: none !important;
}

.modal-overlay * {
  outline: none !important;
  border: none !important;
}

.modal-content {
  background-color: white;
  padding: 20px 40px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* 불필요한 버튼 스타일 제거 */ 

.button-group {
  display: flex;
  gap: 10px;
  align-items: center;
} 