/* Variables */
:root {
  --primary-color: #666;
  --danger-color: #888;
  --text-color: #333;
  --border-color: #ddd;
  --background-color: #f8f9fa;
  --hover-color: #777;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

/* Base */
.cm-container {
  padding: 10px;
  max-width: 360px;
  margin: 0 auto;
}

/* Header */
.cm-header {
  margin-bottom: 15px;
}

.cm-header__title {
  font-size: 20px;
  color: #333;
  margin: 0;
  text-align: center;
}

/* Select */
.cm-select {
  margin-bottom: 15px;
}

.cm-select__dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

/* Table */
.cm-table {
  width: 100%;
  overflow-x: auto;
}

.cm-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.cm-table__header {
  background-color: #f8f8f8;
}

.cm-table__header-cell {
  padding: 8px;
  text-align: center;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #ddd;
}

.cm-table__header-cell--no {
  width: 40px;
}

.cm-table__header-cell--actions {
  width: 120px;
}

.cm-table__row {
  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  height: 50px;
}

.cm-table__row:hover {
  background-color: #f5f5f5;
}

.cm-table__row--active {
  background-color: #f5f5f5;
  border-bottom: none;
}

.cm-table__action-row {
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;
}

.cm-table__cell {
  padding: 15px 8px;
  color: #666;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  vertical-align: middle;
}

.cm-table__cell--no {
  text-align: center;
}

.cm-table__cell--actions {
  display: flex;
  gap: 4px;
  justify-content: center;
}

/* Buttons */
.cm-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  background-color: #946A6A;
  color: white;
  transition: background-color 0.2s;
  white-space: nowrap;
}

.cm-button:hover {
  background-color: #7a5858;
}

.cm-button--edit {
  min-width: 50px;
}

.cm-button--delete {
  min-width: 50px;
}

.cm-button--delete:hover {
  background-color: #c82333;
}

/* Form */
.cm-form {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.cm-form__row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cm-form__input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  text-align: center;
}

.cm-form__button {
  min-width: 80px;
  white-space: nowrap;
}

/* Modal */
.cm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cm-modal__content {
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  width: 90%;
  max-width: 320px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.cm-modal__title {
  font-size: 16px;
  color: #333;
  margin: 0 0 15px 0;
  text-align: center;
  flex-shrink: 0;
}

.cm-modal__input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
}

.cm-modal__buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex-shrink: 0;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.cm-modal__button {
  min-width: 80px;
}

.cm-loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.cm-error {
  text-align: center;
  padding: 40px;
  color: #dc3545;
  background-color: #f8d7da;
  border-radius: 4px;
}

/* 공정명 셀 스타일 */
.cm-table__cell--process {
  color: var(--primary-color);
  font-weight: 500;
}

/* Table actions */
.cm-table__actions {
  padding: 8px;
  display: flex;
  justify-content: center;
  gap: 6px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateY(-10px);
}

.cm-table__action-row .cm-table__actions {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.cm-table__action-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  color: white;
  transition: background-color 0.2s;
  min-width: 60px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-table__action-button--edit {
  background-color: #946A6A;
}

.cm-table__action-button--copy {
  background-color: #4CAF50;
}

.cm-table__action-button--copy:hover {
  background-color: #388E3C;
}

.cm-table__action-button--delete {
  background-color: #dc3545;
}

.cm-table__action-button--delete:hover {
  background-color: #c82333;
}

/* 기존 actions 열 숨기기 */
.cm-table__header-cell--actions,
.cm-table__cell--actions {
  display: none;
}

/* 공정 순서 관리 버튼 */
.cm-select__header {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.cm-button--order {
  white-space: nowrap;
  min-width: 80px;
  background-color: #946A6A;
}

/* 공정 순서 리스트 */
.process-order-list {
  margin: 10px 0;
  overflow-y: auto;
  max-height: calc(80vh - 140px);
  padding-right: 10px;
}

.process-order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #ddd;
  margin-bottom: 6px;
  border-radius: 4px;
  background-color: white;
}

.process-order-buttons {
  display: flex;
  gap: 5px;
}

.cm-button--move {
  padding: 4px 8px;
  min-width: 30px;
  background-color: #946A6A;
}

/* 공정 순서 아이템 수정 */
.process-order-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.process-order-number {
  width: 35px;
  padding: 3px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;
  background-color: white;
  appearance: textfield;
  -moz-appearance: textfield;
  outline: none;
}

.process-order-number:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.2);
}

.process-order-number::-webkit-inner-spin-button,
.process-order-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.process-name {
  font-size: 13px;
  color: #333;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .cm-button {
    width: 100%;
    min-width: auto;
  }

  .cm-table__cell--actions {
    flex-direction: column;
    gap: 4px;
  }

  .cm-button--edit,
  .cm-button--delete {
    width: 100%;
    min-width: auto;
  }

  .cm-select__header {
    flex-direction: column;
  }

  .cm-button--order {
    width: 100%;
  }

  .process-order-item {
    flex-direction: row;
    align-items: center;
  }

  .process-order-number {
    width: 40px;
    padding: 2px;
  }
}

/* 스크롤바 스타일링 */
.process-order-list::-webkit-scrollbar {
  width: 6px;
}

.process-order-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.process-order-list::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 3px;
}

.process-order-list::-webkit-scrollbar-thumb:hover {
  background: #7a5858;
}

/* 미등록 공정 버튼 */
.cm-button--empty {
  background-color: #946A6A;
  white-space: nowrap;
  min-width: 80px;
}

/* 미등록 공정 리스트 */
.empty-process-list {
  margin: 10px 0;
  overflow-y: auto;
  max-height: calc(80vh - 140px);
  padding-right: 10px;
}

.empty-process-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.empty-process-item:hover {
  background-color: #f5f5f5;
  border-color: #946A6A;
}

.empty-process-name {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 50px);
}

.empty-process-message {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}

.cm-button--small {
  padding: 4px 8px;
  font-size: 12px;
  width: 40px;
  min-width: 40px;
  flex-shrink: 0;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .cm-button--empty {
    width: 100%;
  }

  .empty-process-item {
    padding: 8px;
  }
} 