.template-add-edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.template-add-edit-modal {
  background: white;
  width: 95%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.template-add-edit-header {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  background: #fff;
}

.template-add-edit-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  flex: 1;
}

.template-add-edit-close {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.template-add-edit-content {
  padding: 24px;
}

.template-add-edit-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.template-add-edit-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.template-add-edit-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.template-add-edit-select {
  height: 40px;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background: white;
}

.template-add-edit-input {
  height: 40px;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.template-add-edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.template-add-edit-save {
  background: #946A6A;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-add-edit-save:hover {
  background: #7A5656;
}

.template-add-edit-cancel {
  background: white;
  color: #666;
  border: 1px solid #ddd;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-add-edit-cancel:hover {
  background: #f8f9fa;
} 