.processcontainer-container {
  background: white;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.processcontainer-container.processcontainer-selected {
  border-color: #946A6A;
  background-color: #fff9f9;
}

.processcontainer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.processcontainer-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
  flex: 1;
}

.processcontainer-title:hover {
  background-color: #f5f5f5;
}

.processcontainer-label {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  user-select: none;
  flex: 1;
}

.processcontainer-checkbox-group {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px;
}

.processcontainer-checkbox-group input[type="checkbox"] {
  margin: 0;
  accent-color: #946A6A;
  cursor: pointer;
}

.processcontainer-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.processcontainer-days-input {
  width: 60px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.processcontainer-expand-button {
  padding: 6px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.processcontainer-expand-button:hover {
  background-color: #e0e0e0;
}

.processcontainer-expand-button.expanded {
  background-color: #946A6A;
  color: white;
  border-color: #946A6A;
}

.processcontainer-expand-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.processcontainer-expanded-content {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.processcontainer-linked-processes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.processcontainer-linked-header {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  margin-bottom: 4px;
}

.processcontainer-process-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.processcontainer-process-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.processcontainer-process-item:hover {
  background-color: #f0f0f0;
}

.processcontainer-process-item input[type="checkbox"] {
  margin: 0;
  accent-color: #946A6A;
  cursor: pointer;
}

.processcontainer-link-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.processcontainer-link-option {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
}

.processcontainer-link-option input[type="checkbox"] {
  margin: 0;
  accent-color: #946A6A;
  cursor: pointer;
}

@media (max-width: 768px) {
  .processcontainer-process-list {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
} 