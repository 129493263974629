.big-slab {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.big-slab h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.input-form {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #495057;
  font-weight: 500;
}

.input-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.input-group input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.jollycut-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.jollycut-options label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #495057;
  cursor: pointer;
}

.share-button-container {
  text-align: center;
  margin: 20px 0;
}

.share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.share-button:hover:not(:disabled),
.share-button:focus:not(:disabled) {
  background-color: #A87F7F;
  outline: none;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.25);
}

.share-button:disabled {
  background-color: #C4B0B0;
  cursor: not-allowed;
}

.drawing-container {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  max-width: 1200px;
  text-align: center;
  position: relative;
}

.main-drawing {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-width: 95vw;
}

.big-slab-main-drawing {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  max-width: 95vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.info-panel {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}

.info-panel h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
}

.info-panel p {
  margin: 8px 0;
  color: #495057;
  font-size: 16px;
}

.info-panel p:last-of-type {
  margin-top: 30px;
}

.info-panel ul {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.info-panel li {
  margin: 5px 0;
  color: #495057;
  font-size: 16px;
}

.share-container-wrapper {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: hidden;
  display: block !important;
  width: 1200px;
  height: auto;
  background: white;
}

.share-container {
  background: white;
  padding: 40px;
  width: 1200px;
  height: auto;
  position: relative;
  display: block;
  overflow: visible;
}

.share-header h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 28px;
}

.share-drawing {
  margin: 30px 0;
  width: 100%;
  height: auto;
  display: block;
  overflow: visible;
  text-align: center;
  position: relative;
}

.share-drawing svg {
  width: 100% !important;
  height: auto !important;
  display: block;
  overflow: visible;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.share-info {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  display: block;
}

.share-info h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 20px;
}

.share-info p {
  margin: 8px 0;
  color: #495057;
  font-size: 16px;
}

.share-info ul {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.share-info li {
  margin: 5px 0;
  color: #495057;
  font-size: 16px;
}

.dimension-text {
  font-family: Arial, sans-serif;
  fill: #333;
  font-weight: 500;
  text-anchor: middle;
  dominant-baseline: middle;
}

.big-slab-shape-selector {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.big-slab-shape-selector h3 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 20px;
}

.shape-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.shape-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 120px;
}

.shape-option.selected {
  border-color: #946A6A;
  background-color: rgba(148, 106, 106, 0.1);
}

.shape-option input[type="checkbox"] {
  display: none;
}

.shape-icon {
  font-size: 32px;
  margin-bottom: 10px;
  color: #333;
}

.shape-label {
  font-size: 16px;
  color: #495057;
}

.shape-dimensions {
  margin-top: 20px;
}

.shape-dimension-group {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shape-dimension-group h4 {
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  text-align: center;
}

.shape-info {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.shape-info:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.shape-info h4 {
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
}

/* 조각 분류별 스타일 */
.top-piece rect:first-of-type {
  fill: #e6f7ff;
}

.bottom-piece rect:first-of-type {
  fill: #fffbe6;
}

.left-piece rect:first-of-type {
  fill: #f6ffed;
}

.right-piece rect:first-of-type {
  fill: #fff2e8;
}

.dimension-label {
  font-weight: bold;
  fill: #1890ff;
}

/* 조각 그룹 스타일 */
.big-slab-piece-group {
  margin: 10px 0;
  padding: 10px;
}

/* 조각 아이템 스타일 */
.piece-item {
  margin: 10px 0;
}

/* 조각 텍스트 스타일 */
.piece-text {
  font-size: 14px;
  margin: 5px 0;
  font-weight: 500;
  fill: #333;
}

/* 섹션 타이틀 스타일 */
.section-title {
  font-size: 18px;
  font-weight: bold;
  fill: #333;
  margin-bottom: 15px;
}

/* 메인 조각과 졸리컷 조각 스타일 */
.main-piece rect:first-of-type {
  stroke-width: 1;
}

.jollycut-piece rect:first-of-type {
  stroke-width: 0.5;
  stroke-dasharray: 2,2;
}

/* 조각 섹션 스타일 */
.piece-section {
  margin: 25px 0;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

/* 전체 치수 요약 스타일 */
.total-dimension {
  font-size: 20px;
  font-weight: bold;
  fill: #333;
  margin-top: 20px;
  margin-bottom: 30px;
}

.total-dimension .dimension-label {
  font-size: 22px;
  fill: #1890ff;
}

/* 조각 그룹 스타일 */
.big-slab-piece-group {
  margin: 30px 0;
  padding: 20px 0;
  border-top: 2px solid #eaeaea;
}

/* 조각 유형별 색상 구분 강화 */
.top-piece rect:first-of-type {
  fill: #e6f7ff;
}

.bottom-piece rect:first-of-type {
  fill: #fffbe6;
}

.left-piece rect:first-of-type {
  fill: #f6ffed;
}

.right-piece rect:first-of-type {
  fill: #fff2e8;
}

.main-piece.top-piece rect:first-of-type {
  fill: #bae7ff;
}

.main-piece.bottom-piece rect:first-of-type {
  fill: #fffb8f;
}

.main-piece.left-piece rect:first-of-type {
  fill: #b7eb8f;
}

.main-piece.right-piece rect:first-of-type {
  fill: #ffd8b8;
}

.jollycut-piece rect:first-of-type {
  fill: #f0f0f0;
}

@media (max-width: 768px) {
  .big-slab {
    padding: 10px;
  }

  .input-form {
    padding: 15px;
  }

  .jollycut-options {
    grid-template-columns: repeat(2, 1fr);
  }

  .share-button {
    width: 100%;
  }

  .shape-options {
    gap: 10px;
  }

  .shape-option {
    padding: 15px;
    min-width: 100px;
  }

  .shape-icon {
    font-size: 28px;
  }

  .shape-label {
    font-size: 14px;
  }

  .drawing-container {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    overflow: visible;
  }

  .main-drawing,
  .big-slab-main-drawing {
    max-width: 90vw;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 480px) {
  .jollycut-options {
    grid-template-columns: 1fr;
  }

  .input-group input {
    font-size: 14px;
  }

  .share-button {
    font-size: 14px;
  }

  .shape-options {
    flex-direction: column;
    align-items: center;
  }

  .shape-option {
    width: 100%;
    max-width: 200px;
  }
}
