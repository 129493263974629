.customer-number-input {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-sizing: border-box;
  overflow-x: hidden;
  padding: 1rem;
}

.customer-number-input__content {
  width: 100%;
  max-width: 360px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
  background-color: white;
}

.customer-number-input__header {
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
}

.customer-number-input__header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
}

.customer-number-input__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
  letter-spacing: 0.5px;
}

.customer-number-input__subtitle {
  font-size: 0.75rem;
  color: var(--text-secondary-color);
  margin: 0.5rem 0 0;
  letter-spacing: 0.5px;
}

.customer-number-input__form {
  width: 100%;
}

.customer-number-input__field {
  margin-bottom: 1.5rem;
}

.customer-number-input__label {
  display: block;
  font-size: 0.75rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
  text-align: center;
  letter-spacing: 0.5px;
}

.customer-number-input__input {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  text-align: center;
  background-color: white;
  box-sizing: border-box;
  transition: all 0.2s ease;
  letter-spacing: 1px;
}

.customer-number-input__input::placeholder {
  color: #999;
  font-size: 0.75rem;
}

.customer-number-input__input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.customer-number-input__submit {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  color: white;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: 1px;
}

.customer-number-input__submit:hover {
  background-color: white;
  color: var(--primary-color);
}

.customer-number-input__submit:disabled {
  background-color: #f5f5f5;
  border-color: var(--border-color);
  color: var(--text-secondary-color);
  cursor: not-allowed;
}

.customer-number-input__error {
  color: #dc3545;
  font-size: 0.75rem;
  margin: 0.5rem 0 0;
  text-align: center;
  letter-spacing: 0.5px;
}

/* 모바일 스타일 */
@media (max-width: 360px) {
  .customer-number-input {
    padding: 0.75rem;
  }

  .customer-number-input__content {
    padding: 1.5rem 1rem;
  }

  .customer-number-input__header {
    margin-bottom: 1.5rem;
  }

  .customer-number-input__title {
    font-size: 1.125rem;
  }

  .customer-number-input__subtitle {
    font-size: 0.6875rem;
  }

  .customer-number-input__input {
    padding: 0.625rem;
  }

  .customer-number-input__submit {
    padding: 0.625rem;
  }
} 