.processfilter-container {
  width: 100%;
  position: relative;
}

.processfilter-header {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 1em;
  padding-right: 32px;
}

.processfilter-selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
}

.processfilter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.processfilter-search-container {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.processfilter-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
}

.processfilter-search-input:focus {
  border-color: #ddd;
}

.processfilter-list {
  max-height: 250px;
  overflow-y: auto;
}

.processfilter-item {
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  border-bottom: 1px solid #eee;
}

.processfilter-item:last-child {
  border-bottom: none;
}

.processfilter-item:hover {
  background-color: #f9f9f9;
}

.processfilter-item-selected {
  background-color: #f5f5f5;
  color: #333;
}

.processfilter-no-results {
  padding: 12px;
  text-align: center;
  color: #666;
  font-size: 12px;
} 