.process-mgmt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.process-mgmt-content {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.process-mgmt-title {
  margin: 0 0 24px 0;
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 600;
}

.process-mgmt-add-section {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  align-items: center;
}

.process-mgmt-input {
  flex: 1;
  height: 36px;
  padding: 0 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;
  background-color: #fafafa;
}

.process-mgmt-input:focus {
  outline: none;
  border-color: #946A6A;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(148, 106, 106, 0.1);
}

.process-mgmt-input::placeholder {
  color: #999;
}

.process-mgmt-add-btn {
  height: 36px;
  padding: 0 16px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
}

.process-mgmt-add-btn:hover {
  background-color: #855b5b;
  transform: translateY(-1px);
}

.process-mgmt-add-btn:active {
  transform: translateY(0);
}

.process-mgmt-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.process-mgmt-item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 12px;
  background-color: #fafafa;
  border-radius: 8px;
  transition: all 0.2s;
  border: 1px solid #e0e0e0;
  gap: 12px;
  cursor: pointer;
  user-select: none;
}

.process-mgmt-item:hover {
  border-color: #946A6A;
  box-shadow: 0 2px 8px rgba(148, 106, 106, 0.1);
  background-color: #f8f8f8;
}

.process-mgmt-item.selected {
  background-color: #f0e6e6;
  border-color: #946A6A;
  box-shadow: 0 2px 8px rgba(148, 106, 106, 0.15);
}

.process-mgmt-name {
  font-size: 14px;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process-mgmt-id {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.process-mgmt-toggle {
  padding: 6px 12px !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  transition: all 0.2s !important;
  min-width: 60px !important;
  width: auto !important;
  white-space: nowrap !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 28px !important;
}

.process-mgmt-toggle.active {
  background-color: #946A6A !important;
  color: white !important;
}

.process-mgmt-toggle.inactive {
  background-color: #e53935 !important;
  color: white !important;
}

.process-mgmt-toggle:hover.active {
  background-color: #855b5b !important;
}

.process-mgmt-toggle:hover.inactive {
  background-color: #d32f2f !important;
}

.process-mgmt-close {
  width: 100%;
  padding: 12px;
  margin-top: 24px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 6px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.process-mgmt-close:hover {
  background-color: #eeeeee;
}

/* 순서 변경 버튼 스타일 추가 */
.process-mgmt-order-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  min-width: 40px;
}

.process-mgmt-order-input {
  width: 40px;
  height: 24px;
  padding: 0 4px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  background-color: white;
  transition: all 0.2s;
}

.process-mgmt-order-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.process-mgmt-order-input:hover {
  border-color: #946A6A;
}

.process-mgmt-order-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: #946A6A;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: all 0.2s;
}

.process-mgmt-order-btn:hover:not(:disabled) {
  background-color: #855b5b;
  transform: scale(1.1);
}

.process-mgmt-order-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

.process-mgmt-order-btn:active:not(:disabled) {
  transform: scale(0.95);
}

.process-mgmt-migrate-btn {
  width: 100%;
  height: 36px;
  margin-bottom: 24px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-mgmt-migrate-btn:hover:not(:disabled) {
  background-color: #e8e8e8;
  border-color: #d0d0d0;
  color: #444;
}

.process-mgmt-migrate-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.process-mgmt-delete-btn {
  width: 100%;
  height: 36px;
  margin-bottom: 24px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-mgmt-delete-btn:hover {
  background-color: #c0392b;
  transform: translateY(-1px);
}

.process-mgmt-delete-btn:active {
  transform: translateY(0);
} 