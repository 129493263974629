/* 기본 컨테이너 */
.schedule-container {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* 제목 */
.schedule-title {
  font-size: 14px;
  text-align: center;
  margin: 8px 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

/* 현장 폼 */
.schedule-form {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}
  
/* 폼 헤더 */
.form-header {
  display: block;
  margin-bottom: 15px;
}

.form-header-title {
  font-size: 16px;
  margin: 0 0 5px 0;
  word-break: break-all;
  padding-left: 8px;
}

/* 입력 필드 */
.input-group {
  margin-bottom: 10px;
}

.input-label {
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  text-align: left;
}

.input-field {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

/* 체크박스 컨테이너 */
.checkbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 8px;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #eee;
  justify-content: flex-start;
}

/* 체크박스 그룹 */
.checkbox-group {
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  justify-content: flex-start;
}

.checkbox-group input[type="checkbox"] {
  margin: 0;
  accent-color: #946A6A;
}

.checkbox-group .checkbox-label {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  user-select: none;
}

/* 모바일 대응 */
@media (max-width: 480px) {
  .checkbox-container {
    flex-direction: column;
    gap: 8px;
  }
}

/* 공정 그리드 */
.process-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

/* 버튼 */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0;
}

.button {
  width: 100%;
  padding: 6px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.generate-button {
  background-color: #946A6A;
  color: white;
}

.clear-button {
  background-color: #666;
  color: white;
}

.remove-button {
  background-color: #f44336;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  margin-top: 0;
  width: auto;
}

/* 캘린더 섹션 */
.calendar-section {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.calendar-section .fc {
  height: 100% !important;
  overflow: visible;
}

/* FullCalendar 커스텀 스타일 */
.fc {
  flex: 1;
  display: flex !important;
  flex-direction: column !important;
}

.fc .fc-toolbar {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 8px;
  padding: 0 5px;
}

.fc .fc-toolbar-title {
  font-size: 12px !important;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
}

.fc .fc-button {
  padding: 3px 6px !important;
  font-size: 11px !important;
}

.fc .fc-toolbar-chunk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-button-group {
  display: flex !important;
  gap: 4px !important;
}

/* 태블릿 이상 화면 대응 */
@media (min-width: 768px) {
  .schedule-container {
    max-width: 720px;
  }

  .process-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .button-group {
    flex-direction: row;
  }

  .fc .fc-toolbar {
    flex-direction: row;
  }

  .calendar-section {
    height: calc(100vh - 220px); /* 데스크톱에서는 더 큰 높이 */
    min-height: 700px;
  }
}

/* 데스크톱 화면 대응 */
@media (min-width: 1024px) {
  .schedule-container {
    max-width: 960px;
  }

  .process-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .calendar-section {
    height: calc(100vh - 250px);
    min-height: 800px;
  }
}

/* 삭제 버튼 컨테이너 */
.remove-button-container {
  text-align: right;
}

/* 공정 헤더 스타일 */
.process-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  text-align: left;
  cursor: pointer;
}

.process-header .input-label {
  cursor: pointer;
  flex: 1;
  user-select: none;
}

.process-header .checkbox-group {
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.process-header:hover {
  background-color: #f5f5f5;
}

.process-header .checkbox-label {
  font-size: 12px;
  color: #666;
}

.input-group {
  background: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #eee;
}

/* 초기화 버튼 컨테이너 */
.clear-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

/* 초기화 버튼 스타일 수정 */
.clear-button {
  width: auto;
  padding: 6px 12px;
  background-color: #666;
  color: white;
  font-size: 12px;
}

/* 기존 button-group에서 clear-button 관련 스타일 제거 */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0;
}

/* 모바일 반응형 대응 */
@media (max-width: 768px) {
  .clear-button-container {
    margin-bottom: 8px;
  }

  .schedule-container {
    padding: 0;
  }
  
  .calendar-section {
    padding: 10px;
  }
  
  .fc .fc-daygrid-day-frame {
    min-height: 60px !important;
  }
}

/* 초기화 버튼 관련 스타일 제거 */
.clear-button-container,
.clear-button {
  display: none;
}

/* 탭이블 형태의 탭 스타일 */
.tab-table {
  display: table;
  width: 100%;
  table-layout: fixed;
  border: 1px solid #ddd;
  margin: 15px 0 15px 0;
  height: 37px;
  min-height: 37px;
}

/* 탭 셀 스타일 */
.tab-cell {
  display: table-cell;
  width: 33.333%;  /* 3개의 탭을 위해 33.333%로 변경 */
  padding: 8px 12px;
  text-align: center;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 12px;
  color: #666;
  border-right: 1px solid #ddd;
  vertical-align: middle;
  transition: all 0.2s ease;
}

/* 마지막 셀의 오른쪽 보더 제거 */
.tab-cell:last-child {
  border-right: none;
}

/* 활성화된 탭 */
.tab-cell.active {
  font-weight: 700;
  color: #333;
  background-color: #fff;
}

/* 탭 호버 효과 */
.tab-cell:hover {
  background-color: #fff;
  color: #333;
}

/* 탭 컨텐츠 */
.settings-section,
.notification-section,
.calendar-section {
  flex: 1;
  padding: 10px;
}

.settings-section,
.notification-section {
  height: calc(100vh - 47px);  /* 탭 높이(37px)와 마진(10px) 제외 */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* 스크롤 가능한 컨테이너 */
.settings-scrollable-content,
.notification-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;  /* 스크롤바 공간 확보 */
  margin-right: -10px;  /* 패딩만큼 여백 상쇄 */
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  -webkit-overflow-scrolling: touch; /* iOS 스크롤 부드럽게 */
}

/* Chrome, Safari 스크롤바 숨기기 */
.settings-scrollable-content::-webkit-scrollbar,
.notification-scrollable-content::-webkit-scrollbar {
  display: none;
}

/* 캘린더 뷰 컨테이너 */
.calendar-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 캘린더 래퍼 */
.calendar-wrapper {
  flex: 1;
  display: flex !important;
  flex-direction: column !important;
}

/* 스와이프 중 텍스트 선택 방지 */
.calendar-wrapper * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* FullCalendar 컨테이너 */
.calendar-wrapper .fc {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

/* FullCalendar 뷰 */
.calendar-wrapper .fc-view {
  flex: 1 !important;
}

/* 날짜 그리드 */
.fc .fc-daygrid-body {
  width: 100% !important;
}

.fc .fc-scrollgrid,
.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section > td,
.fc .fc-scrollgrid-section-body {
  height: 100% !important;
}

.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  height: 100% !important;
}

/* 각 날짜 셀의 크기 조정 */
.fc .fc-daygrid-day {
  height: auto !important;
}

.fc .fc-daygrid-day-frame {
  min-height: 80px !important;
  height: 100% !important;
}

/* 이벤트 컨테이너 */
.fc-daygrid-day-events {
  margin: 0 !important;
  padding: 2px !important;
}

/* 이벤트 스타일 */
.fc-event {
  margin: 1px 0 !important;
  padding: 2px !important;
  color: var(--event-text-color, #000000) !important;
}

/* 이벤트 제목 */
.fc-event-title {
  font-size: 8px !important;
  line-height: 1.2 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* 반응형 조정 */
@media (max-width: 768px) {
  .schedule-container {
    padding: 0;
  }

  .settings-section,
  .notification-section,
  .calendar-section {
    padding: 10px;
  }

  .schedule-title {
    margin: 5px 0 20px 0;
    padding-bottom: 10px;
  }

  .tab-cell {
    padding: 6px 12px;
    font-size: 12px;
  }

  .tab-table {
    margin: 8px 0 10px 0;
  }
}

/* 셀 내부 텍스트 중앙정렬 */
.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  width: 100%;
  text-align: center !important;
  text-decoration: none !important;
}

/* 요일 표시 스타일 */
.fc-col-header-cell-cushion {
  font-size: 9px !important;
  font-weight: normal !important;
}

/* 날짜 셀 내부 정렬 */
.fc .fc-daygrid-day-top {
  justify-content: center !important;
  flex-direction: column !important;
  min-height: 40px !important;
  padding-bottom: 10px !important;
}

/* 날짜 숫자 스타일 */
.fc-daygrid-day-number {
  font-size: 9px !important;
  padding: 4px 0 !important;
  width: 100% !important;
  text-align: center !important;
  text-decoration: none !important;
}

/* 날짜 아래 클릭 가능한 영역 추가 */
.fc-daygrid-day-top::after {
  content: '';
  display: block;
  width: 100%;
  height: 15px;
  cursor: pointer;
}

/* 이벤트 컨테이너 위치 조정 */
.fc-daygrid-day-events {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

/* 이벤트 텍스트 정렬 */
.fc-event-title {
  text-align: center !important;
  width: 100% !important;
  font-size: 8px !important;
  white-space: pre-wrap !important;
  padding: 2px 0 !important;
  line-height: 1.2 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: visible !important;
}

/* 력 셀 패딩 축소 */
.fc td, .fc th {
  padding: 1px !important;
}

/* 이전/다음 버튼 스타일 */
.fc .fc-prev-button,
.fc .fc-next-button {
  background-color: transparent !important;
  border: none !important;
  padding: 4px 8px !important;
}

.fc .fc-prev-button:hover,
.fc .fc-next-button:hover {
  background-color: #f0f0f0 !important;
}

/* 이전/다음 버튼을 위 독립적인 스타일 */
.fc .fc-button.fc-prev-button,
.fc .fc-button.fc-next-button {
  background-color: transparent !important;
  border: none !important;
  padding: 4px 8px !important;
  color: #333 !important;
  box-shadow: none !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
}

/* 버튼 아이콘 스타일 */
.fc .fc-button.fc-prev-button .fc-icon,
.fc .fc-button.fc-next-button .fc-icon {
  font-size: 1.2em !important;
  color: #666 !important;
}

/* 호버 효과 */
.fc .fc-button.fc-prev-button:hover,
.fc .fc-button.fc-next-button:hover {
  background-color: #f0f0f0 !important;
}

/* 버튼 활성화 상태 */
.fc .fc-button.fc-prev-button:active,
.fc .fc-button.fc-next-button:active {
  background-color: #e0e0e0 !important;
}

/* 버튼 비활성화 상태 제거 */
.fc .fc-button.fc-prev-button:disabled,
.fc .fc-button.fc-next-button:disabled {
  opacity: 1 !important;
  cursor: pointer !important;
}

/* 툴바 정렬 수정 */
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 10px !important;
}

/* 이벤트 컨테이너 높이 조정 */
.fc-daygrid-event {
  padding: 1px 2px !important;
  margin-top: 1px !important;
  min-height: 32px !important;
}

/* 모달 오버레이 */
.event-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px 0;
}

/* 모달 컨테이너 */
.event-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 320px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: auto;
}

/* 모달 헤더 */
.event-modal-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding: 15px;
  border-bottom: 1px solid #eee;
  border-radius: 8px 8px 0 0;
}

.event-modal-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

/* 닫기 버튼 */
.close-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 모달 컨텐츠 */
.event-modal-content {
  padding: 15px;
  overflow-y: auto;
  flex: 1;
  -webkit-overflow-scrolling: touch;
}

/* 이벤트 상세 정보 */
.event-detail {
  margin-bottom: 12px;
}

.event-detail:last-child {
  margin-bottom: 0;
}

.event-detail label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.event-detail p {
  margin: 0;
  font-size: 14px;
  color: #333;
  word-break: break-all;
}

/* select 스타일 */
.input-field[type="text"],
.input-field[type="date"],
select.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: white;
  height: 36px;
  line-height: 20px;
}

select.input-field {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 1em;
  padding-right: 32px;
}

select.input-field:focus {
  outline: none;
  border-color: #4A90E2;
}

/* 테이블 레이아웃 수정 */
.fc-scrollgrid {
  height: calc(100% - 10px) !important;
}

.fc-daygrid-body {
  height: 100% !important;
}

/* 마지막 행의 셀에 패딩 추가 */
.fc-daygrid-body tr:last-child td {
  padding-bottom: 8px !important;
}

/* 폼 헤더 컨텐츠 레이아웃 */
.form-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

/* 버튼 컨테이너 스타일 - 하나로 통합 */
.form-header-buttons {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 28px;
}

/* 헤더 버튼 공통 스타일 */
.form-header-buttons .button {
  height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
}

/* 초기화 버튼 스타일 */
.form-header-buttons .reset-button {
  background-color: #808080;
  color: white;
}

/* 삭제 버튼 스타일 */
.form-header-buttons .remove-button {
  background-color: #f44336;
  color: white;
}

/* 날짜 숫자 크기 수정 */
.fc-daygrid-day-number {
  font-size: 9px !important;
}

/* 모달 버튼 컨테이너 */
.modal-buttons {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}

/* 모달 버튼 스타일 */
.modal-buttons .button {
  flex: 1;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

/* 모달 버튼 그룹 스타일 */
.modal-button-group {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background: white;
  padding: 15px;
  border-top: 1px solid #eee;
  margin-top: 0;
}

/* 모달 버튼 공통 스타일 */
.modal-button {
  flex: 1;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  background-color: #946A6A;
  color: white;
}

/* 버튼 호버 효과 */
.modal-button:hover {
  opacity: 0.9;
}

/* 버튼 활성화 효과 */
.modal-button:active {
  opacity: 0.8;
}

/* 모달 입력 필드 스타일 수정 */
.event-detail .input-field {
  margin-top: 4px;
  width: 100%;
}

/* 이벤트 상세 정보 간격 조정 */
.event-detail {
  margin-bottom: 16px;
}

/* 버튼 컨테이너 정렬 */
.form-header-buttons {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 28px;
}

/* 헤더 버튼 컨테이너 */
.header-buttons-container {
  display: flex;
  align-items: center;
  gap: 3px;
  width: auto;
  justify-content: flex-start;
  margin-top: 4px;
  padding-left: 0;
}

/* 헤더 버튼 공통 스타일 */
.header-button {
  height: 28px;
  min-width: 40px;
  max-width: 50px;
  padding: 0 6px;
  font-size: 11px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 초기화 버튼 */
.header-button-reset {
  background-color: #808080;
  color: white;
}

/* 삭제 버튼 */
.header-button-remove {
  background-color: #f44336;
  color: white;
}

/* 날짜 셀 크기 자동 조정 */
.fc-daygrid-body {
  height: 100% !important;
}

.fc-daygrid-day {
  height: auto !important;
}

/* 이벤트 텍스트 크기 조정 */
.fc-event-title {
  font-size: 8px !important;
  line-height: 1.1 !important;
}

/* 체크박스 커스텀 스타일 */
input[type="checkbox"] {
  accent-color: #946A6A;
}

/* 체크박스에 마우스 올렸을 때 약간 투명하게 처리 */
input[type="checkbox"]:hover {
  opacity: 0.8;
}

/* 이벤트 스타일 수정 */
.fc-event {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* 이벤트 내부 텍스트 정렬 */
.fc-event-title {
  text-align: center !important;
  width: 100% !important;
  font-size: 8px !important;
  white-space: pre-wrap !important;
  padding: 2px 0 !important;
  line-height: 1.2 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: visible !important;
}

/* 이벤트 내부 텍스트 줄바꿈 처리 */
.fc-event-title-container {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

/* 여러 날짜에 걸친 이벤트 스타일 */
.fc-event-main {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

.fc-event-main-frame {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

/* 삭제 버튼 스타일 */
.delete-button {
  background-color: #f44336;
  color: white;
}

/* 삭제 확인 모달 스타일 */
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.confirm-modal {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirm-modal-message {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.confirm-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirm-modal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  background-color: #946A6A;
  color: white;
  min-width: 60px;
}

/* 공정 체크박스 스타일 추가 */
.process-checkboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 8px;
  background: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
}

.process-checkbox-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.process-checkbox-item input[type="checkbox"] {
  margin: 0;
}

.process-checkbox-item label {
  font-size: 12px;
  color: #333;
  cursor: pointer;
}

/* 모바일에서는 1열로 표시 */
@media (max-width: 480px) {
  .process-checkboxes {
    grid-template-columns: 1fr;
  }
}

/* 공유 버튼 스타일 */
.header-button-share {
  background-color: #946A6A;
  color: white;
}

/* 헤더 버튼 호버 효과 */
.header-button:hover {
  opacity: 0.9;
}

/* 헤더 버튼 활성화 효과 */
.header-button:active {
  opacity: 0.8;
}

/* 전체 선택 체크박스 스타일 */
.all-process-group {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  margin-bottom: 15px;
  grid-column: 1 / -1;  /* 전체 너비 차지 */
}

.all-process-group .process-header {
  padding: 8px;
}

.all-process-group .input-label {
  font-weight: 500;
  color: #333;
}

/* 체크박스 라벨 커서 스타일 */
.checkbox-label {
  cursor: pointer;
}

/* 체크박스 그룹 호버 효과 */
.checkbox-group:hover {
  opacity: 0.8;
}

/* 체크박스 컨테이너 스타일 */
.checkbox-container {
  display: flex;
  gap: 20px;
  margin: 10px 8px;
  padding: 5px 0;
}

/* 체크박스 그룹 스타일 수정 */
.checkbox-group {
  display: flex;
  align-items: center;
  gap: 6px;
}

.checkbox-group input[type="checkbox"] {
  margin: 0;
}

.checkbox-group .checkbox-label {
  font-size: 12px;
  color: #666;
  cursor: pointer;
  user-select: none;
}

/* 체크박스 호버 효과 */
.checkbox-group:hover {
  opacity: 0.8;
}

/* 상담 관련 이벤트 스타일 */
.fc-event.consultation-event {
  background-color: #000000 !important;
  border-color: #000000 !important;
  --event-text-color: #FFFFFF !important;
}

.fc-event.consultation-event .fc-event-title,
.fc-event.consultation-event .fc-event-main {
  color: #FFFFFF !important;
}

/* 체크박스 그룹에서 상담 관련 항목 스타일 */
.process-checkbox-item[data-process="사무실 상담"],
.process-checkbox-item[data-process="현장상담"] {
  background-color: #000000;
  color: #FFFFFF;
  padding: 4px 8px;
  border-radius: 4px;
}

.process-checkbox-item[data-process="사무실 상담"] label,
.process-checkbox-item[data-process="현장상담"] label {
  color: #FFFFFF;
}

.process-checkbox-item[data-process="사무실 상담"] input[type="checkbox"],
.process-checkbox-item[data-process="현장상담"] input[type="checkbox"] {
  accent-color: #FFFFFF;
}

/* 상담 이벤트 스타일 */
.fc-event.consultation-event {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.fc-event.consultation-event .fc-event-main {
  color: #FFFFFF !important;
}

.fc-event.consultation-event .fc-event-title {
  color: #FFFFFF !important;
}

/* 상담 이벤트 호버 효과 */
.fc-event.consultation-event:hover {
  opacity: 0.9;
}

/* 문자 버튼 스타일 추가 */
.header-button-sms {
  background-color: #4A90E2;
  color: white;
}

/* 메모 입력 필드 스타일 */
.memo-input {
  resize: vertical;
  min-height: 60px;
  font-size: 12px;
  line-height: 1.5;
  padding: 8px;
}

/* 메모 이벤트 스타일 */
.fc-event.memo-event {
  background-color: #808080 !important;
  border-color: #808080 !important;
  --event-text-color: #FFFFFF !important;
}

.fc-event.memo-event .fc-event-title,
.fc-event.memo-event .fc-event-main {
  color: #FFFFFF !important;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .event-modal {
    width: 95%;
    margin: auto;
  }

  .event-modal-content {
    max-height: calc(90vh - 130px);
  }
}

/* 공정 관리 버튼 컨테이너 */
.process-management-button-container {
  margin: 0 0 15px 0;
  padding: 0 10px;
}

/* 공정 관리 버튼 */
.process-management-button {
  width: 100%;
  padding: 8px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

/* 공정 관리 버튼 호버 효과 */
.process-management-button:hover {
  opacity: 0.9;
}

/* 공정 관리 버튼 활성화 효과 */
.process-management-button:active {
  opacity: 0.8;
}

/* 공휴일 스타일 */
.holiday-cell {
  background-color: #FFE6E6 !important;
}

.holiday-event {
  opacity: 0.3;
}

/* 공휴일 날짜 색상 */
.holiday-cell .fc-daygrid-day-number {
  color: #FF0000 !important;
}

/* 일요일 날짜 색상 */
.fc-day-sun .fc-daygrid-day-number {
  color: #FF0000 !important;
}

/* 토요일 날짜 색상 */
.fc-day-sat .fc-daygrid-day-number {
  color: #0000FF !important;
}

/* 공휴일 이벤트 제목 스타일 */
.holiday-event .fc-event-title {
  font-size: 10px !important;
  color: #FF0000 !important;
  font-weight: 500 !important;
}

/* 아코디언 헤더 스타일 */
.accordion-header {
  background-color: white;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  min-height: 70px;
}

.accordion-header.active {
  background-color: white;
}

/* 아코디언 콘텐츠 스타일 */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: white;
}

.accordion-content.active {
  max-height: 3000px;
  padding: 15px;
}

/* 아코디언 화살표 아이콘 */
.accordion-arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.accordion-arrow::after {
  content: '▼';
  font-size: 12px;
  color: #666;
}

.accordion-arrow.active {
  transform: rotate(180deg);
}

/* 아코디언 헤더 내용 */
.accordion-header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: 0;
}

.accordion-title {
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  width: 100%;
  word-break: break-all;
  text-align: left;
}

.accordion-subtitle {
  display: none;
}

/* 헤더 버튼 컨테이너 */
.header-buttons-container {
  display: flex;
  align-items: center;
  gap: 3px;
  width: auto;
  justify-content: flex-start;
  margin-top: 4px;
  padding-left: 0;
}

.accordion-header.active .header-buttons-container {
  display: flex;
}

/* 색상 선택 버튼 스타일 */
.header-button-color {
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 0;
  width: 28px;
  min-width: 28px !important;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}

.color-preview {
  width: 100%;
  height: 100%;
  border: none;
}

/* 색상 선택기 컨테이너 */
.color-picker-container {
  position: absolute;
  z-index: 1000;
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
}

/* 색상 선택기 오버레이 */
.color-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
}

/* 색상 팔레트 */
.color-palette {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin-bottom: 10px;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 4px;
}

.color-option {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: transform 0.2s ease;
}

.color-option:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

/* 데스크톱 버전 스타일 */
@media (min-width: 768px) {
  .accordion-header {
    min-height: 44px;
  }

  .accordion-header-content {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  .accordion-title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header-buttons-container {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
}

/* 자재 버튼 스타일 */
.header-button-material {
  background-color: #4CAF50;
  color: white;
}

/* 어두운 배경색에 대한 텍스트 색상 조정 */
.fc-event[style*="background-color: rgb(0, 0, 0)"],
.fc-event[style*="background-color: #000000"],
.fc-event[style*="background-color: rgb(51, 51, 51)"],
.fc-event[style*="background-color: #333333"],
.fc-event[style*="background-color: rgb(68, 68, 68)"],
.fc-event[style*="background-color: #444444"],
.fc-event[style*="background-color: rgb(85, 85, 85)"],
.fc-event[style*="background-color: #555555"],
.fc-event[style*="background-color: rgb(34, 34, 34)"],
.fc-event[style*="background-color: #222222"],
.fc-event[style*="background-color: rgb(17, 17, 17)"],
.fc-event[style*="background-color: #111111"],
.fc-event[style*="background-color: rgb(0, 51, 102)"],
.fc-event[style*="background-color: #003366"],
.fc-event[style*="background-color: rgb(0, 0, 128)"],
.fc-event[style*="background-color: #000080"],
.fc-event[style*="background-color: rgb(0, 0, 139)"],
.fc-event[style*="background-color: #00008B"],
.fc-event[style*="background-color: rgb(25, 25, 112)"],
.fc-event[style*="background-color: #191970"],
.fc-event[style*="background-color: rgb(0, 71, 171)"],
.fc-event[style*="background-color: #0047AB"],
.fc-event[style*="background-color: rgb(128, 0, 0)"],
.fc-event[style*="background-color: #800000"],
.fc-event[style*="background-color: rgb(139, 0, 0)"],
.fc-event[style*="background-color: #8B0000"],
.fc-event[style*="background-color: rgb(128, 0, 128)"],
.fc-event[style*="background-color: #800080"] {
  --event-text-color: #FFFFFF;
}

.fc-event[style*="background-color"] .fc-event-title,
.fc-event[style*="background-color"] .fc-event-main {
  color: var(--event-text-color, #000000) !important;
}

/* 상담 이벤트 스타일 수정 */
.fc-event.consultation-event {
  background-color: #000000 !important;
  border-color: #000000 !important;
  --event-text-color: #FFFFFF !important;
}

.fc-event.consultation-event .fc-event-main,
.fc-event.consultation-event .fc-event-title {
  color: #FFFFFF !important;
}

/* 메모 이벤트 스타일 수정 */
.fc-event.memo-event {
  background-color: #808080 !important;
  border-color: #808080 !important;
  --event-text-color: #FFFFFF !important;
}

.fc-event.memo-event .fc-event-title,
.fc-event.memo-event .fc-event-main {
  color: #FFFFFF !important;
}

/* 필터 컨테이너 스타일 */
.filters-container {
  display: flex;
  gap: 0;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
}

/* 필터 컨테이너 내부의 모든 자식 요소 (AddressFilter, ProcessFilter) */
.filters-container > div {
  flex: 1;
  width: 50%;
  max-width: 50%;
  padding: 0 5px;
  box-sizing: border-box;
}

/* 모바일 대응 */
@media (max-width: 480px) {
  .filters-container {
    padding: 10px 5px;
  }
  .filters-container > div {
    padding: 0 2.5px;
  }
}

/* 공정 검색 입력 필드 스타일 */
.process-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 8px;
  background-color: white;
}

.process-search-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.process-search-input::placeholder {
  color: #999;
}

/* 공정 체크박스 컨테이너 스타일 수정 */
.process-checkboxes {
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #f8f8f8;
}

/* 검색 결과가 없을 때 메시지 스타일 */
.process-checkboxes:empty::after {
  content: '검색 결과가 없습니다.';
  display: block;
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 12px;
}
 