/* 모달 오버레이 */
.process-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 모달 컨테이너 */
.process-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 320px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/* 모달 헤더 */
.process-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.process-modal-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

/* 닫기 버튼 */
.close-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 모달 컨텐츠 */
.process-modal-content {
  padding: 15px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* 새 공정 추가 섹션 */
.add-process-section {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
}

.process-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 0;
}

.add-process-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
  width: auto;
  min-width: 48px;
}

.add-process-button:hover {
  background-color: #7a5757;
}

/* 에러 메시지 */
.error-message {
  color: #f44336;
  font-size: 12px;
  margin: 8px 0;
  padding: 8px;
  background-color: #ffebee;
  border-radius: 4px;
}

/* 공정 목록 */
.process-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* 공정 아이템 */
.process-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: background-color 0.2s;
  gap: 8px;
}

.process-item:hover {
  background-color: #f0f0f0;
}

.process-name {
  flex: 1;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.process-name:hover {
  background-color: #f0f0f0;
}

.process-edit-container {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.process-edit-input {
  flex: 1;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: white;
  min-width: 0;
}

.process-edit-input:focus {
  outline: none;
  border-color: #946A6A;
}

.process-edit-buttons {
  display: flex;
  gap: 4px;
  flex-shrink: 0;
}

.process-edit-save,
.process-edit-cancel {
  padding: 0;
  width: 32px;
  height: 24px;
  min-width: 32px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-edit-save {
  background-color: #946A6A;
  color: white;
}

.process-edit-cancel {
  background-color: #f5f5f5;
  color: #666;
}

.process-edit-save:hover,
.process-edit-cancel:hover {
  opacity: 0.9;
}

.process-edit-save:active,
.process-edit-cancel:active {
  opacity: 0.8;
}

.delete-process-button {
  padding: 0;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.delete-process-button:hover {
  opacity: 0.9;
}

.delete-process-button:active {
  opacity: 0.8;
}

/* 버튼 호버 효과 */
.add-process-button:hover,
.delete-process-button:hover {
  opacity: 0.9;
}

/* 버튼 활성화 효과 */
.add-process-button:active,
.delete-process-button:active {
  opacity: 0.8;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .process-modal {
    width: 95%;
    margin: 20px;
  }
}

/* 순서 변경 버튼 컨테이너 */
.process-order-buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-right: 4px;
}

/* 순서 변경 버튼 */
.order-button {
  padding: 0;
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.order-button:hover {
  opacity: 1;
}

.order-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

/* 공정 번호 입력 필드 */
.process-number-input {
  width: 50px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  margin-right: 8px;
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge에서 스피너 제거 */
.process-number-input::-webkit-outer-spin-button,
.process-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.process-number-input:focus {
  outline: none;
  border-color: #946A6A;
}

/* 공정 번호 스타일 */
.process-number {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #946A6A;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  margin-right: 4px;
  cursor: pointer;
}

.process-number:hover {
  opacity: 0.9;
}

/* AI 설정 버튼 컨테이너 */
.ai-settings-button-container {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

/* AI 설정 버튼 */
.ai-settings-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: opacity 0.2s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ai-settings-button::before {
  content: '⚙️';
  font-size: 16px;
}

.ai-settings-button:hover {
  opacity: 0.9;
  background-color: #7a5757;
}

.ai-settings-button:active {
  opacity: 0.8;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .ai-settings-button-container {
    padding-top: 15px;
  }

  .ai-settings-button {
    padding: 8px 12px;
    font-size: 12px;
  }
} 