body {
  background-color: #F7F7F6;
  margin: 0;
  padding: 0;
  color: #946A6A;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.content {
  width: 360px;
  min-height: 100vh;
  background-color: #F7F7F6;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

/* 여기서부터는 1분 견적과 관련 없는 다른 컴포넌트들의 스타일을 유지합니다 */

.delete-selected-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.estimates-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.estimates-header {
  display: flex;
  background-color: #FCF5F5;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  margin-bottom: 8px;
}

.estimates-header span {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #946A6A;
}

.estimate-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  margin-bottom: 8px;
}

.estimate-item span {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #946A6A;
}

.checkbox-column {
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-selected-button {
  margin-bottom: 20px;
  background-color: #C57E66;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px; /* 버튼 텍스트 크 12px로 변경 */
}

.delete-selected-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 추가: 체크박 크기 조정 */
.checkbox-column input[type="checkbox"] {
  width: 12px;
  height: 12px;
}

/* 기존 스타일은 유지하고 아래 스타일을 수정 또는 추가합니다 */

.main-screen {
  background-color: #F7F7F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  box-sizing: border-box;
}

.main-title {
  color: #946A6A;
  font-size: 24px;
  margin-bottom: 40px; /* 여백을 20px에서 40px로 증가 */
  text-align: center;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 15px; /* 세로 간격을 20px로 늘리고, 가로 간격은 15px 유지 */
  width: 100%;
  max-width: 360px;
  margin-top: 20px; /* 상단 여백 추가 */
}

.app-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #946A6A;
}

.icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #946A6A;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.icon-circle svg {
  width: 30px;
  height: 30px;
}

.app-name {
  font-size: 10px;
  text-align: center;
  color: #946A6A;
  word-break: keep-all;
  line-height: 1.2;
}

/* 태블릿 및 데스크톱 화면에 대한 미디어 쿼리 */
@media (min-width: 768px) {
  .main-title {
    font-size: 28px;
    margin-bottom: 50px;
  }

  .app-grid {
    grid-template-columns: repeat(4, 1fr);
    max-width: 600px;
    gap: 30px 20px; /* 세로 간격을 30px로 늘리고, 가로 간격은 20px로 설정 */
  }

  .icon-circle {
    width: 70px;
    height: 70px;
  }

  .icon-circle svg {
    width: 40px;
    height: 40px;
  }

  .app-name {
    font-size: 12px;
    margin-top: 8px; /* 아이콘과 텍스트 사이 간격 추가 */
  }
}

/* 기존 스타일 유지 */

.address-management {
  position: relative;
  width: 100%;
  max-width: 360px;
  min-height: 100vh;
  background-color: #F7F7F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-y: auto;
}

.site-info-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-info-section {
  width: 100%;
  max-width: 280px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #F7F7F6; /* FCF5F5에서 F7F7F6로 변경 */
  border-radius: 12px;
  position: relative;
}

.site-info-section::after {
  content: '';
  position: absolute;
  bottom: -20px; /* -10px에서 -20px로 변경 */
  left: 5%;
  width: 90%;
  height: 1px;
  background-color: #E1D8D8;
}

.site-info-section:last-child {
  margin-bottom: 0; /* 마지막 섹션의 하단 여백 제거 */
}

.site-info-section:last-child::after {
  display: none;
}

/* 입력 필드 테두리 유지 */
.address-management input,
.admin-section input,
.site-info-section input {
  border: 1px solid #E1D8D8;
}

/* 나머지 요소들의 테두리 제거 */
.address-management,
.address-management *:not(input),
.admin-section,
.admin-section *:not(input),
.site-info-section,
.site-info-section *:not(input) {
  border: none;
}

/* 각 페이지 컨테이너에 대한 스타일 수정 */
.landing-page,
.address-management,
.admin-page,
.page-container {
  width: 360px;
  min-height: 100vh;
  background-color: #F7F7F6;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}

/* 나머지 스타일은 그대로 유지 */

.address-input-section {
  width: 100%;
  max-width: 240px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-input-section input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.address-input-section button {
  width: 100%;
  height: 40px;
  font-size: 13px;
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.address-input-section button:hover {
  background-color: #7A5656;
}

.site-info-section {
  width: 100%;
  max-width: 280px;
  margin-top: 20px;
  padding: 15px;
  background-color: #F7F7F6; /* FCF5F5에서 F7F7F6로 변경 */
  border-radius: 12px;
}

.site-info-section h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.site-info-section input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.site-info-section button {
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.site-info-section button:first-of-type {
  background-color: #EECACA; /* 공사 완료 버튼 색상 */
  color: #946A6A; /* 텍스트 색상을 어둡게 변경 */
}

.site-info-section button:nth-of-type(2),
.site-info-section button:last-of-type {
  background-color: #946A6A; /* 저장하기와 삭제 버튼 색상을 추가하기 버튼과 동일하게 */
}

.site-info-section button:hover {
  opacity: 0.8;
}

.site-info-section button:disabled {
  background-color: #CCCCCC;
  cursor: not-allowed;
}

/* 모달 스타일 수정 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #FCF5F5;
  padding: 15px;
  border-radius: 12px;
  text-align: center;
  width: 80%;
  max-width: 300px;
}

.modal-content p {
  color: #946A6A;
  margin-bottom: 15px;
  font-size: 14px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-content button {
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  flex: 1;
  max-width: 100px;
}

.modal-content button:hover {
  background-color: #7A5656;
}

.modal-content button:last-child {
  background-color: #C57E66;
}

.modal-content button:last-child:hover {
  background-color: #A35E46;
}

/* 기존 스타일은 유지  스타일을 가하거나 수정합다 */

.address-input-section,
.admin-section {
  width: 100%;
  max-width: 280px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-input-section input,
.admin-section input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.address-input-section button,
.admin-section button {
  width: 100%;
  height: 40px;
  font-size: 13px;
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.address-input-section button:hover,
.admin-section button:hover {
  background-color: #7A5656;
}

.admin-section {
  margin-top: 20px;  /* 상단 여백 줄임 */
  margin-bottom: 20px;  /* 하단 여백 추가 */
}

/* 머지 스타일은 그대로 유지 */

/* 기존 스타일 유지 */

.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

.privacy-policy h1 {
  color: #946A6A;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy p {
  margin-bottom: 15px;
}

.privacy-policy a {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #946A6A;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

/* 기존 스타일 유지 */

.terms-of-service,
.delete-user-data {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

.terms-of-service h1,
.delete-user-data h1 {
  color: #946A6A;
  text-align: center;
  margin-bottom: 20px;
}

.terms-of-service p,
.delete-user-data p {
  margin-bottom: 15px;
}

.terms-of-service a,
.delete-user-data a {
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #946A6A;
  text-decoration: none;
}

.terms-of-service a:hover,
.delete-user-data a:hover {
  text-decoration: underline;
}

.delete-user-data form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.delete-user-data input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #946A6A;
  border-radius: 5px;
}

.delete-user-data button {
  padding: 10px 20px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-user-data button:hover {
  background-color: #7A5656;
}

.delete-user-data .message {
  color: #946A6A;
  text-align: center;
  margin-top: 10px;
}

.instagram-auto {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.dropzone {
  border: 2px dashed #946A6A;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.dropzone:hover, .dropzone.dragging {
  background-color: #FCF5F5;
  border-color: #7A5656;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.image-preview {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;
}

.image-item {
  position: relative;
  flex: 0 0 auto;
  width: 120px;
  height: 120px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
}

.image-item.selected {
  border: 3px solid #946A6A;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-item input[type="checkbox"] {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.image-item:hover input[type="checkbox"],
.image-item.selected input[type="checkbox"] {
  opacity: 1;
}

.upload-settings {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.upload-settings h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 10px;
}

.time-picker {
  display: flex;
  align-items: center;
  background-color: #FCF5F5;
  border: 1px solid #946A6A;
  border-radius: 8px;
  padding: 5px 10px;
}

.time-picker select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  padding: 5px;
  font-size: 14px;
  color: #946A6A;
  cursor: pointer;
  text-align: center;
  width: 60px;  /* 너비를 조금 늘림 */
}

.time-picker select option {
  font-size: 14px;
}

.time-unit {
  font-size: 10px;
  color: #999;
  margin-left: 2px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.upload-settings button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-settings button:hover:not(:disabled) {
  background-color: #7A5656;
}

.upload-settings button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pending-uploads .image-preview {
  margin-bottom: 15px;
}

/* 나머지 스타일은 그대로 유지 */

button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 기존 스타일 유지 */

.instagram-auto button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.instagram-auto button:hover {
  background-color: #7A5656;
}

.instagram-auto button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.instagram-auto input[type="password"] {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #946A6A;
  border-radius: 4px;
}

/* 기 스타일 유지 */

.pending-uploads .image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.pending-uploads .image-item {
  position: relative;
  width: 150px;
  height: 150px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
}

.pending-uploads .image-item.selected {
  border: 3px solid #946A6A;
}

.pending-uploads .image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pending-uploads .image-item input[type="checkbox"] {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.pending-uploads .image-item:hover input[type="checkbox"],
.pending-uploads .image-item.selected input[type="checkbox"] {
  opacity: 1;
}

.pending-uploads button {
  margin-right: 10px;
  margin-bottom: 10px;
}

/* 나머지 스타일은 그대로 유지 */

.instagram-auto .profile-info {
  margin-top: 50px !important;
}

.instagram-auto .fetch-posts-button {
  margin-bottom: 50px !important;
}

.instagram-auto .image-upload-section {
  margin-top: 50px !important;
}

.instagram-auto .pending-uploads {
  margin-top: 50px !important;
}

.instagram-auto .app-description {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1.4;
}

.address-management .input-section {
  width: 100%;
  max-width: 280px;
  margin: 20px auto;
}

.address-management .input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* 관리자 모드 입력 그룹에 대한 추가 스타일 */
.address-management .input-group:first-child {
  margin-bottom: 30px; /* 여백을 30px로 증가 */
}

.address-management .input-group input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.address-management .input-group button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.address-management .input-group button:hover {
  background-color: #7A5656;
}

