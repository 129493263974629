/* 기본 버튼 스타일 */
.hidden-process-button {
  width: 100%;
  height: 46px;
  background-color: #F7F7F6;
  color: #946A6A;
  border: 2px dashed #946A6A;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-process-button:hover {
  background-color: #946A6A;
  color: white;
}

/* 모달 오버레이 */
.hidden-process-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* 모달 컨테이너 */
.hidden-process-modal-container {
  background-color: white;
  border-radius: 12px;
  width: 400px;
  max-width: 90vw;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: modalFadeIn 0.3s ease;
}

/* 모달 헤더 */
.hidden-process-modal-header {
  padding: 20px;
  border-bottom: 1px solid #E1D8D8;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hidden-process-modal-header h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

/* 버튼 그룹 컨테이너 */
.hidden-process-button-group {
  display: flex;
  gap: 10px;
}

/* 0원 공정 숨기기/표시 버튼 공통 스타일 */
.hide-zero-process-button,
.show-zero-process-button {
  flex: 1;
  height: 36px;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 숨기기 버튼 */
.hide-zero-process-button {
  background-color: #946A6A;
  color: white;
}

.hide-zero-process-button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

/* 표시 버튼 */
.show-zero-process-button {
  background-color: #F7F7F6;
  color: #946A6A;
  border: 1px solid #946A6A;
}

.show-zero-process-button:hover {
  background-color: #E1D8D8;
  transform: translateY(-1px);
}

.hide-zero-process-button:active,
.show-zero-process-button:active {
  transform: translateY(0);
}

/* 모달 본문 */
.hidden-process-modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;
}

/* 숨긴 공정 목록 */
.hidden-process-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* 개별 공정 아이템 */
.hidden-process-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  padding: 12px;
  background-color: #F7F7F6;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.hidden-process-item:hover {
  background-color: #F0EDED;
}

/* 공정 이름 */
.hidden-process-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* 숨김 타입 표시 */
.hidden-process-type {
  font-size: 12px;
  color: #946A6A;
  text-align: center;
}

/* 복원 버튼 */
.hidden-process-restore {
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hidden-process-restore:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

/* 모달 푸터 */
.hidden-process-modal-footer {
  padding: 20px;
  border-top: 1px solid #E1D8D8;
}

/* 닫기 버튼 */
.hidden-process-close {
  width: 100%;
  height: 45px;
  background-color: #F7F7F6;
  color: #946A6A;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hidden-process-close:hover {
  background-color: #E1D8D8;
}

/* 스크롤바 스타일링 */
.hidden-process-modal-body::-webkit-scrollbar {
  width: 6px;
}

.hidden-process-modal-body::-webkit-scrollbar-track {
  background: #F7F7F6;
  border-radius: 3px;
}

.hidden-process-modal-body::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 3px;
}

.hidden-process-modal-body::-webkit-scrollbar-thumb:hover {
  background: #7A5656;
}

/* 모달 애니메이션 */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 