.edit-start-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-start-modal {
  background: white;
  border-radius: 12px;
  padding: 30px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.edit-start-modal h3 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 20px;
}

.edit-start-modal p {
  margin: 0 0 25px 0;
  color: #666;
  font-size: 16px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.button-group button {
  padding: 10px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.button-group .confirm-button {
  background-color: #946A6A;
  color: white;
}

.button-group .confirm-button:hover {
  background-color: #7A5656;
}

.button-group .cancel-button {
  background-color: #E1D8D8;
  color: #333;
}

.button-group .cancel-button:hover {
  background-color: #D1C8C8;
} 