.page-minimap {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  max-height: 80vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 297mm) {
  .page-minimap {
    right: calc((100vw - 297mm) / 2 - 220px);
  }
}

.minimap-title {
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.minimap-container {
  padding: 8px;
  overflow-y: auto;
  max-height: calc(80vh - 50px);
}

.minimap-item {
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.minimap-item:hover {
  transform: translateX(-4px);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.minimap-item.active {
  border-color: #946A6A;
  background-color: #fff;
}

.minimap-preview {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minimap-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.minimap-empty {
  color: #999;
  font-size: 14px;
}

.minimap-info {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.page-number {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.work-type {
  font-size: 12px;
  color: #666;
}

/* 드래그 앤 드롭 스타일 */
.minimap-item.dragging {
  opacity: 0.5;
}

.minimap-item.drag-over {
  border-color: #946A6A;
  transform: translateX(-4px);
}

/* 스크롤바 스타일 */
.minimap-container::-webkit-scrollbar {
  width: 6px;
}

.minimap-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.minimap-container::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 3px;
}

.minimap-container::-webkit-scrollbar-thumb:hover {
  background: #7a5757;
}

.minimap-preview.text-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  font-size: 24px;
  color: #666;
  font-family: 'Noto Sans KR', sans-serif;
}

.minimap-preview.text-preview .preview-text {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 10px;
  text-align: center;
} 