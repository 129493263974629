.template-items-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.template-items-modal__container {
  background: #ffffff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.template-items-modal__header {
  padding: 16px 20px;
  border-bottom: 1px solid #E1D8D8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-items-modal__header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.template-items-modal__close-btn {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
}

.template-items-modal__content {
  padding: 16px;
  overflow-y: auto;
}

.template-items-modal__table {
  width: 100%;
}

.template-items-modal__table-header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 12px 8px;
  background-color: #F8F8F8;
  border-radius: 4px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #666;
  font-size: 13px;
}

.template-items-modal__table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 12px 8px;
  border-bottom: 1px solid #F5F5F5;
  font-size: 14px;
  align-items: center;
}

.template-items-modal__col {
  padding: 0 4px;
}

.template-items-modal__col.name {
  color: #333;
}

.template-items-modal__col.quantity {
  text-align: center;
  color: #666;
}

.template-items-modal__col.price {
  text-align: right;
  color: #946A6A;
}

.template-items-modal__footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #E1D8D8;
}

.template-items-modal__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.template-items-modal__total-price {
  color: #946A6A;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 480px) {
  .template-items-modal__container {
    width: 95%;
    max-height: 90vh;
  }
  
  .template-items-modal__table-header,
  .template-items-modal__table-row {
    font-size: 13px;
  }
} 