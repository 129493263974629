.ai-settings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ai-settings-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.ai-settings-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.ai-settings-modal-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.ai-settings-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #946A6A;
  color: white;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 8px;
}

.ai-settings-icon:hover {
  background-color: #7a5757;
}

.ai-settings-modal-content {
  padding: 15px;
  overflow-y: auto;
  flex: 1;
}

.ai-settings-loading {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}

.ai-settings-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ai-settings-option {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
}

.ai-settings-option-text {
  flex: 1;
}

.ai-settings-option-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.ai-settings-option-description {
  display: block;
  font-size: 12px;
  color: #666;
  line-height: 1.4;
}

.ai-settings-option input[type="checkbox"] {
  margin: 0;
  width: 18px;
  height: 18px;
  accent-color: #946A6A;
}

.ai-settings-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 15px;
  border-top: 1px solid #eee;
  background: white;
}

.ai-settings-save-button,
.ai-settings-cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: opacity 0.2s;
}

.ai-settings-save-button {
  background-color: #946A6A;
  color: white;
}

.ai-settings-cancel-button {
  background-color: #f5f5f5;
  color: #666;
}

.ai-settings-save-button:hover,
.ai-settings-cancel-button:hover {
  opacity: 0.9;
}

/* 섹션 구분선 */
.ai-settings-divider {
  height: 1px;
  background-color: #eee;
  margin: 15px 0;
  width: 100%;
}

/* 섹션 제목 */
.ai-settings-section-title {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

/* 음성 설정 옵션 컨테이너 */
.ai-settings-voice-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-top: 10px;
}

.ai-settings-voice-options.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* 슬라이더 옵션 */
.ai-settings-slider-option {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ai-settings-slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-settings-slider-value {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}

.ai-settings-slider {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: #ddd;
  outline: none;
  border-radius: 2px;
}

.ai-settings-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #946A6A;
  cursor: pointer;
}

.ai-settings-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #946A6A;
  cursor: pointer;
  border: none;
}

/* 음성 테스트 버튼 */
.ai-settings-test-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 5px;
  transition: opacity 0.2s;
}

.ai-settings-test-button:hover {
  opacity: 0.9;
}

.ai-settings-test-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* API 키 입력 필드 */
.ai-settings-api-key {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.ai-settings-api-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: white;
}

.ai-settings-api-input:focus {
  outline: none;
  border-color: #946A6A;
}

.ai-settings-api-input::placeholder {
  color: #aaa;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .ai-settings-modal {
    width: 95%;
    margin: 10px;
  }

  .ai-settings-option {
    flex-direction: column;
    gap: 10px;
  }

  .ai-settings-option input[type="checkbox"] {
    align-self: flex-start;
  }

  .ai-settings-voice-options {
    padding: 8px;
  }
  
  .ai-settings-test-button {
    align-self: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .ai-settings-api-key {
    padding: 8px;
  }
} 