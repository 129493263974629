.mobile-add-item-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mobile-add-item-content {
  background-color: white;
  width: 90%;
  max-width: 320px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mobile-add-item-content h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 18px;
  text-align: center;
}

.mobile-add-item-field {
  margin-bottom: 15px;
}

.mobile-add-item-field label {
  display: block;
  margin-bottom: 5px;
  color: #946A6A;
  font-size: 14px;
}

.mobile-add-item-field input {
  width: 100%;
  padding: 12px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 14px;
}

.mobile-add-item-field input:focus {
  outline: none;
  border-color: #946A6A;
}

.mobile-total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #F7F7F6;
  border-radius: 8px;
  margin: 20px 0;
}

.mobile-total-price label {
  font-size: 14px;
  color: #333;
}

.mobile-total-price span {
  font-size: 16px;
  color: #946A6A;
  font-weight: 500;
}

.mobile-item-management-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.mobile-item-management-buttons button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #946A6A;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.mobile-item-management-buttons button:active {
  background-color: #7A5656;
}

.mobile-add-item-buttons {
  display: flex;
  gap: 10px;
}

.mobile-add-item-buttons button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-add-item-buttons button:first-child {
  background-color: #946A6A;
  color: white;
}

.mobile-add-item-buttons button:first-child:active {
  background-color: #7A5656;
}

.mobile-add-item-buttons button:last-child {
  background-color: #E1D8D8;
  color: #333;
}

.mobile-add-item-buttons button:last-child:active {
  background-color: #D1C8C8;
} 