.database-manager {
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}

.database-header {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-bottom: 20px;
}

.database-header h2 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 24px;
}

.header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.filters {
  display: inline-flex;
  gap: 8px;
  padding: 0;
  margin: 0;
}

.filters select,
.action-buttons button {
  all: unset;
  box-sizing: border-box;
  width: 120px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.filters select {
  background-color: white;
  border: 1px solid #ddd;
  padding: 0 28px 0 12px;
  position: relative;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.action-buttons {
  display: inline-flex;
  gap: 8px;
  padding: 0;
  margin: 0;
}

.action-buttons button {
  background: #946A6A;
  color: white;
  gap: 6px;
}

.action-buttons button svg {
  width: 14px;
  height: 14px;
}

.action-buttons button:hover {
  background: #7A5656;
}

.filters select:hover {
  border-color: #946A6A;
}

.refresh-button,
.export-button {
  background: #946A6A;
  color: white;
}

.refresh-button:hover,
.export-button:hover {
  background: #7A5656;
}

.database-content {
  flex: 1;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  overflow: auto;
}

.templates-table {
  width: 100%;
  border-collapse: collapse;
}

.templates-table th,
.templates-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.templates-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #666;
  position: sticky;
  top: 0;
  z-index: 1;
}

.templates-table tbody tr:hover {
  background: #f8f9fa;
}

.loading-cell,
.empty-cell {
  text-align: center;
  padding: 40px !important;
  color: #666;
}

.templates-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.add-button {
  background: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: 8px;
}

.add-button:hover {
  background: #7A5656;
}

.template-edit-button,
.template-clone-button,
.template-delete-button {
  background: rgba(148, 106, 106, 0.1);
  color: #946A6A;
  border: 1px solid rgba(148, 106, 106, 0.2);
  padding: 4px 8px;
  height: 24px;
  line-height: 1;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 40px;
  margin-right: 4px;
}

.template-edit-button:hover {
  background: rgba(148, 106, 106, 0.2);
  border-color: rgba(148, 106, 106, 0.3);
}

.template-clone-button {
  background: rgba(106, 148, 129, 0.1);
  color: #6A9481;
  border: 1px solid rgba(106, 148, 129, 0.2);
}

.template-clone-button:hover {
  background: rgba(106, 148, 129, 0.2);
  border-color: rgba(106, 148, 129, 0.3);
}

.template-delete-button {
  background: rgba(230, 93, 32, 0.1);
  color: #E65D20;
  border: 1px solid rgba(230, 93, 32, 0.2);
  margin-right: 0;
}

.template-delete-button:hover {
  background: rgba(230, 93, 32, 0.2);
  border-color: rgba(230, 93, 32, 0.3);
}

.action-cell {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.template-one-min-button {
  background: rgba(74, 144, 226, 0.1);
  color: #4A90E2;
  border: 1px solid rgba(74, 144, 226, 0.2);
  min-width: 40px;
  padding: 4px 6px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.template-one-min-button:hover {
  background: rgba(74, 144, 226, 0.2);
  border-color: rgba(74, 144, 226, 0.3);
} 