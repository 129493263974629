.drag-handle-list {
  width: 100%;
}

.list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E1D8D8;
  position: relative;
}

.list-item:last-child {
  border-bottom: none;
}

.drag-handle-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
}

.drag-handle {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  color: #946A6A;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.delete-handle {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  color: #e74c3c;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.drag-handle:hover,
.delete-handle:hover {
  opacity: 1;
}

.move-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #E1D8D8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 120px;
  padding: 4px;
}

.move-button {
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  color: #333;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s ease;
}

.move-button:hover:not(:disabled) {
  background-color: rgba(148, 106, 106, 0.1);
}

.move-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.move-button.delete {
  display: none;
}

.item-content {
  flex: 1;
  display: flex;
  min-height: 45px;
  cursor: pointer;
}

.item-content:hover {
  background-color: rgba(148, 106, 106, 0.05);
}

.item-content .col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 12px;
  position: relative;
}

.item-content .col:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: #E1D8D8;
}

.item-content .col.item { 
  width: 20%; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
  line-height: 1.4;
  text-align: center;
  min-height: 45px;
  padding: 8px;
}

.item-content .col.item > span {
  order: 2;
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;
}

.item-content .col.quantity { width: 8%; }
.item-content .col.unit { width: 8%; }
.item-content .col.unit-price { width: 15%; }
.item-content .col.amount { width: 15%; }
.item-content .col.note { width: 34%; }

.additional-tag {
  color: #e74c3c;
  font-weight: 500;
  font-size: 0.9em;
  white-space: nowrap;
  order: 1;
  margin-right: 4px;
}

.separate-tag {
  color: #3498db;
  font-weight: 500;
  font-size: 0.9em;
  white-space: nowrap;
  order: 1;
  margin-right: 4px;
}
