/* 공사 안내문 스타일 */
.cg-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 40px 20px;
}

.cg-container {
  max-width: 21cm; /* A4 width */
  margin: 0 auto;
  background-color: transparent;
}

.cg-paper {
  width: 100%;
  min-height: 29.7cm; /* A4 height */
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}

.cg-header {
  text-align: center;
  padding: 40px 0;
  background-color: #946A6A;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cg-title {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.5px;
  color: #ffffff;
  margin-top: 10px;
}

.cg-content {
  padding: 40px;
}

.cg-greeting {
  margin-bottom: 30px;
  font-size: 18px;
  color: #333;
}

.cg-company-info {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.cg-company-info h2 {
  font-size: 24px;
  color: #946A6A;
  margin: 0 0 10px 0;
}

.cg-address {
  color: #666;
  font-size: 16px;
  margin: 0;
}

.cg-notice {
  margin-bottom: 40px;
  line-height: 1.6;
}

.cg-notice p {
  margin: 0 0 10px 0;
  color: #444;
}

.cg-construction-info {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 40px;
}

.cg-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.cg-info-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.cg-label {
  color: #666;
  font-size: 16px;
}

.cg-value {
  color: #333;
  font-weight: 500;
}

.cg-contact {
  text-align: center;
  margin-bottom: 40px;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.cg-contact p {
  margin: 0 0 15px 0;
  color: #666;
}

.cg-phone {
  display: inline-block;
  color: #946A6A;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.cg-phone:hover {
  color: #7A5656;
}

.cg-schedule {
  margin-top: 40px;
}

.cg-schedule-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #946A6A;
}

.cg-table {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.cg-table-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background-color: #f8f9fa;
  padding: 15px 20px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.cg-table-header span {
  text-align: center;
  padding: 0 10px;
}

.cg-table-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  text-align: center;
  align-items: center;
}

.cg-table-row span {
  text-align: center;
  padding: 0 10px;
}

.cg-period-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  text-align: center;
}

.cg-noise-level {
  color: #666;
  font-size: 14px;
  text-align: center;
}

/* 다운로드 섹션 스타일 */
.cg-download-section {
  margin-top: 30px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.cg-toggle-button {
  background-color: #f8f9fa;
  color: #666;
  border: 1px solid #e9ecef;
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.cg-toggle-button:hover {
  background-color: #e9ecef;
  color: #333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cg-download-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.cg-download-button:hover {
  background-color: #7A5656;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .cg-wrapper {
    padding: 20px 10px;
  }

  .cg-content {
    padding: 20px;
  }

  .cg-title {
    font-size: 24px;
  }

  .cg-company-info h2 {
    font-size: 20px;
  }

  .cg-construction-info {
    padding: 20px;
  }

  .cg-phone {
    font-size: 20px;
  }

  .cg-table-row {
    flex-direction: column;
  }

  .cg-period-info {
    margin-top: 10px;
  }

  .cg-toggle-button,
  .cg-download-button {
    width: 100%;
    max-width: none;
  }
}

/* 인쇄 스타일 */
@media print {
  .cg-wrapper {
    padding: 0;
    background: none;
  }

  .cg-container {
    max-width: 100%;
    box-shadow: none;
  }

  .cg-paper {
    box-shadow: none;
  }

  .cg-download-section {
    display: none;
  }
}

.cg-company {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 500;
  text-align: center;
}

.cg-company-name {
  color: #946A6A;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.cg-contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cg-company-name {
  color: #946A6A;
  font-size: 16px;
  font-weight: 500;
}

.cg-address-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333;
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

.cg-address-select:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.cg-address-select option {
  padding: 10px;
  font-size: 16px;
}

.cg-pdf-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.cg-email-section {
  display: flex;
  gap: 10px;
  width: 100%;
}

.cg-email-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
}

.cg-email-input:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.cg-email-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.cg-email-button:hover {
  background-color: #7A5656;
}

.cg-email-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cg-error-message {
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}

.cg-success-message {
  color: #27ae60;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}

@media screen and (max-width: 768px) {
  .cg-email-section {
    flex-direction: column;
  }

  .cg-email-button {
    width: 100%;
  }
}

/* 공사 안내문 컨트롤 스타일 - 새로운 독립적인 클래스 */
.cg-new-controls-container {
  width: 100%;
  max-width: 800px;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cg-new-controls-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cg-new-button {
  height: 46px;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cg-new-button-primary {
  background-color: #946A6A;
  color: white;
}

.cg-new-button-secondary {
  background-color: #f8f9fa;
  color: #666;
  border: 1px solid #e9ecef;
}

.cg-new-button-primary:hover {
  background-color: #7A5656;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cg-new-button-secondary:hover {
  background-color: #e9ecef;
  color: #333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cg-new-link {
  text-decoration: none;
  width: 50%;
}

.cg-new-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cg-new-input {
  height: 46px;
  padding: 0 15px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
  width: 50%;
  color: #333;
  background-color: #ffffff;
}

.cg-new-input:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.cg-new-input::placeholder {
  color: #999;
}

.cg-new-button:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
  transform: none !important;
  box-shadow: none !important;
}

.cg-new-error-message {
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}

.cg-new-success-message {
  color: #27ae60;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .cg-new-controls-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .cg-new-button,
  .cg-new-input,
  .cg-new-link {
    width: 100% !important;
  }
}

/* 이메일 전송 섹션 스타일 */
.constructionguide-email-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-top: 15px;
}

.constructionguide-email-input {
  flex: 2;
  height: 46px;
  padding: 0 15px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  box-sizing: border-box;
}

.constructionguide-email-input:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.constructionguide-email-button {
  flex: 1;
  height: 46px;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  border: none;
  background-color: #946A6A;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.constructionguide-email-button:hover {
  background-color: #7A5656;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.constructionguide-email-button:disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
  transform: none !important;
  box-shadow: none !important;
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .constructionguide-controls-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .constructionguide-controls-row > div {
    width: 100% !important;
    padding: 0 !important;
  }
  
  .constructionguide-button,
  .constructionguide-input,
  .constructionguide-link {
    width: 100% !important;
  }
  
  .constructionguide-email-section {
    flex-direction: row;
    gap: 10px;
  }
  
  .constructionguide-email-input {
    flex: 2;
  }
  
  .constructionguide-email-button {
    flex: 1;
  }
} 