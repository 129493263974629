.ac-filter {
  width: 180px;
  margin: 0;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac-select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #946A6A;
  border-radius: 8px;
  background-color: white;
  color: #946A6A;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.ac-select:hover,
.ac-select:focus {
  border-color: #7A5656;
  outline: none;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

@media (max-width: 768px) {
  .ac-filter {
    width: 100%;
    padding: 10px 0;
  }
  
  .ac-select {
    width: 100%;
  }
} 