/* InteriorMaterialSelection 스타일 */
.interior-material-selection-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.interior-material-selection-container h1 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* 주소 선택 스타일 */
.interior-address-selection {
  margin-bottom: 25px;
}

.interior-address-selection select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

/* 공정 목록 스타일 */
.interior-process-list {
  margin-top: 30px;
}

.interior-process-list h2 {
  color: #444;
  margin-bottom: 20px;
}

.interior-process-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.interior-process-item {
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}

.interior-process-item.disabled {
  background-color: #946A6A;
  cursor: not-allowed;
  opacity: 0.8;
  border-color: #7A5656;
  color: white;
}

.interior-process-item.disabled:hover {
  transform: none;
  background-color: #946A6A;
}

.interior-process-item:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

/* 모달 스타일 */
.interior-material-modal {
  display: none;
}

.interior-material-modal.open {
  display: block;
  pointer-events: all;
}

.interior-material-modal-content {
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  width: 60%;
  max-width: 600px;
  max-height: 70vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.interior-material-modal-content h2 {
  margin-bottom: 12px;
  color: #333;
  font-size: 1.2rem;
  text-align: center;
}

.interior-material-input-group {
  display: flex;
  gap: 6px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.interior-material-input-group input {
  flex: 1;
  min-width: 120px;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 13px;
}

.interior-material-input-group button {
  padding: 6px 16px;
  font-size: 13px;
  height: 28px;
  border-radius: 8px;
  background-color: #946A6A;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.interior-material-input-group button:hover {
  background-color: #7A5656;
}

.interior-material-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.interior-material-table th,
.interior-material-table td {
  padding: 6px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
}

.interior-material-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.interior-material-table input {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 13px;
  text-align: center;
}

.interior-material-table button.interior-material-delete-btn {
  padding: 4px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
  height: 24px;
  line-height: 1;
  width: 40px;
}

.interior-material-table button.interior-material-delete-btn:hover {
  background-color: #7A5656;
}

.interior-modal-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
}

.interior-modal-buttons .interior-material-close-btn {
  padding: 4px 12px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  height: 28px;
  line-height: 1.5;
  width: auto;
  transition: background-color 0.3s ease;
}

.interior-modal-buttons .interior-material-close-btn:hover {
  background-color: #7A5656;
}

.interior-material-management-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
  gap: 10px;
}

.interior-material-management-button {
  padding: 10px 25px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.interior-toggle-active-button {
  padding: 10px 25px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.interior-toggle-active-button.active {
  background-color: #946A6A;
  opacity: 0.8;
}

.interior-toggle-active-button:hover {
  background-color: #7A5656;
}

.interior-material-management-button:hover {
  background-color: #7A5656;
}

.interior-material-management-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 모바일 대응 스타일 */
@media (max-width: 768px) {
  .interior-material-modal-content {
    width: calc(100% - 40px);
    padding: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .interior-material-input-group {
    flex-direction: column;
    gap: 8px;
  }

  .interior-material-input-group input {
    width: 100%;
  }

  .interior-material-input-group button {
    width: 100%;
  }

  .interior-material-table {
    font-size: 14px;
  }

  .interior-material-table th,
  .interior-material-table td {
    padding: 8px;
  }

  .interior-process-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .interior-process-item {
    padding: 12px;
    font-size: 14px;
  }
}

.interior-material-table tr:hover {
  background-color: #f8f8f8;
}

.interior-material-table td {
  white-space: pre-wrap;
  word-break: break-word;
}

/* 자재 관리 모달 테이블 스타일 */
.interior-material-modal-content .interior-material-table td {
  padding: 6px;
  font-size: 12px;
}

.interior-material-save-btn {
  padding: 4px 12px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  height: 28px;
  line-height: 1.5;
  width: auto;
  transition: background-color 0.3s ease;
  margin-right: 8px;
  background-color: #4CAF50;
}

.interior-material-save-btn:hover {
  background-color: #45a049;
}

.interior-material-buttons {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.interior-material-toggle-btn {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.interior-material-toggle-btn:hover {
  background-color: #7a5757;
}

.existing-materials-container {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.material-type-group {
  margin-bottom: 15px;
}

.material-type-group h3 {
  font-size: 14px;
  color: #666;
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.existing-material-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
}

.material-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.material-location {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.material-name {
  font-size: 13px;
  color: #666;
}

.material-address {
  font-size: 12px;
  color: #999;
}

.select-material-btn {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s;
  height: 28px;
}

.select-material-btn:hover {
  background-color: #7a5757;
} 