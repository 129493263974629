/* Pretendard 폰트 설정 */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

/* 공통 스타일 */
.estimate-app {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  width: 360px;
  min-height: 100vh;
  background-color: #F7F7F6;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  caret-color: transparent;
  position: relative;
}

.title {
  color: #946A6A;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-top: 100px;
  font-size: 24px;
}

/* LandingPage 스타일 */
.landing-page {
  position: relative;
  width: 100%;
  min-height: 95vh;
  background-color: #F7F7F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 60px;
  padding: 20px;
  margin-top: 0;
}

.brand {
  color: #946A6A;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: 10px 20px;
  margin: 0;
}

.slogan {
  color: #AA9696;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.slogan .highlight-text {
  display: block;
  margin: 10px 0;
}

.start-button {
  background-color: #946A6A;
  color: #ffffff;
  border: 1px solid #E1D8D8;
  width: 280px;
  height: 50px;
  font-size: 18px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0;
}

.start-button:hover {
  background-color: #7A5656;
}

/* AreaSelection 스타일 */
.area-selection, .process-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.area-button, .process-button {
  width: 280px;
  height: 50px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.3s ease;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-button {
  background-color: #946A6A;
  color: #ffffff;
  border: 1px solid #E1D8D8;
}

.process-button {
  background-color: #FCF5F5;
  color: #AFAFAF;
  border: 1px solid #E1D8D8;
}

.process-button.selected {
  background-color: #946A6A;
  color: #ffffff;
  border: none;
}

.area-info, .process-info {
  color: #946A6A;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 1.5;
  width: 100%;
}

.complete-button {
  background-color: #E65D20;
  color: white;
  border: none;
  width: 280px;
  height: 50px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.3s ease;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.complete-button.active {
  background-color: #D14E12;
}

.warning-message {
  color: #C57E66;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

/* UserInfoInput 스타일 */
.user-info-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  justify-content: flex-start;
  padding-top: 20px;
  margin-top: 20px;
}

.user-info-input form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.user-info-input input {
  width: 280px;
  height: 50px;
  font-size: 14px;
  padding: 0 15px;
  border: 1px solid #E1D8D8;
  border-radius: 16px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  caret-color: #946A6A;
}

.user-info-input input::placeholder {
  color: #946A6A;
}

.user-info-input .submit-button {
  width: 280px;
  height: 50px;
  font-size: 14px;
  background-color: #946A6A;
  color: #ffffff;
  border: 1px solid #E1D8D8;
  cursor: pointer;
  border-radius: 16px;
  transition: all 0.3s ease;
  text-align: center;
  box-sizing: border-box;
}

.user-info-input .submit-button:hover {
  background-color: #7A5656;
}

/* 기존 submit-button 스타일은 유지 (다른 컴포넌트에서 사용될 수 있음) */
.submit-button {
  background-color: #946A6A;
  color: #ffffff;
  border: 1px solid #E1D8D8;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #7A5656;
}

.user-info-description {
  color: #946A6A;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.5;
  width: 100%;
}

/* EstimateResult 스타일 */
.estimate-result {
  padding: 24px;
  max-width: 360px;
  margin: 0 auto;
  background-color: #F7F7F6;
}

.estimate-header {
  margin: 24px 0;
  text-align: center;
  padding: 16px;
}

.sub-title {
  color: #946A6A;
  font-size: 24px;
  margin-bottom: 8px;
}

.estimate-date {
  color: #666;
  font-size: 14px;
}

.total-price {
  background: #946A6A;
  color: white;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}

.total-label {
  font-size: 16px;
  font-weight: 500;
}

.total-value {
  font-size: 18px;
  font-weight: 600;
}

.estimate-footer {
  margin-top: 24px;
  text-align: center;
  padding: 16px;
}

.note {
  color: #666;
  font-size: 12px;
  line-height: 1.5;
}

.go-home-button {
  background: none;
  border: 1px solid #946A6A;
  color: #946A6A;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.go-home-button:hover {
  background: #946A6A;
  color: white;
}

/* 버튼 컨테이너 스타일 */
.button-container {
  padding: 16px;
  margin-bottom: 16px;
}

/* 제목 컨이너 스타일 */
.title-container {
  text-align: center;
  padding: 16px;
  margin-bottom: 16px;
}

.admin-link {
  background: #946A6A;
  color: white;
  text-decoration: none;
  font-size: 11px;
  padding: 4px 12px;
  border-radius: 16px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  display: block;
  text-align: center;
  margin: 6px auto 0;
  width: fit-content;
  min-width: min-content;
}

.admin-link:hover {
  opacity: 1;
  color: white;
}

/* 컨테이너 추가 */
.content-container {
  width: 320px;
  background-color: transparent;
  padding: 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

/* 스케일 애니메이션을 위한 스타일 수정 */
.highlight-text {
  display: inline-block;
  animation: scaleText 1.5s ease-in-out infinite;
  color: #946A6A;
  font-weight: 700;
  font-size: 14px;
}

@keyframes scaleText {
  0%, 20%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  70% {
    transform: scale(1.05);
  }
}

/* 기존 CSS에 추가 */
.scroll-indicator {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  animation: fadeInOut 3s ease-in-out;
  z-index: 1000;
}

.scroll-indicator .arrow {
  color: #946A6A;
  font-size: 24px;
  margin-bottom: 8px;
  animation: bounce 1s infinite;
}

.scroll-indicator p {
  color: #946A6A;
  font-size: 14px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(148, 106, 106, 0.2);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

/* 선택 완료 버튼 애니메이션 추가 */
.complete-button.active {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(230, 93, 32, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(230, 93, 32, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(230, 93, 32, 0);
  }
}
