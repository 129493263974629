.video-maker {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.video-maker h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
} 