.sns-image-edit {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.sns-image-edit * {
  box-sizing: border-box;
}

.sns-image-edit h1 {
  color: #946A6A;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  width: 100%;
}

.sns-image-edit .text-input-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.sns-image-edit .text-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #946A6A;
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: center;
}

.sns-image-edit .random-apply-button {
  width: 100%;
  padding: 8px 12px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.sns-image-edit .dropzone {
  border: 2px dashed #946A6A;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 14px;
}

.sns-image-edit .image-preview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: center;
  padding: 10px;
  background-color: #FCF5F5;
  border-radius: 8px;
  width: 100%;
}

.sns-image-edit .image-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 이미지 크기를 조정하여 더 크게 보이도록 합니다 */
.sns-image-edit .image-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sns-image-edit .image-item input[type="checkbox"] {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 10;
}

.sns-image-edit .image-text {
  position: absolute;
  background-color: white;
  color: black;
  padding: 3px;
  border-radius: 2px;
  font-size: 8px;
  max-width: 80%;
  word-wrap: break-word;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.lightbox .image-text {
  position: absolute;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  max-width: 80%;
  word-wrap: break-word;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.image-controls {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 340px;
}

.select-all-button,
.remove-checked-button {
  background-color: #C57E66;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  width: 48%;
  text-align: center;
  margin: 0 5px;
}

.select-all-button:hover,
.remove-checked-button:hover {
  background-color: #A35E46;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}
