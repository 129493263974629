.area-management {
  margin-top: 20px;
}

.area-list {
  margin-top: 20px;
}

.area-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 8px;
}

.area-item span {
  font-size: 14px;
  color: #333;
}

.area-item .control-button {
  margin-left: auto;
  padding: 4px 8px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.area-item .control-button:hover {
  background: #7A5656;
}

.area-management .input-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  max-width: 400px;
}

.area-add-button {
  width: 100%;
  padding: 12px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.area-add-button:hover {
  background: #7A5656;
}

.area-add-button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

.area-management .input-group .admin-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
}

.area-management .admin-button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
} 