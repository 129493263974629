/* 음성 버튼 스타일 */
.voice-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.voice-button:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.voice-button.speaking {
  animation: pulse 1.5s infinite;
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.voice-reset-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.voice-reset-button:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

/* 음성 설정 옵션 컨테이너 */
.ai-settings-voice-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-top: 10px;
}

.ai-settings-voice-options.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* 슬라이더 옵션 */
.ai-settings-slider-option {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ai-settings-slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-settings-slider-value {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}

.ai-settings-slider {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: #ddd;
  outline: none;
  border-radius: 2px;
}

.ai-settings-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #946A6A;
  cursor: pointer;
}

.ai-settings-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #946A6A;
  cursor: pointer;
  border: none;
}

/* 음성 테스트 버튼 */
.ai-settings-test-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 5px;
  transition: opacity 0.2s;
}

.ai-settings-test-button:hover {
  opacity: 0.9;
}

.ai-settings-test-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} 