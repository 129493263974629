.material-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.material-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.material-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.material-modal-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.material-modal-content {
  padding: 15px;
  overflow-y: auto;
  flex: 1;
}

.address-group {
  margin-bottom: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
}

.address-title {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}

.material-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.material-table th,
.material-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 12px;
}

.material-table th {
  background-color: #946A6A;
  color: white;
  font-weight: normal;
}

.material-table tr:hover {
  background-color: #f5f5f5;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-materials {
  text-align: center;
  padding: 30px;
  background: #f8f8f8;
  border-radius: 8px;
  color: #666;
  font-size: 14px;
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .material-modal {
    width: 95%;
    margin: 10px;
  }

  .material-table {
    font-size: 11px;
  }

  .material-table th,
  .material-table td {
    padding: 6px;
  }

  .address-group {
    padding: 10px;
  }

  .no-materials {
    padding: 20px;
    font-size: 12px;
  }
} 