@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@font-face {
  font-family: 'Abster';
  src: url('./fonts/abster-500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

.auto-proposal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #FAFAF8;
  width: 100%;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

.auto-proposal-page {
  width: 297mm;
  height: 210mm;
  background-color: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.03);
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.auto-proposal-sidebar {
  width: 100px;
  background: linear-gradient(180deg, #6B8CAE 0%, #8BA5C4 100%);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auto-proposal-side-text {
  margin-top: 140px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 20px;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
  letter-spacing: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  text-transform: uppercase;
}

.auto-proposal-main-content {
  flex: 1;
  display: flex;
  padding: 60px;
  gap: 60px;
}

.auto-proposal-text-content {
  flex: 1;
  padding-right: 60px;
}

.auto-proposal-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 50px;
  line-height: 1.4;
  color: #424B5A;
}

.auto-proposal-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.auto-proposal-list-item {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  color: #666D78;
  line-height: 1.8;
  font-size: 18px;
}

.auto-proposal-list-number {
  font-size: 20px;
  font-weight: 600;
  margin-right: 15px;
  color: #89A6A2;
}

.auto-proposal-image-container {
  width: 50%;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.auto-proposal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 첫 페이지 스타일 */
.auto-proposal-first-page .auto-proposal-company-info {
  width: 450px;
  background: linear-gradient(135deg, #6B8CAE 0%, #8BA5C4 100%);
  padding: 60px;
  color: white;
  margin-left: auto;
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}

.auto-proposal-logo-wrapper {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.auto-proposal-logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: contain;
  opacity: 0.45;
}

.auto-proposal-company-name-en {
  font-family: 'Abster', sans-serif;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 15px;
  letter-spacing: 2px;
  word-spacing: 8px;
  display: block;
}

.auto-proposal-company-name {
  font-size: 42px;
  font-weight: 200;
  margin-bottom: 30px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: white;
  margin-top: 0;
  display: block;
}

.auto-proposal-contact-section {
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 30px;
}

.auto-proposal-contact-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.auto-proposal-contact-info {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 2;
}

@media print {
  .auto-proposal-container {
    padding: 0;
    background-color: white;
  }

  .auto-proposal-page {
    box-shadow: none;
    margin: 0;
  }
} 