.view-type-selection {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F7F7F6;
  padding: 20px;
  position: relative;
}

h1 {
  color: #946A6A;
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
}

.view-type-buttons {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.view-type-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 120px;
  border: 2px solid #946A6A;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-type-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(148, 106, 106, 0.2);
}

.view-type-button .icon {
  font-size: 40px;
  margin-bottom: 8px;
}

.view-type-button span:not(.icon) {
  color: #946A6A;
  font-size: 14px;
} 