.lineup-selection {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
}

.lineup-title-card {
  background: white;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #E1D8D8;
  text-align: center;
  margin-bottom: 16px;
}

.lineup-title-card h2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px 0;
  color: #333;
  white-space: nowrap;
}

.lineup-title-card p {
  font-size: 13px;
  color: #666;
  margin: 0;
  line-height: 1.5;
}

.lineup-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 360px;
  margin: 0 auto;
}

.lineup-card {
  background: white;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #E1D8D8;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.lineup-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.lineup-card.selected {
  border-color: var(--card-color);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.97) 100%
  );
}

.lineup-card.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: var(--card-color);
}

.lineup-card.selected .lineup-content {
  position: relative;
  z-index: 1;
}

.lineup-card.selected .lineup-icon {
  animation: pulse 2s infinite;
}

.lineup-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.lineup-icon {
  font-size: 20px;
  color: var(--card-color);
  transition: transform 0.3s ease;
}

.lineup-card:hover .lineup-icon {
  transform: scale(1.1);
}

.lineup-name {
  font-size: 13px;
  font-weight: 600;
  color: var(--card-color);
  letter-spacing: 0.5px;
}

/* 애니메이션 효과 */
@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  20%, 100% {
    transform: translateX(100%) rotate(45deg);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

/* 선택된 카드에 반짝이는 효과 추가 */
.lineup-card.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 2s infinite;
}

.lineup-info {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lineup-title {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 4px 0;
  color: #333;
  text-align: center;
}

.lineup-description {
  font-size: 13px;
  color: #666;
  margin: 0;
  line-height: 1.4;
  text-align: center;
}

.lineup-sub-description {
  font-size: 12px;
  color: #888;
  margin: 2px 0 8px 0;
  text-align: center;
}

.lineup-price {
  font-size: 14px;
  font-weight: 600;
  color: var(--card-color);
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: inline-block;
  text-align: center;
} 