.description-list {
  margin-top: 20px;
}

.description-item {
  margin-bottom: 16px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.process-name {
  font-size: 14px;
  font-weight: 500;
  color: #946A6A;
  margin-bottom: 8px;
}

.description-input-wrapper {
  position: relative;
}

.description-input {
  width: 100%;
  min-height: 80px;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  line-height: 1.5;
}

.description-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.clear-button {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
}

.clear-button:hover {
  background: #e0e0e0;
  color: #333;
}

.save-button {
  width: 100%;
  padding: 12px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}

.save-button:hover {
  background: #7A5656;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
} 