/* PaymentHistory.css */
/* 공통 스타일 */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 기본 컨테이너 */
.PaymentHistory-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #F8F9FA;
  padding: 16px;
}

.PaymentHistory-wrapper {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding-bottom: 120px;
}

/* 결제 항목 카드 */
.PaymentHistory-item-container {
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: transform 0.2s ease;
}

.PaymentHistory-item-container:active {
  transform: scale(0.98);
}

/* 입력 필드 그룹 */
.PaymentHistory-input-group {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  width: 100%;
}

.PaymentHistory-date-input,
.PaymentHistory-amount-input {
  flex: 1;
  height: 40px;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 14px;
  background: #F8F9FA;
  text-align: center;
  width: 100%;
  min-width: 0; /* flex item 최소 너비 설정 */
}

.PaymentHistory-date-input {
  cursor: text;
}

.PaymentHistory-date-input::placeholder {
  color: #ADB5BD;
  font-size: 14px;
}

.PaymentHistory-date-input:disabled,
.PaymentHistory-amount-input:disabled {
  background: #F8F9FA;
  cursor: not-allowed;
  opacity: 0.7;
}

/* 체크박스 그룹 */
.PaymentHistory-checkbox-group {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 12px;
}

.PaymentHistory-checkbox-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #495057;
  font-size: 14px;
}

/* 통장 선택 라디오 버튼 그룹 */
.PaymentHistory-bank-radio-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.PaymentHistory-bank-checkbox-group {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 4px;
  flex-wrap: wrap;
}

.PaymentHistory-bank-checkbox-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #495057;
  font-size: 14px;
  padding: 4px 8px;
  background: #F8F9FA;
  border-radius: 6px;
  transition: all 0.2s ease;
  min-width: 100px;
  justify-content: center;
}

.PaymentHistory-bank-checkbox-label input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: #946A6A;
}

.PaymentHistory-bank-checkbox-label:has(input:checked) {
  background: #946A6A20;
}

/* 버튼 그룹 */
.PaymentHistory-button-group {
  display: flex;
  gap: 8px;
}

.PaymentHistory-action-button {
  flex: 1;
  height: 36px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

/* 총 금액 텍스트 */
.PaymentHistory-total-amount-text {
  font-size: 15px;
  font-weight: 500;
  color: #343A40;
  text-align: center;
  margin-bottom: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.PaymentHistory-total-amount-text p {
  margin-bottom: 8px;
  line-height: 1.5;
}

.PaymentHistory-total-amount-text p:last-child {
  margin-bottom: 0;
}

/* 추가 버튼 스타일 */
.PaymentHistory-add-payment-button {
  width: 100%;
  height: 48px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 1px 3px rgba(148,106,106,0.2);
  transition: all 0.2s ease;
}

.PaymentHistory-add-payment-button:active {
  transform: scale(0.98);
  background: #7A5656;
}

.PaymentHistory-add-payment-button span {
  font-size: 20px;
  line-height: 1;
}

/* 모달 */
.PaymentHistory-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
}

.PaymentHistory-modal-content {
  position: relative;
  width: 90%;
  max-width: 320px;
  background: white;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  animation: PaymentHistory-modalFadeIn 0.2s ease-out;
  margin: auto;
  transform: translateY(0);
}

@keyframes PaymentHistory-modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.PaymentHistory-modal-content p {
  font-size: 16px;
  color: #343A40;
  margin-bottom: 8px;
  font-weight: 500;
}

.PaymentHistory-modal-buttons {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

.PaymentHistory-modal-buttons button {
  flex: 1;
  height: 44px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.PaymentHistory-modal-buttons button:first-child {
  background: #946A6A;
  color: white;
}

.PaymentHistory-modal-buttons button:last-child {
  background: #F8F9FA;
  color: #495057;
}

.PaymentHistory-modal-buttons button:active {
  transform: scale(0.98);
}

/* 알림 메시지 */
.PaymentHistory-confirm-message {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 14px;
  z-index: 1000;
}

/* DatePicker 관련 스타일은 삭제하거나 PaymentHistory 접두사 추가 */

.PaymentHistory-no-address-selected {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-top: 16px;
}

.PaymentHistory-no-address-selected p {
  color: #495057;
  font-size: 15px;
  font-weight: 500;
}

.PaymentHistory-payment-item-row {
  width: 100%;
  margin-bottom: 12px;
}

.PaymentHistory-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentHistory-w-full {
  width: 100%;
}

.PaymentHistory-gap-2 {
  gap: 8px;
}

/* 모바일 환경에서 터치 영역 확장 */
@media (max-width: 768px) {
  .PaymentHistory-date-input,
  .PaymentHistory-amount-input {
    height: 48px;
    font-size: 16px;
    padding: 0 8px;
  }
  
  .PaymentHistory-date-input::placeholder {
    font-size: 16px;
  }

  .PaymentHistory-input-group {
    gap: 6px;
  }

  .PaymentHistory-date-input {
    flex: 3; /* 날짜 입력 필드가 더 넓게 */
  }

  .PaymentHistory-amount-input {
    flex: 2; /* 금액 입력 필드는 상대적으로 좁게 */
  }
}
