.estimate-inquiry-list {
  width: 360px;
  margin: 0 auto;
  padding: 16px 0;
}

.estimate-inquiry-list h1 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 16px 0;
  color: #333;
}

.button-container {
  width: 326px;
  margin: 0 auto 16px;
  display: flex;
  gap: 16px;
  position: relative;
  z-index: 2;
}

.button-container button {
  width: 155px;
  height: 40px;
  border-radius: 6px;
  border: none;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.select-button {
  background-color: #f0f0f0 !important;
  color: #333 !important;
}

.delete-button {
  background-color: #ff4444 !important;
  color: #ffffff !important;
}

.action-bar {
  width: 326px;
  height: 48px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin: 0 auto 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.select-all-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.select-all-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.inquiry-list {
  width: 326px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inquiry-item {
  background: #fff;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
}

.inquiry-item.selected {
  background: #e3f2fd;
}

.select-checkbox {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 18px;
  height: 18px;
}

.inquiry-header {
  margin-bottom: 12px;
}

.inquiry-header .name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  display: block;
  margin-bottom: 4px;
}

.inquiry-header .date {
  font-size: 12px;
  color: #888;
}

.info-row {
  display: flex;
  margin-bottom: 8px;
}

.info-row .label {
  width: 52px;
  color: #666;
  font-size: 13px;
}

.info-row .value {
  font-size: 13px;
  color: #333;
}

.question {
  background: #f8f9fa;
  padding: 12px;
  border-radius: 6px;
  margin-top: 12px;
}

.question .label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.question .value {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
}

.status-badge {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 11px;
  margin-top: 12px;
}

.status-badge[data-status="신규"] {
  background: #e3f2fd;
  color: #1976d2;
}

.status-badge[data-status="확인"] {
  background: #f3e5f5;
  color: #7b1fa2;
}

.status-badge[data-status="완료"] {
  background: #e8f5e9;
  color: #388e3c;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 14px;
}

.select-button-container,
.delete-button-container,
.button-wrapper {
  display: none;
}

.view-estimate-button {
  padding: 8px 16px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 12px;
}

.view-estimate-button:hover {
  background: #7A5656;
}
 
 