.interim-save-button {
  width: 100%;
  height: 46px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.interim-save-button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

.interim-save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
} 