.template-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.template-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.template-modal-title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.template-options {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.template-option {
  flex: 1;
  cursor: pointer;
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

.template-option:hover {
  border-color: #946A6A;
  transform: translateY(-2px);
}

.template-option.active {
  border-color: #946A6A;
  background-color: rgba(148, 106, 106, 0.1);
}

.template-option.active::after {
  content: '현재 템플릿';
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #946A6A;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.template-preview {
  width: 100%;
  height: 200px;
  border: 1px solid #eee;
  margin-bottom: 1rem;
  display: flex;
  gap: 4px;
}

.template1-preview {
  .preview-main {
    flex: 8;
    background-color: #f8f9fa;
  }
  
  .preview-side {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .preview-materials {
      flex: 1;
      background-color: #e9ecef;
    }
    
    .preview-memo {
      flex: 1;
      background-color: #e9ecef;
    }
  }
}

.template2-preview {
  .preview-main {
    flex: 8;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .preview-blueprint {
      flex: 4;
      background-color: #f8f9fa;
    }
    
    .preview-memo {
      flex: 1;
      background-color: #f8f9fa;
    }
  }
  
  .preview-side {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .preview-materials {
      flex: 7;
      background-color: #e9ecef;
    }
    
    .preview-floor-plan {
      flex: 3;
      background-color: #e9ecef;
    }
  }
}

.template4-preview {
  .preview-full-text {
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: #666;
    font-family: 'Noto Sans KR', sans-serif;
  }
}

.template-label {
  text-align: center;
  color: #495057;
  font-size: 0.9rem;
  margin-top: 0.5rem;
} 