.admin-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  color: #666;
  text-decoration: none;
  font-size: 16px;
}

.admin-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin: 40px auto;
  max-width: 600px;
  padding: 0 20px;
}

.tab-button {
  flex: 1 1 180px;
  padding: 12px 24px;
  border: 1px solid #eee;
  background: white;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.tab-button.active {
  background: #B69999;
  border-color: #B69999;
  color: white;
}

.tab-button:hover:not(.active) {
  background: #f8f8f8;
  border-color: #ddd;
}

.content-card {
  background: white;
  padding: 24px;
  margin-bottom: 20px;
}

.card-title {
  margin-bottom: 24px;
  color: #333;
  font-size: 1.4rem;
  font-weight: 600;
}

/* 공정 관리 스타일 */
.admin-page .process-management .input-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.admin-page .admin-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.admin-page .admin-input::placeholder {
  text-align: center;
  color: #999;
}

.admin-page .admin-process-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-page .admin-process-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  margin: 0;
  background: transparent;
}

.admin-page .admin-process-item span {
  font-size: 15px;
  color: #333;
  flex: 1;
  padding: 8px;
  cursor: pointer;
}

.admin-page .process-controls {
  display: flex;
  gap: 4px;
  margin-left: 12px;
}

.admin-page .control-button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #f0f0f0;
  color: #666;
  font-size: 12px;
  min-width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.admin-page .control-button.delete {
  background: #F5EFEF;
  color: #946A6A;
  min-width: 40px;
  padding: 4px 10px;
  border: 1px solid #B69999;
}

.admin-page .control-button.delete:hover {
  background: #946A6A;
  color: white;
  border-color: #946A6A;
}

.admin-page .control-button:hover:not(.delete) {
  background: #e8e8e8;
}

/* 가격 관리 스타일 수정 */
.select-wrapper {
  margin-bottom: 24px;
  max-width: 600px;
}

.admin-select {
  width: 100%;
  padding: 16px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 15px;
  background-color: white;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.admin-select:hover {
  border-color: #946A6A;
  background-color: #f8f8f8;
}

.admin-select:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.price-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.price-input-group:hover {
  background: #f0f0f0;
}

.price-input-group label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  padding-left: 4px;
}

.price-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  background: white;
}

.price-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.submit-button {
  padding: 12px 24px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
  align-self: flex-end;
  min-width: 120px;
}

.submit-button:hover {
  background: #7A5656;
}

/* 견적서 관리 스타일 */
.delete-selected-button {
  padding: 8px 16px;
  background: none;
  border: 1px solid #dc3545;
  color: #dc3545;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-selected-button:hover:not(:disabled) {
  background: #dc3545;
  color: white;
}

.delete-selected-button:disabled {
  border-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
}

/* 견적서 리스트 스타일 */
.estimates-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
}

.estimate-item {
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.estimate-item.selected {
  background-color: #F5EFEF;
  border-color: #B69999;
}

.estimate-item .name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: center;
}

.estimate-item .email {
  font-size: 13px;
  color: #666;
  text-align: center;
}

.estimate-item .date {
  font-size: 12px;
  color: #888;
  text-align: center;
  margin-top: 4px;
}

/* 선택 모드 관련 스타일 */
.selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
}

.selection-mode-button {
  padding: 8px 16px;
  border: 1px solid #eee;
  background: white;
  color: #666;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
}

.selection-mode-button.active {
  border-color: #B69999;
  color: #B69999;
}

.delete-button {
  padding: 8px 16px;
  border: 1px solid #dc3545;
  background: white;
  color: #dc3545;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background: #dc3545;
  color: white;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .admin-tabs {
    padding: 0 12px;
  }
  
  .tab-button {
    flex: 1 1 140px;
    padding: 10px 16px;
    font-size: 14px;
  }
  
  .price-input-group {
    grid-template-columns: 1fr;
  }
  
  .table-header,
  .table-row {
    grid-template-columns: 50px 2fr 1fr 1fr;
    font-size: 13px;
    padding: 10px 12px;
  }
  
  .content-card {
    padding: 16px;
  }
  
  .price-input-group label {
    white-space: normal;
    margin-bottom: 8px;
  }
}

.mapping-management {
  margin-top: 20px;
}

.mapping-list {
  margin-top: 20px;
}

.mapping-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 8px;
}

.mapping-item span {
  font-size: 14px;
  color: #333;
}

.mapping-item span:nth-child(2) {
  color: #946A6A;
  font-weight: bold;
}

.mapping-item .delete-button {
  margin-left: auto;
  padding: 4px 8px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mapping-item .delete-button:hover {
  background: #c82333;
}

.mapping-management .input-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  max-width: 400px;
}

.mapping-management .admin-select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.mapping-management .admin-button {
  width: 100%;
  padding: 12px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mapping-management .admin-button:hover {
  background: #7A5656;
}

.mapping-management .admin-button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

.admin-process-item input {
  flex: 1;
  padding: 8px;
  border: 1px solid #946A6A;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.admin-process-item input:focus {
  outline: none;
  border-color: #7A5656;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

/* 선택 모드 컨트롤 스타일 */
.selection-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.selection-controls .control-button {
  width: 100%;
  padding: 12px;
  border: 1px solid #eee;
  background: white;
  color: #666;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.selection-controls .control-button.active {
  border-color: #B69999;
  background: #F5EFEF;
  color: #946A6A;
}

.selection-controls .control-button.select-all {
  border-color: #B69999;
  color: #946A6A;
}

.selection-controls .control-button.select-all:hover {
  background: #F5EFEF;
}

.selection-controls .control-button.delete {
  border-color: #946A6A;
  color: #946A6A;
}

.selection-controls .control-button.delete:not(:disabled) {
  background: #946A6A;
  color: white;
}

.selection-controls .control-button.delete:disabled {
  border-color: #eee;
  color: #999;
  background: #f8f8f8;
  cursor: not-allowed;
}

.selection-controls .control-button:hover:not(:disabled) {
  background: #F5EFEF;
}

.selection-controls .control-button.delete:hover:not(:disabled) {
  background: #7A5656;
  color: white;
}

.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.message-box {
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  15% { opacity: 1; }
  85% { opacity: 1; }
  100% { opacity: 0; }
}

.message-box p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.export-button {
  margin-top: 12px;
  padding: 8px 16px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}

.export-button:hover {
  background: #7A5656;
}

.process-description-input {
  display: none;
}

.process-add-button {
  width: 100%;
  padding: 12px;
  background: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.process-add-button:hover {
  background: #7A5656;
}

.process-add-button:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

.process-management .input-group .admin-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
} 