.address-select-container {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.address-select-label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
}

.address-select-input {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.address-select-input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

/* 모바일 대응 */
@media screen and (max-width: 21cm) {
  .address-select-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .address-select-label {
    font-size: 14px;
  }

  .address-select-input {
    height: 40px;
    font-size: 14px;
    padding: 0 12px;
  }
} 