.template-table {
  width: 100%;
  overflow-x: auto;
}

.template-table table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.template-table th,
.template-table td {
  padding: 12px 16px;
  text-align: center;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.template-table th {
  background: #f8f9fa;
  font-weight: 500;
  color: #666;
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: center;
}

.template-table tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

.template-table tbody tr:hover {
  background: #f8f9fa;
}

.template-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.action-cell {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.template-delete-button {
  background: rgba(148, 106, 106, 0.1);
  color: #946A6A;
  border: 1px solid rgba(148, 106, 106, 0.2);
  padding: 4px 8px;
  height: 24px;
  line-height: 1;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.template-delete-button:hover {
  background: rgba(148, 106, 106, 0.2);
  border-color: rgba(148, 106, 106, 0.3);
}

.template-clone-button {
  background: rgba(106, 148, 129, 0.1);
  color: #6A9481;
  border: 1px solid rgba(106, 148, 129, 0.2);
  padding: 4px 8px;
  height: 24px;
  line-height: 1;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  margin-right: 8px;
}

.template-clone-button:hover {
  background: rgba(106, 148, 129, 0.2);
  border-color: rgba(106, 148, 129, 0.3);
} 