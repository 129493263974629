.ai-prompt-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  font-size: 0.875rem;
  color: var(--color-primary);
  font-weight: 500;
}

.input-group input,
.input-group textarea {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  background-color: var(--bg-white);
  width: 100%;
  transition: all 0.2s ease;
}

.input-group textarea {
  min-height: 5rem;
  resize: vertical;
}

.input-group input:focus,
.input-group textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

:root {
  --color-primary: #946A6A;
  --color-primary-dark: #7A5656;
  --border-color: #E1D8D8;
  --bg-white: #ffffff;
  --bg-light: #f8f8f8;
  --text-dark: #333333;
  --text-light: #666666;
  --text-white: #ffffff;
}

.size-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.size-input {
  padding-right: 35px !important;
  width: 100%;
}

.size-unit {
  position: absolute;
  right: 12px;
  color: #666;
  font-size: 14px;
  pointer-events: none;
  user-select: none;
} 