.material-form__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.material-form {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.material-form__title {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 24px 0;
}

.material-form__field {
  margin-bottom: 20px;
}

.material-form__label {
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.material-form__input {
  width: 100%;
  padding: 10px 12px;
  border: 2px solid #E0E0E0;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.material-form__input:focus {
  outline: none;
  border-color: #946A6A;
}

.material-form__input[type="number"] {
  -moz-appearance: textfield;
}

.material-form__input[type="number"]::-webkit-outer-spin-button,
.material-form__input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.material-form__actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.material-form__cancel,
.material-form__submit {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.material-form__cancel {
  background-color: #F5F5F5;
  color: #666;
}

.material-form__cancel:hover {
  background-color: #E0E0E0;
}

.material-form__submit {
  background-color: #946A6A;
  color: white;
}

.material-form__submit:hover {
  background-color: #7D5A5A;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
  .material-form {
    margin: 20px;
    padding: 20px;
  }

  .material-form__title {
    font-size: 18px;
  }
} 