.shared-estimate-page-container {
  max-width: 1400px;
  margin: 40px auto;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shared-estimate-page-header {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
}

.shared-estimate-page-header h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.shared-estimate-page-header .address {
  font-size: 18px;
  color: #666;
}

.shared-estimate-page-header .expiry-date {
  color: #946A6A;
  font-size: 14px;
  margin: 0;
  margin-bottom: 20px;
}

.shared-estimate-page-password-form {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #E1D8D8;
}

.shared-estimate-page-password-input {
  padding: 12px;
  border: 2px solid #E1D8D8;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 4px;
  color: #946A6A;
  background-color: #F7F7F6;
  transition: all 0.2s ease;
}

.shared-estimate-page-password-input:focus {
  border-color: #946A6A;
  outline: none;
  background-color: white;
}

.shared-estimate-page-password-button {
  padding: 12px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.shared-estimate-page-password-button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

.shared-estimate-page-error {
  color: #dc3545;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  padding: 8px;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 4px;
}

.shared-estimate-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 18px;
  color: #946A6A;
}

.shared-estimate-page-table {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 30px;
}

.shared-estimate-page-table-header {
  display: flex;
  background-color: #F7F7F6;
  padding: 15px 0;
  border-radius: 8px;
  margin-bottom: 10px;
}

.shared-estimate-page-header-col {
  text-align: center;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.shared-estimate-page-header-col.item { width: 40%; }
.shared-estimate-page-header-col.quantity { width: 15%; }
.shared-estimate-page-header-col.unit { width: 15%; }
.shared-estimate-page-header-col.unit-price { width: 15%; }
.shared-estimate-page-header-col.amount { width: 15%; }

.shared-estimate-page-table-content {
  display: flex;
  width: 100%;
}

.shared-estimate-page-table-row {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #E1D8D8;
  flex-direction: column;
}

.shared-estimate-page-table-row:last-child {
  border-bottom: none;
}

.shared-estimate-page-col {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 20%;
}

.shared-estimate-page-col.item { 
  width: 40%; 
  justify-content: flex-start;
  text-align: left;
}

.shared-estimate-page-col.quantity { 
  width: 15%; 
  justify-content: center;
  text-align: center;
}

.shared-estimate-page-col.unit { 
  width: 15%; 
  justify-content: center;
  text-align: center;
}

.shared-estimate-page-col.unit-price { 
  width: 15%; 
  justify-content: center;
  text-align: center;
}

.shared-estimate-page-col.amount { 
  width: 15%; 
  justify-content: center;
  text-align: center;
}

.shared-estimate-page-note {
  width: 100%;
  padding: 8px 12px;
  margin-top: 12px;
  background-color: #F7F7F6;
  border-radius: 4px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.shared-estimate-page-note-label {
  font-size: 12px;
  color: #946A6A;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.shared-estimate-page-note-text {
  width: 100%;
  white-space: normal;
  word-break: break-all;
  line-height: 1.4;
  text-align: left;
  justify-content: flex-start;
  padding: 2px 4px;
  font-size: 12px;
  color: #333;
  display: flex;
  align-items: center;
}

.shared-estimate-page-summary {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #E1D8D8;
}

.shared-estimate-page-total-amount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.shared-estimate-page-total-amount .amount {
  color: #946A6A;
  font-weight: 600;
  font-size: 18px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.shared-estimate-page-table-header h3 {
  margin: 0;
  color: #946A6A;
  font-size: 18px;
  font-weight: 600;
  padding: 0 15px;
  text-align: center;
  width: 100%;
}

.shared-estimate-page-table + .shared-estimate-page-table {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 2px solid #E1D8D8;
}

.shared-estimate-page-table + .shared-estimate-page-table .shared-estimate-page-table-header {
  background-color: #F7F7F6;
  margin-bottom: 15px;
}

.shared-estimate-page-table + .shared-estimate-page-table .shared-estimate-page-table-header:first-child {
  background-color: transparent;
  margin-bottom: 0;
}

.shared-estimate-page-table-row.process-header {
  background-color: #F7F7F6;
  margin-top: 20px;
  border-bottom: none;
}

.shared-estimate-page-table-row.process-header:first-child {
  margin-top: 0;
}

.shared-estimate-page-table-row.process-header .shared-estimate-page-col {
  color: #946A6A;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 15px;
  justify-content: flex-start;
}

.customer-message-section {
  background-color: #F7F7F6;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  border: 1px solid #E1D8D8;
}

.customer-message-section h3 {
  color: #946A6A;
  font-size: 16px;
  margin: 0 0 12px 0;
  font-weight: 600;
}

.customer-message-section p {
  color: #333;
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .shared-estimate-page-container {
    margin: 10px;
    padding: 10px;
  }

  .shared-estimate-page-header h1 {
    font-size: 20px;
  }

  .shared-estimate-page-header .address {
    font-size: 16px;
  }

  .shared-estimate-page-table {
    max-width: 100%;
    font-size: 14px;
  }

  .shared-estimate-page-table-header {
    padding: 10px 0;
  }

  .shared-estimate-page-header-col,
  .shared-estimate-page-col {
    font-size: 13px;
    padding: 8px 4px;
    width: 20%;
  }

  .shared-estimate-page-header-col.item,
  .shared-estimate-page-col.item { 
    width: 40%; 
    padding-right: 5px;
  }

  .shared-estimate-page-header-col.quantity,
  .shared-estimate-page-col.quantity,
  .shared-estimate-page-header-col.unit,
  .shared-estimate-page-col.unit,
  .shared-estimate-page-header-col.unit-price,
  .shared-estimate-page-col.unit-price,
  .shared-estimate-page-header-col.amount,
  .shared-estimate-page-col.amount { 
    width: 15%;
    padding: 8px 2px;
  }

  .shared-estimate-page-table-row {
    padding: 8px 0;
  }

  .shared-estimate-page-note {
    margin: 6px 0;
    padding: 6px 8px;
    min-height: 22px;
  }

  .shared-estimate-page-note-label {
    font-size: 11px;
  }

  .shared-estimate-page-note-text {
    font-size: 11px;
    line-height: 1.3;
    padding: 1px 3px;
  }
}

@media (max-width: 480px) {
  .shared-estimate-page-header-col,
  .shared-estimate-page-col {
    font-size: 12px;
    padding: 6px 2px;
  }

  .shared-estimate-page-header-col.item,
  .shared-estimate-page-col.item { 
    width: 40%; 
  }

  .shared-estimate-page-header-col.quantity,
  .shared-estimate-page-col.quantity,
  .shared-estimate-page-header-col.unit,
  .shared-estimate-page-col.unit,
  .shared-estimate-page-header-col.unit-price,
  .shared-estimate-page-col.unit-price,
  .shared-estimate-page-header-col.amount,
  .shared-estimate-page-col.amount { 
    width: 15%;
  }

  .shared-estimate-page-note {
    margin: 4px 0;
    padding: 4px 6px;
    min-height: 20px;
  }

  .shared-estimate-page-note-label {
    font-size: 10px;
  }

  .shared-estimate-page-note-text {
    font-size: 10px;
    line-height: 1.2;
    padding: 1px 2px;
  }
} 