.bathroom-material-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.bathroom-material-list__item {
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.bathroom-material-list__item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px var(--shadow-color);
  border-color: var(--primary-light);
}

.bathroom-material-list__image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: white;
  overflow: hidden;
  border-bottom: 1px solid var(--border-color);
}

.bathroom-material-list__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  background-color: white;
}

.bathroom-material-list__image:not([src]),
.bathroom-material-list__image[src=""],
.bathroom-material-list__image[src="undefined"] {
  opacity: 0;
}

.bathroom-material-list__item:hover .bathroom-material-list__image {
  transform: scale(1.05);
}

.bathroom-material-list__info {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.bathroom-material-list__name {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  margin: 0 0 0.75rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bathroom-material-list__model {
  font-size: 0.875rem;
  color: var(--text-secondary-color);
  margin: 0 0 0.5rem 0;
}

.bathroom-material-list__price {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0.75rem 0 0 0;
}

.bathroom-material-list__prices {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px dashed var(--primary-light);
}

.bathroom-material-list__vendor-price,
.bathroom-material-list__consumer-price {
  font-size: 0.875rem;
  margin: 0.375rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bathroom-material-list__vendor-price::before,
.bathroom-material-list__consumer-price::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.bathroom-material-list__vendor-price {
  color: var(--primary-color);
}

.bathroom-material-list__vendor-price::before {
  background-color: var(--primary-color);
}

.bathroom-material-list__consumer-price {
  color: var(--primary-dark);
}

.bathroom-material-list__consumer-price::before {
  background-color: var(--primary-dark);
}

.bathroom-material-list__actions {
  display: flex;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--border-color);
  background-color: white;
}

.bathroom-material-list__clone-btn,
.bathroom-material-list__edit-btn,
.bathroom-material-list__delete-btn {
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bathroom-material-list__clone-btn {
  background-color: var(--primary-bg);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.bathroom-material-list__clone-btn:hover {
  background-color: var(--primary-light);
  color: white;
  transform: translateY(-2px);
}

.bathroom-material-list__edit-btn {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material-list__edit-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.bathroom-material-list__delete-btn {
  background-color: var(--primary-bg);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.bathroom-material-list__delete-btn:hover {
  background-color: #FFF0F0;
  transform: translateY(-2px);
}

.bathroom-material-list__item--selected {
  border: 2px solid var(--primary-color);
  box-shadow: 0 4px 20px rgba(148, 106, 106, 0.2);
}

.bathroom-material-list__selected-mark {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  z-index: 1;
  animation: scaleIn 0.2s ease-out;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* 태블릿 스타일 */
@media (min-width: 768px) {
  .bathroom-material-list {
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;
    gap: 2rem;
  }

  .bathroom-material-list__name {
    font-size: 1.25rem;
  }

  .bathroom-material-list__price {
    font-size: 1.375rem;
  }
}

/* 데스크톱 스타일 */
@media (min-width: 1024px) {
  .bathroom-material-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    padding: 1.5rem;
  }

  .bathroom-material-list__item {
    position: relative;
    padding-bottom: 4rem;
  }

  .bathroom-material-list__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1.5rem;
    gap: 1rem;
  }

  .bathroom-material-list__clone-btn,
  .bathroom-material-list__edit-btn,
  .bathroom-material-list__delete-btn {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}

/* 대형 화면 스타일 */
@media (min-width: 1440px) {
  .bathroom-material-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* 초대형 화면 스타일 */
@media (min-width: 1920px) {
  .bathroom-material-list {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1800px;
    margin: 0 auto;
  }
}

.bathroom-material-list__image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.bathroom-material-list__image-modal-content {
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
  animation: zoomIn 0.3s ease-out;
}

.bathroom-material-list__image-modal-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* 선택안함 자재 전용 스타일 */
.bathroom-material-list__no-selection {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px dashed var(--border-color);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}

.bathroom-material-list__no-selection:hover {
  border-color: var(--primary-color);
  background-color: rgba(255, 255, 255, 1);
  transform: translateY(-2px);
}

.bathroom-material-list__no-selection .bathroom-material-list__image-container {
  display: none;
}

.bathroom-material-list__no-selection .bathroom-material-list__info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(to bottom, transparent, rgba(148, 106, 106, 0.03));
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.bathroom-material-list__no-selection .bathroom-material-list__name {
  color: var(--primary-color);
  font-size: 1.1rem;
  text-align: center;
  margin: 0;
  font-weight: 500;
  opacity: 0.8;
  width: 100%;
}

.bathroom-material-list__no-selection .bathroom-material-list__model,
.bathroom-material-list__no-selection .bathroom-material-list__price,
.bathroom-material-list__no-selection .bathroom-material-list__prices {
  display: none;
}

.bathroom-material-list__no-selection.bathroom-material-list__item--selected {
  border-style: solid;
  border-color: var(--primary-color);
  background-color: rgba(148, 106, 106, 0.05);
}

.bathroom-material-list__no-selection.bathroom-material-list__item--selected .bathroom-material-list__image {
  opacity: 1;
  transform: scale(1.05);
}

.bathroom-material-list__no-selection.bathroom-material-list__item--selected .bathroom-material-list__name {
  opacity: 1;
  color: var(--primary-dark);
}

/* 선택안함 자재가 선택되었을 때의 마크 스타일 */
.bathroom-material-list__no-selection .bathroom-material-list__selected-mark {
  background-color: var(--primary-color);
  opacity: 0.9;
  box-shadow: 0 2px 4px rgba(148, 106, 106, 0.2);
}

.bathroom-material-list__completion-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.bathroom-material-list__completion-content {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.bathroom-material-list__completion-content h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: var(--primary-color);
  line-height: 1.4;
  word-break: keep-all;
}

.bathroom-material-list__completion-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.bathroom-material-list__result-btn,
.bathroom-material-list__reset-btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.bathroom-material-list__result-btn {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material-list__reset-btn {
  background-color: #f5f5f5;
  color: #666;
}

.bathroom-material-list__result-btn:hover,
.bathroom-material-list__reset-btn:hover {
  transform: translateY(-2px);
}

@media (max-width: 480px) {
  .bathroom-material-list__completion-content {
    padding: 1.5rem;
    width: 320px;
  }

  .bathroom-material-list__completion-content h3 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }

  .bathroom-material-list__result-btn,
  .bathroom-material-list__reset-btn {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
    min-width: 100px;
  }
} 