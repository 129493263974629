.AddressManagement-container {
  position: relative;
  width: 100%;
  max-width: 360px;
  min-height: 100vh;
  background-color: #F7F7F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-y: auto;
}

.AddressManagement-header {
  height: 20px;
  width: 100%;
}

.AddressManagement-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  cursor: pointer;
  user-select: none;
}

.AddressManagement-title:active {
  transform: scale(0.98);
}

.AddressManagement-input-section {
  width: 100%;
  max-width: 280px;
  margin: 20px auto;
}

.AddressManagement-input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.AddressManagement-input-group:first-child {
  margin-bottom: 30px;
}

.AddressManagement-input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.AddressManagement-button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
}

.AddressManagement-button:hover {
  background-color: #7A5656;
}

.AddressManagement-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AddressManagement-item {
  width: 100%;
  max-width: 280px;
  margin: 20px 0;
  padding: 15px;
  background-color: #FCF5F5;
  border-radius: 12px;
  position: relative;
}

.AddressManagement-item::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 5%;
  width: 90%;
  height: 1px;
  background-color: #E1D8D8;
}

.AddressManagement-item:last-child {
  margin-bottom: 0;
}

.AddressManagement-item:last-child::after {
  display: none;
}

.AddressManagement-item h2 {
  color: #946A6A;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
  cursor: default;
}

.AddressManagement-item h2.editable {
  cursor: pointer;
}

.AddressManagement-edit-hint {
  font-size: 12px;
  color: #946A6A;
  opacity: 0.7;
  font-weight: normal;
}

.AddressManagement-number-edit {
  width: 60px;
  height: 30px;
  font-size: 18px;
  padding: 0 8px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  background-color: transparent;
  text-align: center;
  outline: none;
  color: #946A6A;
}

.AddressManagement-number-edit:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.AddressManagement-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.AddressManagement-modal-content {
  background-color: #FCF5F5;
  padding: 15px;
  border-radius: 12px;
  text-align: center;
  width: 80%;
  max-width: 300px;
}

.AddressManagement-modal-content p {
  color: #946A6A;
  margin-bottom: 15px;
  font-size: 14px;
}

.AddressManagement-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.AddressManagement-modal-button {
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  flex: 1;
  max-width: 100px;
}

.AddressManagement-modal-button:hover {
  background-color: #7A5656;
}

.AddressManagement-modal-button:last-child {
  background-color: #C57E66;
}

.AddressManagement-modal-button:last-child:hover {
  background-color: #A35E46;
}

.AddressManagement-construction-button {
  background-color: #EECACA;
  color: #946A6A;
}

.AddressManagement-save-button,
.AddressManagement-delete-button {
  background-color: #946A6A;
  color: #ffffff;
}

.AddressManagement-button:disabled {
  background-color: #CCCCCC;
  cursor: not-allowed;
}

.AddressManagement-copy-button {
  background-color: #946A6A;
  color: white;
  margin: 10px 0;
  font-size: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddressManagement-copy-button:hover {
  background-color: #7A5656;
} 