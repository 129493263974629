.sketchup-license-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.auth-form-container {
  background: white;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-form-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
}

.auth-form-container h2 {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 0.9rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus {
  outline: none;
  border-color: #946A6A;
}

button {
  width: 100%;
  padding: 1rem;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #7d5a5a;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.auth-switch {
  margin-top: 2rem;
  text-align: center;
}

.auth-switch button {
  background: none;
  border: none;
  color: #946A6A;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.auth-switch button:hover {
  color: #7d5a5a;
  background: none;
}

/* 라이센스 정보 스타일 */
.license-info {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
}

.license-info h3 {
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
}

.license-info p {
  margin: 0.5rem 0;
  color: #555;
  font-size: 0.9rem;
}

.license-status {
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
  margin-top: 1rem;
  background-color: #e8f5e9;
  color: #28a745;
}

.features-info {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #dee2e6;
  margin-bottom: 2rem;
}

.features-info h4 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.features-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features-info li {
  padding: 0.8rem;
  margin: 0.5rem 0;
  background-color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #555;
  border: 1px solid #dee2e6;
}

.purchase-button {
  margin-top: 1rem;
  background-color: #28a745;
}

.purchase-button:hover {
  background-color: #218838;
}

/* 모바일 반응형 스타일 */
@media (max-width: 480px) {
  .auth-form-container {
    padding: 2rem;
  }

  .auth-form-container h1 {
    font-size: 1.5rem;
  }

  .auth-form-container h2 {
    font-size: 1.2rem;
  }

  .form-group input {
    padding: 0.7rem;
  }

  button {
    padding: 0.8rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}

/* 사용자 정보 스타일 */
.user-info {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.user-info p {
  margin: 0.5rem 0;
  color: #555;
  font-size: 0.9rem;
}

/* 로그아웃 버튼 스타일 */
.logout-button {
  margin-top: 1.5rem;
  background-color: #dc3545;
}

.logout-button:hover {
  background-color: #c82333;
}

.admin-link {
  display: block;
  text-align: center;
  color: #946A6A;
  text-decoration: none;
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #946A6A;
  border-radius: 4px;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-in;
}

.admin-link:hover {
  background-color: #946A6A;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.license-key-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  margin: 0.5rem 0;
  align-items: center;
}

.license-key-text {
  margin: 0;
  font-family: monospace;
  font-size: 0.55rem !important;
  color: #495057;
  word-break: break-all;
  width: 100%;
}

.copy-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  max-width: 120px;
  align-self: center;
}

.copy-button:hover {
  background-color: #7d5a5a;
}

.copy-button:active {
  transform: scale(0.98);
}

@media (max-width: 480px) {
  .license-key-container {
    padding: 10px;
  }

  .license-key-text {
    font-size: 0.65rem !important;
  }

  .copy-button {
    max-width: 100%;
  }
}

.brand-logo {
  text-align: center;
  color: #946A6A;
  font-size: 1.2rem;
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
  font-weight: 500;
  letter-spacing: 1px;
  opacity: 0.8;
  font-family: 'Arial', sans-serif;
}

.additional-features {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.features-grid {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.additional-features h4 {
  color: #333;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.license-info .license-key-container .license-key-text {
  margin: 0;
  font-family: monospace;
  font-size: 0.75rem !important;
  color: #495057;
  word-break: break-all;
  width: 100%;
}

@media (max-width: 480px) {
  .license-info .license-key-container .license-key-text {
    font-size: 0.65rem !important;
  }
}