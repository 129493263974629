.email-template-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-template-modal {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.email-template-modal h2 {
  margin: 0 0 24px 0;
  color: #333;
  font-size: 18px;
}

.template-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.template-form .input-group {
  margin-bottom: 24px;
}

.template-form label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-size: 14px;
}

.template-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  caret-color: #946A6A;
}

.template-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  min-height: 200px;
  caret-color: #946A6A;
}

.button-group {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: auto;
}

.button-group button {
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.button-group button:first-child {
  background: #946A6A;
  color: white;
}

.button-group button:last-child {
  background: #f0f0f0;
  color: #666;
}

.signature-section {
  margin: 32px 0;
  padding: 24px;
  background: #F5EFEF;
  border-radius: 12px;
}

.signature-section h3 {
  font-size: 16px;
  color: #946A6A;
  margin: 0 0 20px 0;
  text-align: center;
}

.signature-grid {
  display: grid;
  gap: 16px;
}

.signature-grid .input-group {
  position: relative;
  margin-bottom: 0;
}

.signature-grid .input-group label {
  position: absolute;
  left: 12px;
  top: -8px;
  background: #F5EFEF;
  padding: 0 8px;
  font-size: 12px;
  color: #946A6A;
}

.signature-grid input {
  height: 48px;
  border: 1px solid #B69999;
  background: white;
  padding: 0 16px;
}

/* 스크롤바 스타일링 */
.email-template-modal::-webkit-scrollbar {
  width: 8px;
}

.email-template-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.email-template-modal::-webkit-scrollbar-thumb {
  background: #B69999;
  border-radius: 4px;
}

.email-template-modal::-webkit-scrollbar-thumb:hover {
  background: #946A6A;
}

.description-section {
  margin: 32px 0;
  padding: 24px;
  background: #F5EFEF;
  border-radius: 12px;
}

.description-section h3 {
  font-size: 16px;
  color: #946A6A;
  margin: 0 0 20px 0;
  text-align: center;
}

.description-section textarea {
  width: 100%;
  min-height: 100px;
  border: 1px solid #B69999;
  background: white;
  padding: 16px;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  line-height: 1.5;
}
 