.memo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.memo-modal {
  background: white;
  border-radius: 12px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
}

.memo-modal h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 18px;
  text-align: center;
}

.item-info {
  background: #F7F7F6;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.item-info p {
  margin: 5px 0;
  font-size: 14px;
}

.memo-field {
  margin-bottom: 20px;
}

.memo-field label {
  display: block;
  margin-bottom: 8px;
  color: #946A6A;
  font-size: 14px;
}

.memo-field textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
}

.memo-field textarea:focus {
  outline: none;
  border-color: #946A6A;
}

.memo-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.memo-buttons button {
  padding: 8px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.memo-buttons button:first-child {
  background-color: #946A6A;
  color: white;
}

.memo-buttons button:first-child:hover {
  background-color: #7A5656;
}

.memo-buttons button:last-child {
  background-color: #E1D8D8;
  color: #333;
}

.memo-buttons button:last-child:hover {
  background-color: #D1C8C8;
} 