.admin-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-header {
  margin-bottom: 2rem;
  text-align: center;
}

.admin-header h1 {
  color: #333;
  margin-bottom: 1rem;
}

.users-table-container {
  overflow-x: auto;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.users-table th,
.users-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.users-table tr:hover {
  background-color: #f8f9fa;
}

.license-active {
  color: #28a745;
  background-color: #e8f5e9;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 500;
}

.license-inactive {
  color: #dc3545;
  background-color: #fbe9e7;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 500;
}

/* 삭제 버튼 스타일 */
.license-delete-btn {
  padding: 8px 16px;
  background-color: white;
  color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 60px;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.license-delete-btn:hover {
  background-color: #dc3545;
  color: white;
}

.license-delete-btn:disabled {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #6c757d;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  background-color: #f8d7da;
  padding: 0.75rem;
  border-radius: 4px;
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  .admin-container {
    padding: 1rem;
  }
  
  .users-table th,
  .users-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
  
  .license-delete-btn {
    padding: 6px 12px;
    font-size: 12px;
  }
} 