/* 공통 변수 */
:root {
  --primary-color: #946A6A;
  --border-color: #EEEEEE;
  --background-color: #FFFFFF;
  --text-primary: #333333;
  --text-secondary: #666666;
  --padding: 12px;
  --border-radius: 16px;
}

/* 기본 컨테이너 */
.estimate-app .estimate-result {
  max-width: 360px;
  width: calc(100% - 32px);
  margin: 16px auto;
  padding: 0;
  background-color: var(--background-color);
  min-height: calc(100vh - 32px);
  box-sizing: border-box;
}

/* 상단 버튼 */
.estimate-app .button-container {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estimate-app .go-home-button {
  padding: 8px 14px;
  background: none;
  border: 1px solid #946A6A;
  color: #946A6A;
  border-radius: var(--border-radius);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.estimate-app .go-home-button:hover {
  background: #946A6A;
  color: white;
}

/* 타이틀 영역 */
.estimate-app .title-container {
  width: 100%;
  margin-bottom: 0;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.estimate-app .main-title {
  color: #946A6A;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.3px;
}

/* 견적서 헤더 */
.estimate-app .estimate-header {
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.estimate-app .sub-title {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: #946A6A;
  letter-spacing: -0.3px;
}

.estimate-app .estimate-date {
  font-size: 13px;
  color: var(--text-secondary);
}

/* 공정 목록 */
.estimate-app .process-list {
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.estimate-app .process-header {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0;
  background: #F5EFEF;
  border-bottom: 1px solid #946A6A;
  font-size: 13px;
  font-weight: 600;
  color: #946A6A;
  letter-spacing: -0.3px;
  text-align: center;
}

.estimate-app .process-header::after {
  display: none;
}

.estimate-app .process-header span {
  text-align: center;
  padding: var(--padding);
}

.estimate-app .estimate-result-process-item {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0;
  border-bottom: 1px solid #F5EFEF;
  align-items: center;
}

.estimate-app .estimate-result-process-item::after {
  display: none;
}

.estimate-app .estimate-result-process-item:last-child {
  border-bottom: none;
}

.estimate-app .estimate-result-process-name {
  font-size: 13px;
  color: var(--text-primary);
  letter-spacing: -0.3px;
  padding: var(--padding);
  margin: 0;
  text-align: center;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
}

.estimate-app .estimate-result-price-value,
.estimate-app .visit-required {
  color: #946A6A;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.3px;
  padding: var(--padding);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* 총액 */
.estimate-app .total-price {
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1.5px solid #946A6A;
  border-radius: var(--border-radius);
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #946A6A;
}

.estimate-app .total-price::after {
  display: none;
}

.estimate-app .total-label {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: -0.3px;
  text-align: center;
  padding-right: var(--padding);
}

.estimate-app .total-value {
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: -0.3px;
  text-align: center;
  padding-left: var(--padding);
}

/* 푸터 */
.estimate-app .estimate-footer {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimate-app .note {
  font-size: 12px;
  line-height: 1.6;
  color: var(--text-secondary);
  margin: 0;
  letter-spacing: -0.3px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* EstimateProcess 스타일 */
.estimate-app .content {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  padding: var(--padding);
  box-sizing: border-box;
}

/* 헤더 스타일 */
.estimate-app .header {
  margin-bottom: 20px;
  text-align: center;
}

.estimate-app .header .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 8px 0;
  letter-spacing: -0.3px;
}

.estimate-app .area-info,
.estimate-app .process-info,
.estimate-app .user-info-description {
  font-size: 13px;
  color: var(--text-secondary);
  line-height: 1.4;
  margin: 0;
  letter-spacing: -0.3px;
}

/* 버튼 스타일 */
.estimate-app .complete-button {
  width: 280px;
  height: 50px;
  padding: 12px;
  border: none;
  border-radius: var(--border-radius);
  background: #E65D20;
  color: white;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin: 60px auto 0;
  letter-spacing: -0.3px;
  display: block;
}

.estimate-app .complete-button:not(.active) {
  opacity: 0.6;
  cursor: not-allowed;
}

.estimate-app .complete-button.active {
  background: #D14E12;
}

.estimate-app .warning-message {
  color: #E53E3E;
  font-size: 12px;
  margin: 8px 0 0 0;
  text-align: center;
  letter-spacing: -0.3px;
}

/* 액션 버튼 컨테이너 */
.estimate-app .action-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 0;
}

/* 동그라미 버튼 스타일 */
.estimate-app .circle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  background: white;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
}

.estimate-app .circle-button:hover {
  background: var(--primary-color);
  color: white;
}

.estimate-app .button-icon {
  font-size: 20px;
  margin-bottom: 4px;
}

.estimate-app .circle-button span {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.estimate-id {
  font-size: 13px;
  color: #946A6A;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: -0.3px;
}

/* 버자 버튼 애니메이션 스타일 */
@keyframes bounce {
  0%, 10%, 40%, 70%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  55% {
    transform: translateY(-5px);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(148, 106, 106, 0.3),
                0 0 10px rgba(148, 106, 106, 0.2),
                0 0 15px rgba(148, 106, 106, 0.1);
    background: rgba(148, 106, 106, 0.8);
  }
  50% {
    box-shadow: 0 0 10px rgba(148, 106, 106, 0.5),
                0 0 20px rgba(148, 106, 106, 0.3),
                0 0 30px rgba(148, 106, 106, 0.2);
    background: rgba(148, 106, 106, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(148, 106, 106, 0.3),
                0 0 10px rgba(148, 106, 106, 0.2),
                0 0 15px rgba(148, 106, 106, 0.1);
    background: rgba(148, 106, 106, 0.8);
  }
}

.sms-button {
  position: relative;
  animation: bounce 2s infinite;
}

.sms-button.active {
  animation: bounce 2s infinite, glow 1.5s infinite;
  border: none;
  color: white;
}

.sms-button .button-icon {
  transition: transform 0.3s ease;
}

.sms-button.active .button-icon {
  transform: scale(1.1);
  color: white;
}

.sms-button span {
  position: relative;
  z-index: 1;
}

.sms-button.active span {
  color: white;
  font-weight: 600;
}

/* 기존 circle-button 스타일 수정 */
.circle-button {
  transition: all 0.3s ease;
  position: relative;
  overflow: visible;
}

.circle-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.estimate-description {
  margin: 16px -12px 0 -12px;
  padding: 24px 0;
  background: #F5EFEF;
  border-radius: 12px;
  width: calc(100% + 24px);
  box-sizing: border-box;
}

.estimate-description p {
  margin: 0;
  font-size: 14px;
  line-height: 1.8;
  color: #333;
  text-align: left;
  white-space: pre-line;
  letter-spacing: -0.3px;
  word-break: keep-all;
  word-wrap: break-word;
  padding: 0 24px;
}

.estimate-app .process-description {
  grid-column: 1 / -1;
  padding: 12px 16px;
  background: #F8F9FA;
  border-bottom: 1px solid #F5EFEF;
  font-size: 13px;
  color: #666666;
  line-height: 1.6;
  text-align: left;
  letter-spacing: -0.3px;
  word-break: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
  position: relative;
  padding-left: 32px;
  min-height: 44px;
  width: 100%;
  box-sizing: border-box;
}

.estimate-app .process-description::before {
  content: '';
  position: absolute;
  left: 16px;
  top: 18px;
  width: 6px;
  height: 6px;
  background-color: #946A6A;
  border-radius: 50%;
}

.estimate-app .process-description:last-child {
  border-bottom: none;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

/* 모달 뷰를 위한 특별 스타일 */
.estimate-modal-content .estimate-result {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.estimate-modal-content .estimate-header {
  margin-bottom: 24px;
  text-align: center;
}

.estimate-modal-content .sub-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  letter-spacing: -0.5px;
}

.estimate-modal-content .estimate-date {
  font-size: 14px;
  color: #666;
}

/* 공정 목록 2컬럼 레이아웃 */
.estimate-modal-content .process-list {
  border: 1px solid #eee;
  border-radius: 12px;
  overflow: hidden;
}

.estimate-modal-content .process-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #f8f8f8;
  padding: 14px 0;
  border-bottom: 1px solid #eee;
}

.estimate-modal-content .process-header span {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #333;
}

.estimate-modal-content .estimate-result-process-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.estimate-modal-content .estimate-result-process-name {
  text-align: center;
  font-size: 15px;
  color: #333;
  padding: 0 16px;
  line-height: 1.4;
}

.estimate-modal-content .estimate-result-price-value,
.estimate-modal-content .visit-required {
  text-align: center;
  font-size: 15px;
  color: #946A6A;
  font-weight: 500;
}

/* 공정 설명 */
.estimate-modal-content .process-description {
  grid-column: 1 / -1;
  padding: 12px 20px;
  background: #f9f9f9;
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  border-bottom: 1px solid #eee;
}

/* 총액 */
.estimate-modal-content .total-price {
  margin: 24px 0;
  padding: 20px;
  background: #946A6A;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.estimate-modal-content .total-label {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.estimate-modal-content .total-value {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
}

/* 푸터 */
.estimate-modal-content .estimate-footer {
  text-align: center;
  margin-top: 20px;
}

.estimate-modal-content .note {
  font-size: 13px;
  color: #666;
  line-height: 1.6;
}

/* 특별 항목 (공과잡비 등) */
.estimate-modal-content .estimate-result-process-item.special {
  background: #f9f9f9;
}

/* 반응형 조정 */
@media screen and (max-width: 480px) {
  .estimate-modal-content .estimate-result-process-name,
  .estimate-modal-content .estimate-result-price-value,
  .estimate-modal-content .visit-required {
    font-size: 14px;
  }
  
  .estimate-modal-content .process-description {
    font-size: 13px;
    padding: 12px 16px;
  }
}

/* 시공 프로세스 타임라인 스타일 */
.construction-process {
  margin: 32px 0 0 0;
  padding: 0 20px;
}

.process-title {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: -0.3px;
}

.timeline {
  position: relative;
  padding-left: 36px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #946A6A;
  opacity: 0.3;
}

.timeline-item {
  position: relative;
  margin-bottom: 32px;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-number {
  position: absolute;
  left: -36px;
  width: 30px;
  height: 30px;
  background: #946A6A;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  z-index: 1;
}

.timeline-content {
  background: #F8F9FA;
  border-radius: 12px;
  padding: 16px;
}

.timeline-step {
  font-size: 14px;
  font-weight: 600;
  color: #946A6A;
  margin-bottom: 4px;
  letter-spacing: -0.3px;
}

.timeline-description {
  font-size: 13px;
  color: var(--text-secondary);
  line-height: 1.5;
  letter-spacing: -0.3px;
  margin: 0;
}

/* note 클래스 스타일 수정 */
.estimate-modal-note,
.note {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* 기존 footer 스타일은 유지 */
.estimate-footer,
.estimate-modal-footer {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 시공 프로세스 섹션 스타일 - 독립적인 섹션으로 분리 */
.construction-process-section {
  width: 100%;
  margin-top: 24px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background: #ffffff;
}

.process-title {
  font-size: 15px;
  font-weight: 600;
  color: #946A6A;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: -0.3px;
}

/* 나머지 타임라인 관련 스타일은 동일하게 유지 */
.timeline {
  position: relative;
  padding-left: 36px;
}

/* 비용 지급 방식 스타일 */
.payment-process-section {
  width: 100%;
  margin-top: 16px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background: #ffffff;
}

.payment-info {
  background: #F8F9FA;
  border-radius: 12px;
  padding: 20px;
}

.payment-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.payment-step {
  font-size: 14px;
  font-weight: 600;
  color: #946A6A;
  padding: 10px 24px;
  background: white;
  border: 1px solid #946A6A;
  border-radius: 20px;
  text-align: center;
  min-width: 120px;
  transition: all 0.3s ease;
}

.payment-step:hover {
  background: #946A6A;
  color: white;
}

.payment-arrow {
  color: #946A6A;
  font-size: 16px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-description {
  text-align: center;
  font-size: 13px;
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.5;
  letter-spacing: -0.3px;
}

/* 기존 클래스명을 estimate-result- 접두사를 붙여 변경 */
.estimate-result-header {
  margin: 24px 0;
  text-align: center;
  padding: 16px;
}

.estimate-result-button-container {
  padding: 16px;
  margin-bottom: 16px;
}

.estimate-result-circle-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  background: white;
  color: var(--primary-color);
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease;
}

.estimate-result-note {
  color: #666;
  font-size: 12px;
  line-height: 1.5;
}