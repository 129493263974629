.message-preview {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.message-preview__header {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.message-preview__header:hover {
  background-color: #f5f5f5;
}

.message-preview__header h2 {
  color: #333;
  margin: 0;
}

.message-preview__actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.sample-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.sample-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sample-button.add {
  background: #4CAF50;
  color: white;
}

.sample-button.add:hover:not(:disabled) {
  background: #45a049;
}

.sample-button.delete {
  background: #f44336;
  color: white;
}

.sample-button.delete:hover:not(:disabled) {
  background: #da190b;
}

.message-preview__loading,
.message-preview__error,
.message-preview__empty {
  text-align: center;
  padding: 40px;
  background: #f8f8f8;
  border-radius: 8px;
  color: #666;
  font-size: 16px;
}

.message-preview__error {
  color: #d32f2f;
  background: #ffebee;
}

.date-group {
  margin-bottom: 30px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
}

.date-header {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.date-header h4 {
  color: #946A6A;
  margin: 0;
  font-size: 18px;
}

.process-message-card {
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
}

.process-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-range {
  color: #666;
  font-size: 12px;
}

.recipients-list {
  margin: 10px 0;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 4px;
}

.recipient-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 13px;
}

.message-preview-box {
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
}

.message-content {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 12px;
  margin: 0;
  padding: 10px;
  background: white;
  border-radius: 4px;
}

.send-button {
  background: #946A6A;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  margin-top: 20px;
}

.send-button:hover {
  background: #7a5757;
} 