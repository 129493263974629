.estimate-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  padding: 20px;
}

.estimate-modal-content {
  background: #fff;
  border-radius: 24px;
  max-height: 90vh;
  width: 100%;
  max-width: 460px;
  overflow-y: auto;
  position: relative;
  padding: 20px 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* 스크롤바 기본 트랙 */
.estimate-modal-content::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

/* 스크롤바 트랙 */
.estimate-modal-content::-webkit-scrollbar-track {
  background: transparent;
  margin: 20px 0;
}

/* 스크롤바 썸네일 */
.estimate-modal-content::-webkit-scrollbar-thumb {
  background: rgba(148, 106, 106, 0.3);
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: all 0.3s ease;
}

/* 스크롤바 호버 효과 */
.estimate-modal-content::-webkit-scrollbar-thumb:hover {
  background: rgba(148, 106, 106, 0.5);
  border: 2px solid transparent;
  background-clip: padding-box;
}

/* 닫기 버튼 */
.estimate-modal-close {
  position: absolute;
  right: 20px;  /* left에서 right로 변경 */
  top: 20px;
  background: rgba(148, 106, 106, 0.1);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 22px;
  cursor: pointer;
  color: #946A6A;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1;
}

.estimate-modal-close:hover {
  background: #946A6A;
  color: white;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(148, 106, 106, 0.3);
}

/* 모바일 대응 */
@media screen and (max-width: 480px) {
  .estimate-modal-overlay {
    padding: 10px;
  }

  .estimate-modal-content {
    max-height: 95vh;
    padding: 15px 10px;
    border-radius: 20px;
  }
  
  .estimate-modal-close {
    right: 15px;
    top: 15px;
    width: 36px;
    height: 36px;
    font-size: 20px;
  }

  /* 모바일에서는 스크롤바 숨김 */
  .estimate-modal-content::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

/* 애니메이션 효과 */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.estimate-modal-content {
  animation: modalFadeIn 0.3s ease-out;
}

/* 모달 푸터 스타일 */
.estimate-modal-footer {
  text-align: center;
  margin: 30px auto 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  width: 90%;
}

.estimate-modal-note {
  font-size: 13px;
  color: #666;
  line-height: 1.6;
  margin: 0;
  text-align: center;
  word-break: keep-all;
  white-space: pre-line;
}

@media screen and (max-width: 480px) {
  .estimate-modal-footer {
    margin: 20px auto 0;
    padding: 16px;
    width: 95%;
  }
  
  .estimate-modal-note {
    font-size: 12px;
  }
}

/* 모달 내부의 process-item 높이 조정 */
.estimate-modal-content .process-item {
  min-height: 24px;
  padding: 4px 0;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.estimate-modal-content .process-name,
.estimate-modal-content .price-value,
.estimate-modal-content .visit-required {
  font-size: 13px;
  min-height: 24px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  word-break: keep-all;
  white-space: pre-line;
  text-align: center;
}

/* 공정 설명 부분도 조정 */
.estimate-modal-content .process-description {
  padding: 6px 16px;
  font-size: 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1.4;
}

/* 모바일 대응 */
@media screen and (max-width: 480px) {
  .estimate-modal-content .process-item {
    min-height: 22px;
    padding: 3px 0;
  }

  .estimate-modal-content .process-name,
  .estimate-modal-content .price-value,
  .estimate-modal-content .visit-required {
    font-size: 12px;
    min-height: 22px;
  }
} 