.api-settings-container {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  width: 100%;
  max-width: 360px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-color: #F7F7F6;
  position: relative;
}

.api-settings-container h1 {
  color: #946A6A;
  text-align: center;
  margin: 32px 0 24px;
  font-size: 24px;
}

.api-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #E1D8D8;
}

.input-group label {
  display: block;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.input-with-button {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input-with-button input {
  width: 100%;
  height: 46px;
  padding: 0 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 15px;
  color: var(--primary-color);
  text-align: center;
}

.input-with-button input::placeholder {
  color: #999;
  font-size: 13px;
}

.test-button {
  width: 100%;
  height: 46px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.test-button:hover {
  background-color: var(--primary-color);
}

.test-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.save-button {
  width: 100%;
  height: 46px;
  margin-top: 24px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:hover {
  background-color: var(--secondary-color);
}

.save-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.message-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 14px;
  z-index: 1000;
  text-align: center;
  min-width: 200px;
  max-width: 320px;
}

@media (max-width: 360px) {
  .api-settings-container {
    padding: 16px;
  }
  
  .api-form {
    padding: 16px;
  }
  
  .input-group {
    padding: 12px;
  }
  
  .input-with-button input {
    height: 64px;
    font-size: 14px;
  }
  
  .test-button {
    height: 46px;
    font-size: 14px;
  }
  
  .save-button {
    height: 46px;
    font-size: 14px;
  }
} 