.square-pipe {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.square-pipe h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

/* 입력 폼 스타일 */
.input-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.input-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.input-group input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.2);
}

/* Chrome, Safari, Edge에서 number input의 화살표 제거 */
.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox에서 number input의 화살표 제거 */
.input-group input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* 공유 버튼 스타일 .*/
.share-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.share-button {
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-button:hover {
  background-color: #7d5a5a;
}

.share-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 도면 컨테이너. */
.drawing-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  overflow-x: auto;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-drawing {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  min-height: 200px;
}

.section-view {
  text-align: center;
  margin-bottom: 30px;
}

.section-view h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
}

.section-drawing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-drawing {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
  height: 250px;
}

.info-panel {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: center;
}

.info-panel h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
}

.info-panel p {
  margin: 8px 0;
  color: #666;
  font-size: 14px;
}

.dimension-text {
  font-size: 12px;
  fill: #333;
}

/* 공유 컨테이너 스타일 */
.share-container-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  width: 100%;
  max-width: 1200px;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: -9999;
}

.share-container {
  width: 100%;
  min-height: 600px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-header {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background: white;
  width: 100%;
}

.share-header h2 {
  color: #333;
  margin: 0;
  font-size: 24px;
}

.share-drawing {
  margin: 20px 0;
  border: 1px solid #eee;
  padding: 20px;
  background: white;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-drawing svg {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.share-section-view {
  text-align: center;
  margin: 30px 0;
  padding: 20px;
  background: white;
}

.share-section-view svg {
  width: 300px;
  height: 300px;
  margin: 20px auto;
}

.share-info {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.share-info h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 18px;
}

.share-info p {
  margin: 10px 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

/* 모바일 대응. */
@media (max-width: 768px) {
  .square-pipe {
    padding: 10px;
  }

  .square-pipe h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .input-form {
    grid-template-columns: 1fr;
    padding: 15px;
    gap: 15px;
  }

  .input-group label {
    font-size: 14px;
  }

  .input-group input {
    padding: 10px;
    font-size: 16px;
  }

  .share-button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }

  .drawing-container {
    padding: 10px;
    margin-bottom: 20px;
    min-height: 200px;
  }

  .section-view h3,
  .info-panel h3 {
    font-size: 16px;
  }

  .info-panel p {
    font-size: 14px;
  }

  .dimension-text {
    font-size: 10px;
  }
  
  .share-container {
    padding: 20px;
  }
  
  .share-drawing {
    padding: 20px;
    min-height: 300px;
  }
  
  .share-drawing svg {
    max-height: 400px;
  }
  
  .section-drawing {
    max-width: 250px;
    height: 200px;
    padding: 15px;
  }
  
  .share-section-view svg {
    width: 250px;
    height: 250px;
  }
} 