.image-classification {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  text-align: center;
  background-color: #F7F7F6;
  color: #946A6A;
}

h1, h2, h3 {
  text-align: center;
  color: #946A6A;
  margin-bottom: 30px;
}

.main-content {
  display: flex;
  gap: 40px;
  padding: 30px;
  background-color: rgba(148, 106, 106, 0.05);
}

.left-panel, .right-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: rgba(148, 106, 106, 0.02);
}

.left-panel {
  flex: 2;
}

.right-panel {
  flex: 1;
}

.drop-area {
  border: 2px dashed #946A6A;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 30px;
  background-color: #FCF5F5;
}

.drop-area:hover {
  background-color: #FCF5F5;
  border-color: #7A5656;
  transform: scale(1.02);
  box-shadow: 0 0 15px rgba(148, 106, 106, 0.3);
}

.drop-area.dragging {
  background-color: #FCF5F5;
  border-color: #7A5656;
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(148, 106, 106, 0.5);
}

.drop-area p {
  margin-bottom: 20px;
  color: #946A6A;
}

.drop-area button {
  background-color: #946A6A;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.drop-area button:hover {
  background-color: #7A5656;
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.address-form {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  padding: 20px;
}

.address-form input {
  flex-grow: 1;
  padding: 15px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  max-width: 300px;
  font-size: 16px;
  color: #946A6A;
  background-color: transparent;
}

.address-form button {
  background-color: #946A6A;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
}

.folder-list {
  margin-top: 30px;
  width: 100%;
  padding: 20px;
}

.folder-item {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #FCF5F5;
  border-radius: 12px;
}

.folder-item h3 {
  margin-bottom: 15px;
}

.folder-item ul {
  list-style-type: none;
  padding-left: 0;
}

.folder-item li {
  margin-bottom: 10px;
  color: #946A6A;
}

.results {
  margin-top: 30px;
  width: 100%;
  padding: 20px;
}

.results ul {
  list-style-type: none;
  padding-left: 0;
}

.results li {
  margin-bottom: 15px;
  color: #946A6A;
}

.log-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #FCF5F5;
  border-radius: 12px;
  font-style: italic;
  color: #946A6A;
}

.category-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 12px;
  background-color: #F7F7F6;
}

.category-item {
  background-color: #FCF5F5;
  color: #946A6A;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
  width: 100%;
  justify-items: center;
  padding: 20px;
}

.image-item {
  position: relative;
  width: 100%;
  max-width: 200px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 12px;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(148, 106, 106, 0.5);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
  color: white;
  opacity: 1;
}

.delete-icon:hover {
  background-color: rgba(122, 86, 86, 0.8);
  transform: scale(1.1);
}

.error-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #FCF5F5;
  border-radius: 12px;
  color: #946A6A;
  font-weight: bold;
}

.classification-form {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #F7F7F6;
  border-radius: 12px;
}

.form-group {
  margin-bottom: 15px;
  background-color: rgba(148, 106, 106, 0.02);
  padding: 10px;
  margin: 10px 0;
}

.classification-form input[type="text"],
.classification-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  font-size: 16px;
  color: #946A6A;
  background-color: transparent;
}

.selected-path {
  margin-top: 10px;
  font-size: 14px;
  color: #946A6A;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #7A5656;
}

.path-info {
  font-size: 12px;
  color: #946A6A;
  margin-top: 20px;
  text-align: left;
}

.save-path-button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.save-path-button:hover {
  background-color: #7A5656;
}

.saved-paths {
  margin-top: 15px;
  text-align: left;
}

.saved-paths h4 {
  margin-bottom: 5px;
  font-size: 14px;
  color: #946A6A;
}

.saved-paths ul {
  list-style-type: none;
  padding: 0;
}

.saved-paths li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  padding: 8px 12px;
  font-size: 12px;
  transition: background-color 0.3s;
  border-radius: 12px;
  margin-bottom: 5px;
  color: #946A6A;
  background-color: #FCF5F5;
}

.saved-paths li span {
  cursor: pointer;
  flex-grow: 1;
  margin-right: 10px;
}

.saved-paths li.selected {
  background-color: #F7F7F6;
  color: #946A6A;
}

.delete-path-button {
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-path-button:hover {
  background-color: #7A5656;
}

.save-message {
  margin-top: 10px;
  font-size: 14px;
  color: #946A6A;
  font-weight: bold;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }
  
  .image-classification {
    padding: 20px;
  }
  
  .left-panel, .right-panel {
    padding: 20px;
  }
}

.category-list-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  padding: 0.5rem;
  max-width: 100%;
  margin: 0 auto;
}

.category-item {
  background-color: #FCF5F5;
  color: #946A6A;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem;
}

.category-item:hover {
  background-color: #946A6A;
  color: white;
}

/* 로딩 상태일 때의 스타일 */
.loading-message {
  background-color: #FCF5F5;
  color: #946A6A;
  padding: 15px;
  border-radius: 12px;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

/* disabled 상태의 입력 필드 스타일 */
input:disabled {
  background-color: #F7F7F6;
  cursor: not-allowed;
  opacity: 0.7;
}

/* disabled 상태의 버튼 스타일 */
button:disabled {
  background-color: #C0A9A9;
  cursor: not-allowed;
  opacity: 0.7;
}

/* 삭제 버튼 타일 */
.delete-all-button {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.delete-all-button:hover {
  background-color: #c9302c;
  transform: scale(1.05);
}

.delete-all-button:disabled {
  background-color: #e6a5a3;
  cursor: not-allowed;
  transform: none;
}

/* 서버 이미지 관련 스타일 */
.address-select {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  font-size: 16px;
  color: #946A6A;
  background-color: white;
}

.server-images {
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
  padding: 20px;
}

.category-section {
  margin-bottom: 30px;
  background-color: #FCF5F5;
  padding: 20px;
  border-radius: 12px;
}

.category-section h4 {
  color: #946A6A;
  margin-bottom: 15px;
  text-align: left;
}

.server-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.server-image-item {
  position: relative;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.server-image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.image-name {
  display: block;
  font-size: 12px;
  color: #946A6A;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 스크롤바 스타일링 */
.server-images::-webkit-scrollbar {
  width: 8px;
}

.server-images::-webkit-scrollbar-track {
  background: #F7F7F6;
  border-radius: 4px;
}

.server-images::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 4px;
}

.server-images::-webkit-scrollbar-thumb:hover {
  background: #7A5656;
}

/* 처리 중 오버레이 */
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-content {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.processing-progress {
  width: 100%;
  height: 20px;
  background-color: #F7F7F6;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}

.progress-bar {
  height: 100%;
  background-color: #946A6A;
  transition: width 0.3s ease;
}

.processing-text {
  color: #946A6A;
  margin-bottom: 10px;
  font-size: 16px;
}

.processing-percentage {
  color: #946A6A;
  font-size: 24px;
  font-weight: bold;
}

/* disabled 상태의 입력 필드와 버튼 스타일 강화 */
input:disabled {
  background-color: #F7F7F6;
  cursor: not-allowed;
  opacity: 0.7;
  border: 1px dashed #E1D8D8;
}

button:disabled {
  background-color: #C0A9A9;
  cursor: not-allowed;
  opacity: 0.7;
  position: relative;
}

button:disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 49%, #E1D8D8 49%, #E1D8D8 51%, transparent 51%);
  background-size: 10px 10px;
  opacity: 0.1;
}

.server-images-section {
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.server-images-section select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.category-section {
  margin-bottom: 2rem;
}

.category-section h3 {
  margin-bottom: 1rem;
  color: #333;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.server-image-item {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem;
}

.server-image-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.server-image-item p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.address-list {
  margin-top: 1rem;
}

.address-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  background-color: #FCF5F5;
  border-radius: 8px;
}

.download-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.download-button:hover {
  background-color: #7A5656;
}

.download-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.title {
  cursor: pointer;
  user-select: none;
}

.address-list-section {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #FCF5F5;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.address-list-section h3 {
  color: #946A6A;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
}

.address-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.address-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s ease;
}

.address-card:hover {
  transform: translateY(-2px);
}

.address-text {
  font-size: 1.1rem;
  color: #333;
  text-align: center;
  font-weight: 500;
}

.download-button {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.download-button:hover {
  background-color: #7A5656;
}

.download-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 기존 스타일은 유지하고 아래 스타일 추가/수정 */

/* 기본 컨테이너 반응형 */
.image-classification {
  padding: 1rem;
  max-width: 100%;
  margin: 0 auto;
}

/* 제목 반응형 */
.title {
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin: 1rem 0;
  text-align: center;
}

/* 카테고리 리스트 반응형 */
.image-classification .category-list-horizontal {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important; /* 4열로 강제 고정 */
  gap: 0.5rem !important;
  padding: 0.5rem !important;
  max-width: 100% !important;
  margin: 0 auto !important;
}

/* 모바일 반응형도 수정 */
@media screen and (max-width: 480px) {
  .image-classification .category-list-horizontal {
    grid-template-columns: repeat(4, 1fr) !important; /* 모바일에서도 4열 유지 */
    gap: 0.3rem !important;
  }
}

/* 메인 컨텐츠 영역 반응형 */
.main-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* 드롭 영역 반응형 */
.drop-area {
  min-height: 150px;
  padding: 1rem;
}

.drop-area p {
  font-size: clamp(0.8rem, 2vw, 1rem);
}

/* 이미지 그리드 반응형 */
.image-grid {
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.5rem;
}

.image-item {
  aspect-ratio: 1;
}

/* 폼 섹션 반응형 */
.form-section {
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

/* 버튼 반응형 */
button {
  width: 100%;
  padding: 0.8rem;
  font-size: clamp(0.9rem, 2vw, 1rem);
  margin: 0.5rem 0;
}

/* 주소 목록 섹션 반응형 */
.address-list-section {
  margin-top: 2rem;
  padding: 1rem;
}

.address-grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.address-card {
  padding: 1rem;
}

/* 모바일 최적화 미디어 쿼리 */
@media screen and (max-width: 480px) {
  .image-classification {
    padding: 0.5rem;
  }

  .category-list-horizontal {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.3rem;
    padding: 0.3rem;
  }

  .category-item {
    padding: 0.5rem;
    font-size: 0.7rem;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .address-grid {
    grid-template-columns: 1fr;
  }

  .address-card {
    padding: 0.8rem;
  }

  .download-button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}

/* 매우 작은 화면 최적화 */
@media screen and (max-width: 360px) {
  .image-classification {
    padding: 0.3rem;
  }

  .category-list-horizontal {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2rem;
  }

  .category-item {
    padding: 0.4rem;
    font-size: 0.65rem;
  }

  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }

  .form-group input {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  button {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  .address-card {
    padding: 0.6rem;
  }

  .address-text {
    font-size: 0.9rem;
  }

  .download-button {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
}

.button-group {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  width: 100%;
}

.download-button,
.delete-button {
  flex: 1; /* 버튼들이 동일한 너비를 가지도록 설정 */
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
  height: 45px; /* 고정된 높이 설정 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button {
  background-color: #D64545;
}

.download-button:hover {
  background-color: #7A5656;
}

.delete-button:hover {
  background-color: #B83232;
}

.download-button:disabled,
.delete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 모바일 최적화 */
@media screen and (max-width: 360px) {
  .button-group {
    flex-direction: column;
  }
  
  .download-button,
  .delete-button {
    width: 100%;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
    height: 40px; /* 모바일에서는 약간 작게 */
  }
}

/* 버튼 그룹 스타일 강화 */
.address-card .button-group {
  display: flex !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  width: 100% !important;
  margin: 0 !important;
}

/* 버튼 스타일 */
.address-card .button-group button {
  flex: 1 1 0 !important;
  margin: 0 !important;
  height: 45px !important;
  font-size: 1rem !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.address-card .button-group .download-button {
  background-color: #946A6A !important;
  color: white !important;
}

.address-card .button-group .delete-button {
  background-color: #D64545 !important;
  color: white !important;
}

/* 호버 효과 */
.address-card .button-group .download-button:hover {
  background-color: #7A5656 !important;
}

.address-card .button-group .delete-button:hover {
  background-color: #B83232 !important;
}

/* 모바일 최적화 */
@media screen and (max-width: 360px) {
  .address-card .button-group {
    flex-direction: column !important;
  }
  
  .address-card .button-group button {
    width: 100% !important;
    height: 40px !important;
    font-size: 0.8rem !important;
    padding: 0.5rem 0.8rem !important;
  }
}

.image-classification .category-item {
  background-color: #FCF5F5;
  color: #946A6A;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  font-size: 0.9rem;
}

/* hover 효과 명시적으로 제거 */
.image-classification .category-item:hover {
  background-color: #FCF5F5;
  color: #946A6A;
}

/* 버튼 그룹 스타일 */
.button-group {
  display: flex !important;
  gap: 0.5rem !important;
  justify-content: center !important;
  width: 100% !important;
  margin-top: 1rem !important;
}

/* 다운로드 버튼 스타일 */
.address-download-btn {
  flex: 1 !important;
  background-color: #946A6A !important;
  color: white !important;
  border: none !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  transition: all 0.2s ease !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.address-download-btn:hover {
  background-color: #7A5656 !important;
  transform: translateY(-2px) !important;
}

/* 삭제 버튼 스타일 */
.address-delete-btn {
  flex: 1 !important;
  background-color: #D64545 !important;
  color: white !important;
  border: none !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  transition: all 0.2s ease !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.address-delete-btn:hover {
  background-color: #B83232 !important;
  transform: translateY(-2px) !important;
}

/* disabled 상태 스타일 */
.address-download-btn:disabled,
.address-delete-btn:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
  transform: none !important;
  opacity: 0.7 !important;
}

/* 모바일 최적화 */
@media screen and (max-width: 480px) {
  .button-group {
    flex-direction: column !important;
    gap: 0.3rem !important;
  }
  
  .address-download-btn,
  .address-delete-btn {
    width: 100% !important;
    height: 40px !important;
    font-size: 0.9rem !important;
    padding: 0.6rem 1rem !important;
  }
}

.image-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow-y: auto;
  max-height: 400px;
}

.image-preview-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  background-color: #f5f5f5;
}

.image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.2s ease;
  will-change: transform;
}

/* 호버 효과 최적화 */
@media (hover: hover) {
  .image-preview-item:hover img {
    transform: scale(1.05);
  }
}

.delete-button {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border: none;
  padding: 0;
  transform: translateZ(0);
}

/* 스크롤바 스타일링 */
.image-preview-grid::-webkit-scrollbar {
  width: 6px;
}

.image-preview-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.image-preview-grid::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 3px;
}

/* 반응형 디자인 최적화 */
@media (max-width: 768px) {
  .image-preview-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .image-preview-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
    padding: 8px;
    max-height: 250px;
  }
  
  .delete-button {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
}

.file-size-info {
  font-size: 0.9rem;
  color: #946A6A;
  opacity: 0.8;
  margin: 10px 0;
  line-height: 1.4;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #FCF5F5;
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #946A6A;
  transition: width 0.3s ease;
}
