.ongoing-addresses-page {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  background-color: #F7F7F6;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 
 .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 0;
 }
 
 .back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #946A6A;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
 }
 
 .ongoing-addresses-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 
 .ongoing-addresses-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  position: relative;
  width: 80%;
 }
 
 .ongoing-addresses-header h2 {
  color: #946A6A;
  font-size: 18px;
  margin: 0;
  text-align: center;
  width: 100%;
 }
 
 .toggle-icon {
  color: #946A6A;
  font-size: 18px;
  position: absolute;
  right: 0;
 }
 
 .address-list {
  width: 80%;
  padding: 15px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  margin-bottom: 10px;
 }
 
 .address-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: white;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #333;
  width: 100%;
 }
 
 .address-item:last-child {
  margin-bottom: 0;
 }
 
 .address-item span {
  display: block;
  width: 100%;
  text-align: center;
  color: #946A6A;
  font-size: 14px;
 }
 
 .no-addresses {
  text-align: center;
  color: #946A6A;
  padding: 20px 0;
  font-style: italic;
 }
 
 .admin-form {
  width: 100%;
  margin-top: auto;
 }
 
 .admin-controls-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
 }
 
 .admin-password-input,
 .admin-mode-button {
  width: 100%;
  max-width: 340px;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
  border: 1px solid #E1D8D8;
  border-radius: 12px;
  text-align: center;
  outline: none;
  box-sizing: border-box;
  margin: 0 auto 10px auto;
  display: block;
 }
 
 .admin-password-input {
  background-color: transparent;
  color: #946A6A;
 }
 
 .admin-mode-button {
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
 }
 
 .admin-mode-button:hover {
  background-color: #7A5656;
 }
 
 /* Modal Styles */
 .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
 }
 
 .modal-content {
  background-color: white;
  padding: 15px 25px;
  border-radius: 8px;
  text-align: center;
  width: 260px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
 }
 
 .modal-content p {
  margin: 0;
  color: #946A6A;
  font-size: 14px;
  line-height: 1.4;
 }
 
 .modal-content button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
 }
 
 .modal-content button:hover {
  background-color: #7A5656;
 }
 
 .address-item.selected {
  background-color: #f0f0f0;
  font-weight: bold;
 }

.selected-address-info {
  margin-top: 10px;
  padding: 10px;
  background-color: #e6e6e6;
  border-radius: 4px;
  text-align: center;
}