/* 테마 색상 변수 */
:root {
  --primary-color: #946A6A;
  --primary-dark: #7D5A5A;
  --background-color: #F5F5F5;
  --text-color: #333;
  --text-secondary-color: #666;
  --border-color: #E0E0E0;
  --primary-bg: #FFF;
}

/* 공통 버튼 스타일 */
button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bathroom-material-selection {
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  box-shadow: none;
}

.bathroom-material-selection__header {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.bathroom-material-selection__customer-number {
  font-size: 0.875rem;
  color: var(--text-secondary-color);
  margin: 0;
  padding-bottom: 1rem;
}

.bathroom-material-selection__address {
  font-size: 0.875rem;
  color: var(--primary-color);
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-light);
  border-radius: 4px;
  text-align: center;
}

.bathroom-material-selection__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bathroom-material-selection__categories {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 8px;
}

.bathroom-material-selection__section-header {
  display: none;
}

.bathroom-material-selection__section-title {
  display: none;
}

.bathroom-material-selection__materials-section {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 8px;
}

.bathroom-material-selection__materials {
  background-color: var(--background-color);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.bathroom-material-selection__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0 0 1.5rem 0;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.bathroom-material-selection__title:hover {
  color: var(--primary-color);
  opacity: 0.8;
  transform: scale(1.02);
}

.bathroom-material-selection__add-material-btn {
  width: 100%;
  max-width: 360px;
  margin: 0 auto 1rem;
  padding: 0.75rem;
  font-size: 0.875rem;
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  letter-spacing: 1px;
  display: block;
}

.bathroom-material-selection__add-material-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 360px) {
  .bathroom-material-selection__add-material-btn {
    padding: 0.625rem;
    font-size: 0.8125rem;
  }
}

@media (max-width: 768px) {
  .bathroom-material-selection__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .bathroom-material-selection__customer-number {
    padding-bottom: 1.5rem;
  }

  .bathroom-material-selection__guide-container {
    padding: 1.25rem 1.5rem;
    margin: 1.5rem auto;
    max-width: 90%;
  }

  .bathroom-material-selection__guide {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .bathroom-material-selection__selection-info {
    padding: 0.75rem;
  }

  .bathroom-material-selection__selected-items {
    font-size: 0.875rem;
    line-height: 1.6;
  }

  .bathroom-material-selection__reset-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
    min-width: 70px;
  }

  .bathroom-material__bathroom-selector {
    flex-direction: column;
  }

  .bathroom-material__bathroom-group {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .bathroom-material__bathroom-btn {
    padding: 0.75rem;
    font-size: 0.875rem;
    width: 100%;
    white-space: normal;
    height: auto;
    min-height: 44px;
  }

  .bathroom-material-selection__selection-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .bathroom-material-selection__selection-header-buttons {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .bathroom-material-selection__show-result-btn,
  .bathroom-material-selection__reset-btn {
    flex: 1;
    margin: 0;
    text-align: center;
    justify-content: center;
  }

  .bathroom-material__current-bathroom {
    font-size: 0.8125rem;
    margin-top: 0.75rem;
  }
}

/* 태블릿 스타일 */
@media (min-width: 768px) {
  .bathroom-material-selection {
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .bathroom-material-selection__header {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }

  .bathroom-material-selection__header h2 {
    font-size: 1.5rem;
  }

  .bathroom-material-selection__customer-number {
    font-size: 1rem;
  }

  .bathroom-material-selection__content {
    gap: 1.5rem;
  }

  .bathroom-material-selection__categories,
  .bathroom-material-selection__materials-section {
    padding: 1.5rem;
  }

  .bathroom-material-selection__section-title {
    font-size: 1.25rem;
  }

  .bathroom-material-selection__add-material-btn {
    padding: 1rem;
    font-size: 1rem;
  }
}

/* 데스크톱 스타일 */
@media (min-width: 1024px) {
  .bathroom-material-selection {
    max-width: 1200px;
    padding: 2rem;
  }

  .bathroom-material-selection__content {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
  }

  .bathroom-material-selection__categories {
    height: fit-content;
  }

  .bathroom-material-selection__header h2 {
    font-size: 1.75rem;
  }
}

.bathroom-material-list__name {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
  margin: 0 0 0.75rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.bathroom-material-list__name:hover {
  color: var(--primary-color);
}

.bathroom-material-selection__guide-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem auto;
  background-color: white;
  border: 1px solid var(--primary-light);
  border-radius: 12px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 12px rgba(148, 106, 106, 0.08);
  width: 100%;
  max-width: 600px;
  position: relative;
  overflow: hidden;
}

.bathroom-material-selection__guide-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: var(--primary-color);
}

.bathroom-material-selection__guide {
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.25rem 0;
  text-align: left;
  position: relative;
  line-height: 1.8;
  letter-spacing: -0.3px;
}

.bathroom-material-selection__guide:first-child {
  margin-bottom: 0.25rem;
}

.bathroom-material-selection__guide:not(:last-child) {
  margin-bottom: 0.25rem;
}

.bathroom-material-selection__selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.bathroom-material-selection__selection-header-buttons {
  display: flex;
  gap: 0.5rem;
}

.bathroom-material-selection__selection-header p {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1.1rem;
  margin: 0;
}

.bathroom-material-selection__show-result-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  min-width: 80px;
  white-space: nowrap;
}

.bathroom-material-selection__show-result-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material-selection__reset-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #dc3545;
  background-color: transparent;
  border: 1px solid #dc3545;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  min-width: 80px;
  white-space: nowrap;
}

.bathroom-material-selection__reset-btn:hover {
  background-color: #dc3545;
  color: white;
}

.bathroom-material-selection__selection-info {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 0.5rem;
  text-align: left;
  color: var(--text-color);
}

.bathroom-material-selection__selection-info p:first-child {
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.bathroom-material-selection__selected-items {
  font-size: 0.95rem;
  line-height: 1.8;
  margin: 0 0 1.5rem 0;
  color: var(--text-color);
  white-space: pre-line;
}

.bathroom-material__bathroom-selector {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.bathroom-material__bathroom-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}

.bathroom-material__bathroom-btn {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  background-color: white;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.bathroom-material__bathroom-btn:hover {
  background-color: #f0f0f0;
}

.bathroom-material__bathroom-btn--active {
  border-color: #2196f3;
  background-color: #e3f2fd;
  color: #1976d2;
}

.bathroom-material__bathroom-btn--no-construction {
  background-color: #ffebee;
  border-color: #ffcdd2;
  color: #c62828;
}

.bathroom-material__bathroom-btn--no-construction:hover {
  background-color: #ffcdd2;
}

.bathroom-material__bathroom-btn--no-construction.bathroom-material__bathroom-btn--active {
  background-color: #ef5350;
  border-color: #d32f2f;
  color: white;
}

.bathroom-material__current-bathroom {
  text-align: center;
  padding: 0.5rem;
  background-color: #e8f5e9;
  color: #2e7d32;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 1rem;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 