.edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.edit-modal {
  background: white;
  border-radius: 12px;
  padding: 16px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* 스크롤바 스타일링 */
.edit-modal::-webkit-scrollbar {
  width: 8px;
}

.edit-modal::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.edit-modal::-webkit-scrollbar-thumb {
  background: #946A6A;
  border-radius: 4px;
}

.edit-modal::-webkit-scrollbar-thumb:hover {
  background: #7A5656;
}

.edit-modal-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-modal-content h3 {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #E1D8D8;
}

.item-name {
  padding: 10px;
  background-color: #F7F7F6;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  color: #946A6A;
}

.item-name-display {
  padding: 8px 12px;
  background-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}

.edit-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.edit-field label {
  font-size: 12px;
  color: #946A6A;
  font-weight: 500;
}

.edit-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 13px;
  height: 36px;
  box-sizing: border-box;
}

.edit-field input[type="text"] {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.edit-field input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 1px rgba(148, 106, 106, 0.1);
}

.total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  margin-top: 4px;
  background-color: #F7F7F6;
  border-radius: 8px;
  border: 1px solid #E1D8D8;
}

.total-price label {
  font-size: 13px;
  color: #333;
  font-weight: 500;
}

.total-price span {
  font-size: 14px;
  color: #946A6A;
  font-weight: 600;
}

.edit-buttons {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  justify-content: center;
}

.edit-buttons button {
  width: 120px;
  padding: 8px;
  font-size: 13px;
  min-height: 36px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-buttons button:first-child {
  background-color: #946A6A;
  color: white;
}

.edit-buttons button:first-child:hover {
  background-color: #7A5656;
}

.edit-buttons button:last-child {
  background-color: #E1D8D8;
  color: #333;
}

.edit-buttons button:last-child:hover {
  background-color: #D1C8C8;
}

/* 체크박스 그룹 스타일 수정 */
.checkbox-group {
  display: flex;
  gap: 6px;
  margin-bottom: 6px;
  padding: 2px 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  margin-right: 6px;
  padding: 2px;
}

.checkbox-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

/* 체크박스 호버 효과 */
.checkbox-label:hover {
  color: #946A6A;
}

/* 품목 관리 버튼 그룹 스타일 추가 */
.item-management-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  justify-content: center;
}

.item-management-buttons button {
  width: 120px;
  padding: 8px;
  font-size: 13px;
  min-height: 36px;
  border: none;
  border-radius: 8px;
  background-color: #946A6A;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.item-management-buttons button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

.additional-tag {
  color: #e74c3c;
  font-weight: 500;
  margin-left: 4px;
  font-size: 0.9em;
  display: inline-block;
}

.note-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  font-size: 13px;
  height: 50px;
  min-height: auto;
  resize: none;
}

.note-textarea:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 1px rgba(148, 106, 106, 0.1);
}

/* 모바일 최적화 */
@media screen and (max-width: 480px) {
  .edit-modal-overlay {
    padding: 12px;
  }

  .edit-modal {
    padding: 12px;
  }

  .edit-field input,
  .note-textarea {
    font-size: 16px; /* 모바일에서 자동 확대 방지 */
  }

  .edit-buttons button {
    min-height: 48px; /* 터치 영역 확대 */
  }

  .checkbox-label {
    padding: 8px 4px; /* 터치 영역 확대 */
  }
}

/* 숫자 입력 필드의 스피너 제거 */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
} 