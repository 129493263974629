/* 기본 모바일 견적서 스타일 */
.me {
  width: 360px;
  margin: 0 auto;
  padding: 8px;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #f7f7f6;
  box-sizing: border-box;
}

/* 헤더 스타일 */
.me__header {
  width: 344px;
  background-color: white;
  border: 1px solid #e1d8d8;
  padding: 12px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.me__title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 8px 0;
}

.me__company {
  margin-top: 8px;
}

.me__company-text {
  font-size: 11px;
  color: #666;
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

/* 공정별 합계 섹션 */
.me__summary {
  width: 344px;
  background-color: white;
  border: 1px solid #e1d8d8;
  margin-bottom: 12px;
  overflow: hidden;
  box-sizing: border-box;
}

.me__section-title {
  padding: 8px;
  font-size: 13px;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #e1d8d8;
  text-align: center;
  margin: 0;
}

.me__process-row {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid #e1d8d8;
  width: 100%;
  min-height: 36px;
  position: relative;
}

.me__process-row--last {
  border-bottom: 2px solid #e1d8d8;
}

.me__process-total {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  width: 100%;
  min-height: 36px;
  background-color: #f7f7f6;
  position: relative;
}

.me__process-total-name {
  font-size: 11px;
  color: #333;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 60px;
  position: relative;
  z-index: 1;
  background: #f7f7f6;
  padding-right: 8px;
}

.me__process-total-amount {
  font-size: 12px;
  color: #946a6a;
  font-weight: 600;
  white-space: nowrap;
  margin-left: auto;
  padding-right: 60px;
  position: relative;
  z-index: 1;
  background: #f7f7f6;
  padding-left: 8px;
}

.me__process-name {
  font-size: 11px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 60px;
  position: relative;
  z-index: 1;
  background: white;
  padding-right: 8px;
}

.me__process-amount {
  font-size: 11px;
  color: #946a6a;
  font-weight: 500;
  white-space: nowrap;
  margin-left: auto;
  padding-right: 60px;
  position: relative;
  z-index: 1;
  background: white;
  padding-left: 8px;
}

.me__process-buttons {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-right: 4px;
}

.me__process-button {
  border: none;
  background: none;
  padding: 4px;
  cursor: pointer;
  color: #946a6a;
  font-size: 11px;
}

/* 상세 견적서 섹션 */
.me__detailed {
  width: 344px;
  background-color: white;
  margin-bottom: 12px;
  box-sizing: border-box;
  border: 1px solid #e1d8d8;
}

.me__detailed-process {
  border-bottom: 1px solid #e1d8d8;
  margin-bottom: 0;
}

.me__detailed-process:last-child {
  border-bottom: none;
}

.me__detailed-process--last {
  border-bottom: none;
}

.me__detailed-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;
  background-color: #F0EBEB;
  position: relative;
  height: 28px;
  border-bottom: 1px solid #e1d8d8;
  margin-bottom: 0;
  padding-left: 10px;
}

.me__detailed-list {
  padding: 4px 8px;
  border-bottom: 1px solid #e1d8d8;
}

.me__detailed-item {
  padding: 6px 0;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.me__detailed-item:not(:last-child) {
  border-bottom: 1px dotted #e1d8d8;
}

.me__detailed-grid {
  display: grid;
  grid-template-columns: 35% 15% 25% 25%;
  gap: 4px;
  align-items: start;
  justify-content: center;
  width: 100%;
  min-height: 32px;
  padding: 4px 0;
}

.me__detailed-cell {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 4px 2px;
  min-height: 32px;
}

.me__detailed-text {
  font-size: 11px;
  color: #333;
  white-space: normal;
  word-break: break-word;
  line-height: 1.3;
  text-align: center;
  width: 100%;
}

.me__detailed-text--additional {
  color: #e74c3c;
  font-weight: bold;
}

.me__detailed-process-name {
  font-size: 11px;
  color: #333;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
}

.me__detailed-subtotal {
  padding: 4px 8px;
  background-color: #f7f7f6;
  text-align: right;
  font-weight: 600;
  color: #946a6a;
  font-size: 11px;
  white-space: nowrap;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
}

.me__detailed-empty {
  padding: 2px 0;
  color: #999;
  font-size: 8px;
  text-align: center;
  background-color: #f9f9f9;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.me__detailed-note {
  width: 100%;
  padding: 6px 8px;
  margin-top: 2px;
  background-color: #F7F7F6;
  border-radius: 4px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.me__detailed-note-label {
  font-size: 10px;
  color: #946A6A;
  font-weight: 600;
  white-space: nowrap;
}

.me__detailed-note .me__detailed-text {
  width: 100%;
  white-space: normal;
  word-break: break-all;
  line-height: 1.2;
  text-align: left;
  justify-content: flex-start;
  padding: 2px 4px;
  font-size: 10px;
  color: #333;
}

.me__detailed-button {
  min-width: 34px;
  height: 18px;
  border: 1px solid #946a6a;
  background: none;
  padding: 0 4px;
  font-size: 8px;
  color: #946a6a;
  margin: 0;
}

.me__detailed-title {
  padding: 8px 0;
  font-size: 10px;
  font-weight: 600;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #e1d8d8;
  height: 32px;
  background-color: #f7f7f6;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.me__detailed-buttons {
  display: flex;
  gap: 4px;
  z-index: 2;
  margin-left: auto;
  padding-right: 4px;
  min-width: 85px;
  justify-content: flex-end;
  position: relative;
}

/* 총계 섹션션 */
.me__total {
  width: 344px;
  background-color: white;
  border: 1px solid #e1d8d8;
  padding: 10px;
  box-sizing: border-box;
}

.me__total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  white-space: nowrap;
}

.me__total-label {
  font-size: 11px;
  font-weight: 500;
  color: #333;
}

.me__total-value {
  font-size: 13px;
  font-weight: 600;
  color: #946a6a;
}

.me__total-row--vat {
  border-top: 1px solid #e1d8d8;
  margin-top: 6px;
  padding-top: 8px;
}

/* 버튼 스타일 */
.me__btn {
  height: 20px;
  border: 1px solid #946a6a;
  background: none;
  padding: 0 4px;
  font-size: 11px;
  color: #946a6a;
  margin: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.me__btn:active {
  background-color: #946a6a;
  color: white;
}

.me__btn--hidden {
  background-color: #e1d8d8;
  border-color: #e1d8d8;
  color: #666;
}

/* 빈 공정 스타일 */
.me__empty {
  padding: 4px;
  color: #999;
  font-size: 9px;
  text-align: center;
  background-color: #f9f9f9;
  white-space: nowrap;
}

/* 비고 입력 필드 */
.me__note-input {
  width: 100%;
  padding: 6px;
  border: 1px solid #e1d8d8;
  font-size: 11px;
  margin-top: 2px;
}

/* 반응형 조정 */
@media (max-width: 360px) {
  .me {
    width: 100%;
    padding: 6px;
  }
  
  .me__header,
  .me__summary,
  .me__detailed,
  .me__total {
    width: 100%;
  }
  
  .me__process-row {
    grid-template-columns: 80px 100px auto;
    padding: 8px 4px;
  }
  
  .me__btn {
    height: 20px;
    font-size: 11px;
  }
  
  .me__process-buttons {
    gap: 1px;
  }
}

.me__address-select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  background-color: white;
  color: #333;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.me__address-select:focus {
  border-color: #946A6A;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.me__address-select option {
  padding: 8px;
  font-size: 14px;
}

/* 버전 관리 컨트롤 스타일 */
.me__version-controls {
  padding: 8px;
  margin: 0 0 8px 0;
  display: flex;
  gap: 8px;
  background-color: white;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
}

.me__version-button {
  flex: 1;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 8px;
  background-color: #946A6A;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.me__version-button:active {
  background-color: #7A5656;
  transform: translateY(1px);
}

.me__version-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 수정 취소 버튼 스타일 */
.me__version-button--cancel {
  background-color: #E1D8D8;
  color: #333;
}

.me__version-button--cancel:active {
  background-color: #D1C8C8;
}

.me__hidden-process-button {
  width: 100%;
  height: 40px;
  background-color: #F7F7F6;
  color: #946A6A;
  border: 2px dashed #946A6A;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.me__hidden-process-button:hover {
  background-color: #946A6A;
  color: white;
}

.me__process-row::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  border-bottom: 1px dotted #e1d8d8;
  z-index: 0;
}

.me__process-total::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  border-bottom: 1px dotted #e1d8d8;
  z-index: 0;
}

/* 숨김 버튼 스타일 오버라이드 */
.me__detailed-header .hide-process-button {
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 20px;
  width: 20px;
  z-index: 2;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #946a6a;
  cursor: pointer;
  padding: 0;
}

.me__detailed-header .hide-process-button:hover {
  color: #7a5656;
}

.me__detailed-header .hide-process-button.hidden {
  color: #ccc;
}

.me__estimate-buttons {
  display: flex;
  gap: 8px;
  padding: 8px;
  margin: 0 0 8px 0;
  border: 1px solid #E1D8D8;
  border-radius: 8px;
  background-color: white;
}

.me__estimate-buttons button {
  flex: 1 1 50%;
  height: 40px;
  font-size: 14px;
  background-color: #946A6A;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.me__estimate-buttons button:hover {
  background-color: #7A5656;
}

.me__estimate-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.me__logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: 0 20px;
}

.me__logo img {
  max-width: 120px;
  height: auto;
} 