.share-button-icon {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #946A6A;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 36px;
  height: 36px;
}

.share-button-icon:hover {
  background-color: rgba(148, 106, 106, 0.1);
  transform: translateY(-1px);
}

.share-button-icon svg {
  width: 18px;
  height: 18px;
}

/* 모달 스타일 */
.share-button-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-button-modal {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.share-button-modal-header {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E1D8D8;
}

.share-button-modal-header h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.share-button-settings {
  margin-bottom: 20px;
}

.share-button-setting-group {
  margin-bottom: 15px;
}

.share-button-setting-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 14px;
}

.share-button-setting-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #E1D8D8;
  border-radius: 6px;
  font-size: 14px;
}

.share-button-url-section {
  background-color: #F7F7F6;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.share-button-url-section p {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #666;
}

.share-button-url-display {
  background: white;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #E1D8D8;
  font-size: 14px;
  word-break: break-all;
  margin-bottom: 10px;
}

.share-button-copy {
  background-color: #946A6A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  width: 100%;
}

.share-button-copy:hover {
  background-color: #7A5656;
}

.share-button-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.share-button-modal-buttons button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.share-button-modal-close {
  background-color: #E1D8D8;
  border: none;
  color: #333;
  width: 100%;
}

.share-button-modal-close:hover {
  background-color: #D1C8C8;
} 