.desktop-estimate {
  width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #F7F7F6;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

.desktop-ongoing-container {
  position: relative;
  width: 360px;
  margin: 0 auto;
  padding-top: 60px;
}

.estimate-header {
  margin-top: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 30px;
  border: 0.5px solid #E1D8D8;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 150px;
  padding: 20px;
  gap: 20px;
}

.header-left {
  flex: 1;
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-left::after,
.info-item:nth-child(3)::after,
.info-item.address::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
  width: 0.5px;
  background-color: #E1D8D8;
}

.header-left h2 {
  font-size: 28px;
  color: #333;
  margin: 0;
  margin-bottom: 8px;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.header-left h3 {
  font-size: 20px;
  color: #946A6A;
  margin: 0;
  font-weight: normal;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.info-item {
  flex: 1;
  position: relative;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.info-item:first-child,
.info-item:last-child {
  padding: 0 20px;
}

.info-item .label {
  font-size: 13px;
  color: #946A6A;
  margin-bottom: 8px;
  margin-top: 5px;
  font-weight: 500;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.info-item p {
  margin: 2px 0;
  font-size: 12px;
  color: #555;
  line-height: 1.3;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.info-item.total {
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 2px;
}

.info-item.total .amount {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

.info-item.total .amount span {
  font-size: 18px;
  margin-left: 0;
  font-weight: normal;
}

.info-item.total .vat-notice {
  font-size: 13px;
  color: #946A6A;
  margin-top: 4px;
}

.info-item.address p:not(.label) {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.estimate-content {
  margin-top: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.estimate-table {
  margin-top: 20px;
  width: 100%;
}

.estimate-table .col.item,
.estimate-table .table-header .col.item { 
  width: 20%;
  flex: none;
}

.estimate-table .col.quantity,
.estimate-table .table-header .col.quantity { 
  width: 8%;
  flex: none;
}

.estimate-table .col.unit,
.estimate-table .table-header .col.unit { 
  width: 8%;
  flex: none;
}

.estimate-table .col.unit-price,
.estimate-table .table-header .col.unit-price { 
  width: 15%;
  flex: none;
}

.estimate-table .col.amount,
.estimate-table .table-header .col.amount { 
  width: 15%;
  flex: none;
}

.estimate-table .col.note,
.estimate-table .table-header .col.note { 
  width: 34%;
  flex: none;
}

.table-header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-color: #946A6A;
  border: none;
}

.table-header .col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  font-weight: 500;
  text-align: center;
  color: white;
  font-size: 12px;
}

.table-header .col:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.process-section {
  margin-bottom: 20px;
}

.process-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #F7F7F6;
  color: #946A6A;
  font-weight: 600;
  font-size: 12px;
  border: none;
  min-height: 45px;
  position: relative;
  gap: 10px;
}

.process-name span {
  flex: 1;
  text-align: center;
}

.add-item-button {
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.add-item-button:hover {
  background-color: #7A5656;
  transform: scale(1.1);
}

.table-row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #E1D8D8;
  position: relative;
  transition: background-color 0.2s;
}

.table-row:last-child {
  border-bottom: none;
}

.table-row .col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  height: 45px;
  font-size: 12px;
}

.table-row .col:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: #E1D8D8;
}

.table-row .col.note::after {
  display: none;
}

.table-row .col.price {
  color: #946A6A;
  font-weight: 500;
}

.estimate-footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  border: none;
}

.total-sum-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #F7F7F6;
  padding: 20px 30px;
  border-radius: 8px;
  border: 1px solid #E1D8D8;
}

.total-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.total-sum .label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.total-sum .amount {
  font-size: 20px;
  color: #946A6A;
  font-weight: 500;
}

.total-sum.vat-included {
  padding-top: 10px;
  border-top: 1px solid #E1D8D8;
}

.total-sum.vat-included .label {
  font-weight: 600;
}

.total-sum.vat-included .amount {
  font-size: 24px;
  font-weight: bold;
}

.total-sum .currency {
  font-size: 16px;
  margin-left: 5px;
  font-weight: normal;
}

.col.unit-price,
.col.amount {
  color: #946a6a;
  font-weight: 500;
}

.process-subtotal {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #F7F7F6;
  border: none;
  border-bottom: 1px solid #E1D8D8;
}

.subtotal-label {
  color: #946A6A;
  font-weight: 500;
  margin-right: 15px;
}

.subtotal-amount {
  color: #333;
  font-weight: bold;
}

/* 공정별 합계 테이블 컨테이너 */
.process-summary-table {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
}

/* 테이블 헤더 */
.process-summary-table .table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  background-color: #946A6A;
}

.process-summary-table .table-header .col {
  padding: 12px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;
}

.process-summary-table .table-header .col:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

/* 테이 행 */
.process-summary-table .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid #E1D8D8;
}

.process-summary-table .table-row .col {
  padding: 12px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.process-summary-table .table-row .col:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: 1px;
  background-color: #E1D8D8;
}

/* 컬럼별 스타일 */
.process-summary-table .table-row .col.process {
  color: #333;
  font-weight: 500;
}

.process-summary-table .table-row .col.price {
  color: #946A6A;
  font-weight: 500;
}

.process-summary-table .table-row .col.note {
  color: #333;
  font-weight: 500;
}

/* 비고 입력 필드 */
.process-summary-table .table-row .col input {
  width: 90%;
  height: 35px;
  padding: 8px 12px;
  font-size: 14px;
  border: 0.5px solid #E1D8D8;
  border-radius: 4px;
  color: #333;
  font-weight: 500;
  background-color: white;
  text-align: center;
}

.process-summary-table .table-row .col input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 1px rgba(148, 106, 106, 0.1);
}

/* 빈 노트 스타일 */
.empty-note {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

/* 총 합계 섹션 */
.process-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px;
  background-color: #F7F7F6;
  border-bottom: 0.5px solid #E1D8D8;
}

.process-total .total-label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 20px;
}

.process-total .total-amount {
  font-size: 18px;
  font-weight: bold;
  color: #946A6A;
}

/* 필터 컨테이너 스타일 */
.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 20px 0;
  border: 0.5px solid #E1D8D8;
}

/* 첫 번째 행 */
.filter-row-1 {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

/* 두 번째 행 */
.filter-row-2 {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

/* 모든 필터 컨테이너에 동일한 스타일 적용 */
.size-filter,
.grade-filter,
.ac-filter,
.extra-filter,
.window-filter,
.design-filter,
.coating-filter,
.furniture-filter,
.painting-filter,
.floor-tile-filter {
  width: 180px;
  margin: 0;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 모든 select 요소에 동일한 스타일 적용 */
.size-select,
.grade-select,
.ac-select,
.extra-select,
.window-select,
.design-select,
.coating-select,
.furniture-select,
.painting-select,
.floor-tile-select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #946A6A;
  border-radius: 8px;
  background-color: white;
  color: #946A6A;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* hover/focus 상태 스타일 */
.size-select:hover,
.grade-select:hover,
.ac-select:hover,
.extra-select:hover,
.window-select:hover,
.design-select:hover,
.coating-select:hover,
.furniture-select:hover,
.painting-select:hover,
.floor-tile-select:hover,
.size-select:focus,
.grade-select:focus,
.ac-select:focus,
.extra-select:focus,
.window-select:focus,
.design-select:focus,
.coating-select:focus,
.furniture-select:focus,
.painting-select:focus,
.floor-tile-select:focus {
  border-color: #7A5656;
  outline: none;
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

/* 반응형 대응 */
@media (max-width: 768px) {
  .filter-container {
    padding: 15px;
  }

  .size-filter,
  .grade-filter,
  .ac-filter,
  .extra-filter,
  .window-filter,
  .design-filter,
  .coating-filter,
  .furniture-filter,
  .painting-filter,
  .floor-tile-filter {
    width: 100%;
    padding: 10px 0;
  }
  
  .size-select,
  .grade-select,
  .ac-select,
  .extra-select,
  .window-select,
  .design-select,
  .coating-select,
  .furniture-select,
  .painting-select,
  .floor-tile-select {
    width: 100%;
  }
}

.status-extra {
  color: #e74c3c;  /* 붉은색 */
  font-weight: 500;
}

/* 로고 관 스타일 수정 */
.info-item.logo {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.logo-container {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.logo-container img {
  width: 90px;
  height: auto;
  object-fit: contain;
  border-radius: 50%;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

/* 입력란 스타일 수정 */
.process-summary-table .table-row .col input {
  width: 90%;  /* 너비 증가 */
  height: 35px;  /* 높이 증가 */
  padding: 8px 12px;
  font-size: 12px;
  border: 0.5px solid #E1D8D8;  /* 얇은 테두리 */
  border-radius: 4px;
  color: #946A6A;
  background-color: white;
  text-align: center;
}

.process-summary-table .table-row .col input:focus {
  outline: none;
  border-color: #946A6A;
  box-shadow: 0 0 0 1px rgba(148, 106, 106, 0.1);
}

/* 빈 노트 텍스트 스타일 */
.empty-note {
  color: #999;
  font-size: 12px;
  cursor: pointer;
}

/* 컬럼 제목의 단가, 금액 텍스트 스타일 수정 */
.table-header .col.unit-price,
.table-header .col.amount {
  color: white;  /* 헤더에서만 흰색 적용 */
  font-weight: 500;
}

/* 내용의 단가, 금액은 기존 색상으로 복원 */
.table-row .col.unit-price,
.table-row .col.amount {
  color: #946A6A;  /* 내용일 때는 기존 색상 유지 */
  font-weight: 500;
}

.info-item.address {
  position: relative;
}

.admin-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border: 2px dashed #946A6A;  /* 점선 테두리 추가 */
  position: relative;          /* 위치 기준점 설정 */
}

/* 선택적: 테두리 모서리 부분에 둥근 효과 추가 */
.admin-buttons::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;  /* border-radius + 2px */
  pointer-events: none;
}

.edit-buttons-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
  position: relative;
  width: 200px;
}

.edit-buttons-group::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #E1D8D8;
}

.management-buttons-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: stretch;
  width: 200px;
}

.edit-complete-button,
.edit-cancel-button,
.history-button,
.database-button {
  width: 100%;
  height: 46px;
  padding: 0 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  white-space: nowrap;
  background-color: #946A6A;
  color: white;
  border: none;
}

.edit-complete-button {
  background-color: #946A6A;
  color: white;
}

.edit-complete-button:hover {
  background-color: #7A5656;
}

.edit-complete-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.edit-cancel-button {
  background-color: #E1D8D8;
  color: #333;
}

.edit-cancel-button:hover {
  background-color: #D1C8C8;
}

.history-button,
.database-button {
  background-color: #946A6A;
  color: white;
  border: none;
}

.history-button:hover,
.database-button:hover {
  background-color: #7A5656;
}



.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.loading-bar {
  height: 4px;
  background: #E1D8D8;
  border-radius: 2px;
  overflow: hidden;
}

.loading-progress {
  height: 100%;
  background: #946A6A;
  transition: width 0.3s ease;
}

.loading-text {
  text-align: center;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}

.item-actions {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  gap: 5px;
}

.table-row:hover .item-actions {
  display: flex;
}

.delete-item-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-item-button:hover {
  background-color: #c0392b;
}

.table-row.deleted {
  opacity: 0.5;
  background-color: #f8f8f8;
}

.interim-save-button {
  width: 100%;
  height: 46px;
  padding: 0 24px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.interim-save-button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

.table-row {
  position: relative;
  transition: background-color 0.2s;
}

.table-row:hover {
  background-color: #f8f8f8;
}

.delete-button {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s ease;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 2;
}

.delete-button:hover {
  background-color: #c0392b;
  transform: translateY(-50%) scale(1.1);
}

/* 삭제 버튼 호버 시 효과 */
.table-row:hover .delete-button {
  opacity: 1;
  right: -30px;
}

/* 입력 완료된 비고 텍스트 스타일 */
.process-summary-table .table-row .col.note span:not(.empty-note) {
  color: #333;
  font-size: 12px;
  font-weight: 500;
}

.email-send-button {
  width: 100%;
  height: 46px;
  padding: 0 24px;
  background-color: #946A6A;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}

.email-send-button:hover {
  background-color: #7A5656;
  transform: translateY(-1px);
}

.email-send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.desktop-estimate .process-name .hide-process-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-size: 14px;
  opacity: 0.7;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  color: #946A6A;
}

.desktop-estimate .process-name .hide-process-button:hover {
  opacity: 1;
  background-color: rgba(148, 106, 106, 0.1);
}

.desktop-estimate .process-name .hide-process-button.hidden {
  opacity: 0.4;
}

.desktop-estimate .process-name .hide-process-button.hidden:hover {
  opacity: 0.8;
  background-color: rgba(148, 106, 106, 0.1);
}

.desktop-estimate .process-name .hide-process-button svg {
  width: 14px;
  height: 14px;
}
  
  