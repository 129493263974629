.bathroom-material-admin {
  padding: 0.75rem;
  background-color: var(--background-color);
  border-radius: 0.5rem;
}

.bathroom-material-admin__form {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bathroom-material-admin__form-group {
  margin-bottom: 0.75rem;
}

.bathroom-material-admin__label {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 500;
  color: var(--text-color);
  font-size: 0.875rem;
}

.bathroom-material-admin__input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.bathroom-material-admin__input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(148, 106, 106, 0.1);
}

.bathroom-material-admin__form-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.bathroom-material-admin__submit-btn,
.bathroom-material-admin__cancel-btn {
  padding: 0.625rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
  width: 100%;
}

.bathroom-material-admin__submit-btn {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material-admin__submit-btn:hover {
  background-color: var(--primary-dark);
}

.bathroom-material-admin__cancel-btn {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.bathroom-material-admin__cancel-btn:hover {
  background-color: #f5f5f5;
}

.bathroom-material-admin__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bathroom-material-admin__item {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  background-color: white;
  gap: 0.75rem;
}

.bathroom-material-admin__item-info {
  display: flex;
  gap: 0.75rem;
}

.bathroom-material-admin__item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 0.25rem;
}

.bathroom-material-admin__item-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
}

.bathroom-material-admin__item-name {
  font-weight: 500;
  color: var(--text-color);
  font-size: 0.875rem;
}

.bathroom-material-admin__item-model {
  font-size: 0.75rem;
  color: var(--text-secondary-color);
}

.bathroom-material-admin__item-prices {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.bathroom-material-admin__item-price {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 0.875rem;
}

.bathroom-material-admin__item-vendor-price {
  font-size: 0.75rem;
  color: #2196F3;
}

.bathroom-material-admin__item-consumer-price {
  font-size: 0.75rem;
  color: #4CAF50;
}

.bathroom-material-admin__item-actions {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.bathroom-material-admin__edit-btn,
.bathroom-material-admin__delete-btn {
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s;
}

.bathroom-material-admin__edit-btn {
  background-color: var(--primary-color);
  color: white;
}

.bathroom-material-admin__edit-btn:hover {
  background-color: var(--primary-dark);
}

.bathroom-material-admin__delete-btn {
  background-color: #dc3545;
  color: white;
}

.bathroom-material-admin__delete-btn:hover {
  background-color: #c82333;
}

/* 태블릿 스타일 */
@media (min-width: 768px) {
  .bathroom-material-admin {
    padding: 1.25rem;
  }

  .bathroom-material-admin__form {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .bathroom-material-admin__form-group {
    margin-bottom: 1rem;
  }

  .bathroom-material-admin__label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .bathroom-material-admin__input {
    padding: 0.625rem;
    font-size: 1rem;
  }

  .bathroom-material-admin__form-actions {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }

  .bathroom-material-admin__submit-btn,
  .bathroom-material-admin__cancel-btn {
    width: auto;
    padding: 0.625rem 1.5rem;
    font-size: 1rem;
  }

  .bathroom-material-admin__item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .bathroom-material-admin__item-actions {
    width: auto;
  }

  .bathroom-material-admin__edit-btn,
  .bathroom-material-admin__delete-btn {
    flex: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .bathroom-material-admin__item-prices {
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .bathroom-material-admin__item-price,
  .bathroom-material-admin__item-vendor-price,
  .bathroom-material-admin__item-consumer-price {
    font-size: 0.875rem;
  }
}

/* 데스크톱 스타일 */
@media (min-width: 1024px) {
  .bathroom-material-admin {
    padding: 1.5rem;
  }

  .bathroom-material-admin__list {
    gap: 0.75rem;
  }

  .bathroom-material-admin__item-image {
    width: 80px;
    height: 80px;
  }
} 